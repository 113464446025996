function SignupCtrl($rootScope, $http, store, config, $state, $stateParams) {
'ngInject';

  const vm = this;
  vm.user = {};
  vm.nextState = $stateParams.nextState || 'inscriptions';
  var newParams = angular.copy($stateParams);
  
  delete newParams.nextState;
  
  vm.createUser = function() {
    
    $http({
      url: config.REGISTER_URL,
      method: 'POST',
      data: vm.user,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(function(response) {
      store.set('jwt', response.data.token);
      $state.go(vm.nextState, newParams);
    }, function(error) {
      alert(error.data);
    });
  }
}

export default {
  name: 'SignupCtrl',
  fn: SignupCtrl
}
