import $ from 'jquery';
import {convert} from '@isicily/epidoc-viewer-core';

function LeidenTranscription($compile, ModalsManager) {
'ngInject';
		return {
	    templateUrl: 'directives/leiden-transcription.html',
	    restrict: 'E',
	    
	    link: (scope, element, attrs) => {
	        //var teiDomFragmentOriginal = scope.$eval(attrs.teihtml);
	        var isDiplomatic = attrs.diplomatic;
	       
	        var refreshLeidenView = function() {
	        	
	        	let xmlString = scope.$eval(attrs.edition);
	        	
	        	if (!xmlString) return;

	        //	let teiDomFragment = TEIHelper.convertToLeiden(xmlString, isDiplomatic)

			//        let edition = vm.inscription.getEdition();
		        let handleOpenPopup = (textToShow) => {
			        $('#leiden-content-modal').html(textToShow)
			        $('#leidenModal').modal('show')
		        };
		        let showInterpreted = !isDiplomatic;
		        let leiden = convert(xmlString, handleOpenPopup, showInterpreted)

				let leidenElement = $(element).find('#leiden-text');
				leidenElement.html(leiden)
		      	//leidenElement.html(teiDomFragment.html().toString());
		      	
		      	// leidenElement.html((new XMLSerializer()).serializeToString(teiDomFragment[0]));


		    	$compile(element.contents())(scope);
		    }
		    scope.$watch(attrs.edition, refreshLeidenView);
    }
  };
}

export default {
	name: 'leidenTranscription',
	fn: LeidenTranscription
}
