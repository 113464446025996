import $ from 'jquery';
function VocabsManager($http, $q, config){
'ngInject';
    
    var inscriptionTypesPromise = null;
    var objectTypesPromise = null;
    var materialsPromise = null;
    var executionTypesPromise = null;
    

    function loadInscriptionTypes() {
       return loadTerms(config.INSCRIPTION_TYPES_URI, inscriptionTypesPromise);
    }
    function loadObjectTypes() {
       return loadTerms(config.OBJECT_TYPES_URI, objectTypesPromise);
    }
    function loadExecutionTypes() {
       return loadTerms(config.EXECUTION_TYPES_URI, executionTypesPromise);
    }
    function loadMaterials() {
       return loadTerms(config.MATERIALS_URI, materialsPromise);
    }

    function deleteInscriptionType(termId){
        return deleteTerm(config.INSCRIPTION_TYPE_URI, config.INSCRIPTION_TYPES_URI, inscriptionTypesPromise, termId);
    }
    function deleteObjectType(termId){
        return deleteTerm(config.OBJECT_TYPE_URI, config.OBJECT_TYPES_URI, objectTypesPromise, termId);
    }
    function deleteExecutionType(termId){
        return deleteTerm(config.EXECUTION_TYPE_URI, config.EXECUTION_TYPES_URI, executionTypesPromise, termId);
    }
    function deleteMaterial(termId){
        return deleteTerm(config.MATERIAL_URI, config.MATERIALS_URI, materialsPromise, termId);
    }

    function addInscriptionType(termName, termURI){
        return addTerm(config.INSCRIPTION_TYPE_URI, config.INSCRIPTION_TYPES_URI, inscriptionTypesPromise, termName, termURI);
    }
    function addObjectType(termName, termURI){
        return addTerm(config.OBJECT_TYPE_URI, config.OBJECT_TYPES_URI, objectTypesPromise, termName, termURI);
    }
    function addExecutionType(termName, termURI){
        return addTerm(config.EXECUTION_TYPE_URI, config.EXECUTION_TYPES_URI, executionTypesPromise, termName, termURI);
    }
    function addMaterial(termName, termURI){
        return addTerm(config.MATERIAL_URI, config.MATERIALS_URI, materialsPromise, termName, termURI);
    }

    function addTerm(postURI, getURI, getPromise, termName, termURI) {
      return $http.post(postURI, {term: termName, uri: termURI}).then(
          function(response) {   
            getPromise = null;
            return loadTerms(getURI, getPromise);
          },
          function(errorResponse) {  
            console.log('error in call to add to the types list');
            console.log(errorResponse);  
            return $q.reject(errorResponse);
          }
        )
    }

    function deleteTerm(deleteURI, getURI, getPromise, termId) {
        var uriToDelete = deleteURI + '/' + termId;
        return $http.delete(uriToDelete).then(
          function(response) {   
            getPromise = null;
            return loadTerms(getURI, getPromise);
          },
          function(errorResponse) {  
            console.log('error in call to delete from the types list');
            console.log(errorResponse);  
            return $q.reject(errorResponse);
          }
        )
    }


    function loadTerms(uri, promise) {
       if (promise == null) {
        promise = $http({
        url: uri,
        method: 'GET'
      }).then(
          function(response) {   
            return $.each(response.data, function(index,term) {term.certainty = 'high'; return term}) 
          },
          function(errorResponse) {  
            console.log('error in call to get types list');
            console.log(errorResponse);  
            return $q.reject(errorResponse);
          }
        )
      }
      return promise;
    }

    return {
        loadInscriptionTypes: loadInscriptionTypes,
        loadObjectTypes: loadObjectTypes,
        loadExecutionTypes: loadExecutionTypes,
        loadMaterials: loadMaterials,
        addInscriptionType: addInscriptionType,
        addObjectType: addObjectType,
        addExecutionType: addExecutionType,
        addMaterial: addMaterial,
        deleteInscriptionType: deleteInscriptionType,
        deleteObjectType: deleteObjectType,
        deleteExecutionType: deleteExecutionType,
        deleteMaterial: deleteMaterial
    }

  }

export default {
  name: 'VocabsManager',
  fn: VocabsManager
};

