import _ from 'lodash';
import {convert} from '@isicily/epidoc-viewer-core';

//var convert = require('@isicily/epidoc-viewer-core');
//console.log(convert)
function InscriptionCtrl(inscription, OpenSeadragonHelper, MuseumsManager, config, TEIHelper, $window, $state, store, jwtHelper,  $mdDialog, ModalsManager) {
'ngInject';
  
  const vm = this;
  vm.showImages = false;

   // console.log('the convert function')
   // console.log(convert)

  if (inscription) {

    vm.inscription = inscription;
    //console.log('inscription coming from exist')
    //console.log(inscription)

function getPageURL() {
  return $state.href($state.current.name, {}, {absolute:true});
}
    vm.emailPage = function(){
      $window.location.href='mailto:?subject='+document.title+'&body='+encodeURIComponent($window.location.href);
  }

  vm.xmlURL = `${config.SERVICES_BASE_URI}inscription/${vm.inscription.isicily_id}.xml`;

  vm.isicilyNo = vm.inscription.isicily_id.slice(-6)


  vm.submitImageComment = function(ev) {
    var role = store.get('jwt')?jwtHelper.decodeToken(store.get('jwt')).role:false;
    if (role && (role == 'ADMIN' || role=='BASIC')) {
      ModalsManager.openComment('images', vm.inscription.isicily_id, ev);
    } else {
      $mdDialog.show(
            $mdDialog.alert()
              .clickOutsideToClose(true)
              .title('Create an Account')
              .textContent('You must register to submit comments.  Please contact us to create an account.')
              .ariaLabel('Create Account')
              .ok('Done')
              .targetEvent(ev)
            );
    }
  }

  vm.fbShare = function() {
    var pageURL = getPageURL();
    FB.ui(
      {
        method: 'share',
        href: pageURL
      },
      // callback
      function(response) {
        if (response && !response.error_message) {
          alert('Posting completed.');
        } else {
          //alert('Error while posting.');
        }
      }
    )
  }

  vm.copyLink = function(ev){
    $mdDialog.show(
            $mdDialog.alert()
              .clickOutsideToClose(true)
              .title('You may link to this page as')
              .textContent(getPageURL())
              .ariaLabel('Page Link.')
              .ok('Done')
              .targetEvent(ev)
            );
  }

    var sicilyCentre = {lat:37.500, lng:14.000};
    var museum_map = new google.maps.Map(document.getElementById('museum_map'), {
      zoom: 8,
      center: sicilyCentre,
      scrollwheel: false,
      scaleControl: true,
    });
    var findspot_map = new google.maps.Map(document.getElementById('findspot_map'), {
      zoom: 8,
      center: sicilyCentre,
      scrollwheel: false,
      scaleControl: true,
      mapTypeId: google.maps.MapTypeId.TERRAIN
    });    

    vm.inscription.addToFindspotMap(findspot_map);

    var findspotTitle = document.createElement('h4');
    findspotTitle.style.color = 'black';
    findspotTitle.style.margin = "15px 30px 0px 0px"
    findspotTitle.innerHTML = 'Find Spot';
    var findspotDiv= document.createElement('div');
    findspotDiv.appendChild(findspotTitle);

    findspot_map.controls[google.maps.ControlPosition.TOP_RIGHT].push(findspotDiv);

    var museumTitle = document.createElement('h4');
    museumTitle.style.color = 'black';
    museumTitle.style.margin = "15px 30px 0px 0px"
    museumTitle.innerHTML = 'Current Location';
    var museumDiv= document.createElement('div');
    museumDiv.appendChild(museumTitle);

    museum_map.controls[google.maps.ControlPosition.TOP_RIGHT].push(museumDiv);
   
    //if (vm.contributors) vm.contributors = vm.inscription.contributors.map((contrib)=>contrib.name).join(', ');
    
    var heightWithUnits, widthWithUnits, depthWithUnits;
    
    if (vm.inscription.height) {
      var height = vm.inscription.height.hasOwnProperty('#text') ? vm.inscription.height['#text'] : vm.inscription.height;
      heightWithUnits = `H = ${height} ${vm.inscription.height_unit}; `;        
    }
    if (vm.inscription.width) {
      var width = vm.inscription.width.hasOwnProperty('#text') ? vm.inscription.width['#text'] : vm.inscription.width;
      widthWithUnits = `W = ${width} ${vm.inscription.width_unit}; `;
    }
    if (vm.inscription.depth) {
      var depth = vm.inscription.depth.hasOwnProperty('#text') ? vm.inscription.depth['#text'] : vm.inscription.depth;
      depthWithUnits = `D = ${depth} ${vm.inscription.depth_unit}; `;
    }
    vm.dimensions = heightWithUnits + widthWithUnits + depthWithUnits;
      
    // eXist returns single results as an object, multiple results in an array.  so convert object results to array.
  
   if (vm.inscription.letterHeightLoci 
        && vm.inscription.letterHeightLoci.locus 
        && vm.inscription.letterHeights 
        && vm.inscription.letterHeights.dimensions) {
          vm.letterHeightLoci = [].concat(vm.inscription.letterHeightLoci.locus);
          vm.letterHeights = [].concat(vm.inscription.letterHeights.dimensions);
    } 

   if (vm.inscription.interlinearLoci 
        && vm.inscription.interlinearLoci.locus 
        && vm.inscription.interlinearHeights 
        && vm.inscription.interlinearHeights.dimensions 
       // && vm.inscription.interlinearHeights.dimensions.height
      //  && vm.inscription.interlinearHeights.dimensions.height['#text']
     ) {
            let dimensions = [].concat(vm.inscription.interlinearHeights.dimensions)
            let heights = dimensions.map(dimension=>{
                let unit, value = ''
                if (dimension.height) {
                    unit = dimension.height.unit?dimension.height.unit:''
                    value = dimension.height['#text']?dimension.height['#text']:''
                }
                return {unit, value}})
            vm.interlinearLoci = [].concat(vm.inscription.interlinearLoci.locus);
            vm.interlinearHeights = heights;
   }  
  
   if (vm.inscription.material) {
      vm.materialHTML = [].concat(vm.inscription.material).filter(obj=>obj?true:false).map(
        material=>`<a href="${material.ref}" target="_blank">${material['#text']}</a>`
      ).join(', ');
    }
    if (vm.inscription.term) {
      vm.inscriptionTypeHTML = [].concat(vm.inscription.term).filter(obj=>obj?true:false).map(
          (type)=>`<a href="${type.ref}" target="_blank">${type['#text']}</a>`
      ).join('/');
    }
    if (vm.inscription.objectType) {
      vm.objectTypeHTML = [].concat(vm.inscription.objectType).filter(type=>type?true:false).map(
          (type)=>`<a href="${type.ref}" target="_blank">${type['#text']}</a>`
      ).join('/');
    }

    // "rs" : { "ana" : "#execution.chiselled", "ref" : "http://www.eagle-network.eu/voc/writing/lod/1", "#text" : "chiselled" }

    if (vm.inscription.rs) {
      vm.executionTypeHTML = [].concat(vm.inscription.rs).filter(obj=>obj?true:false).map(
        (type)=>`<a href="${type.ref}" target="_blank">${type.ana.slice(type.ana.lastIndexOf('.') + 1)}</a>`
      ).join(', ');
    }

    if (vm.inscription.app) {
      vm.apparati = [].concat(vm.inscription.app);
    }

    if (vm.inscription.phi_id) {
      vm.phiIds = vm.inscription.phi_id.split(' ');
    }

    vm.doi = (vm.inscription.doi && vm.inscription.doi.idno) ?
        ', DOI: ' + [].concat(vm.inscription.doi.idno).sort((a, b) => a.when.localeCompare(b.when)).pop()['#text'] : ''


      let provenanceHyperlinked = TEIHelper.hyperlinkAll(vm.inscription.provenance);
     vm.ancientPlaceForTitle = TEIHelper.hyperlinkAll(vm.inscription.ancient_place_name_for_display);
     vm.modernPlaceForTitle = TEIHelper.hyperlinkAll(vm.inscription.modern_place_name_for_display);
     vm.provenanceForField = provenanceHyperlinked || vm.ancientPlaceForTitle || vm.modernPlaceForTitle;
    vm.layoutDescHTML = TEIHelper.hyperlinkAll(vm.inscription.layout_description);
    vm.supportDescHTML = TEIHelper.hyperlinkAll(vm.inscription.support_description);
    vm.commentaryHTML = TEIHelper.hyperlinkAll(vm.inscription.commentary);
    vm.translations = TEIHelper.splitTranslations(vm.inscription.translation);

    vm.inscription.bibl = [].concat(vm.inscription.bibl);

    vm.inscription.discussion = (vm.inscription.discussion && vm.inscription.discussion.bibl) ?
        [].concat(vm.inscription.discussion.bibl) :
        []

    vm.biblByCorpus = _.sortBy(_.filter(vm.inscription.bibl, 'type'), ['type']);
    vm.biblByAuthor = _.sortBy(_.filter(vm.inscription.bibl, b=>b&&!b.type), ['date', 'author']);

      vm.discussionByCorpus = _.sortBy(_.filter(vm.inscription.discussion, 'type'), ['type']);
      vm.discussionByAuthor = _.sortBy(_.filter(vm.inscription.discussion, b=>b&&!b.type), ['date', 'author']);

    MuseumsManager.getMuseumByURI(vm.inscription.museum_uri).then(
      function(museum) {
        vm.museum = museum;
        if (museum) {
          
          var museumMarker = museum.addToMap(museum_map);
          if (museumMarker) {
           museum_map.setCenter(museumMarker.getPosition());
           museum_map.setZoom(14);
            museum.showInfoWindow();
          }
        }
    });



      vm.edit = function() {
      $state.go('inscription-edit', {id:vm.inscription.isicily_id});
    }

    vm.print = function() {
      $window.open($state.href('inscription-printable', {id:vm.inscription.isicily_id}), '_blank', 'height=1200,width=1000');
    }
    vm.showImages = OpenSeadragonHelper.openseadragon(vm.inscription.images, vm.inscription.isicily_id);

    console.log('the inscription vm:')
    console.log(vm)
  }
}

export default {
  name: 'InscriptionCtrl',
  fn: InscriptionCtrl
}
