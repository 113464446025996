function LayoutCtrl($mdDialog, $state, $window, $translate, config) {
'ngInject';
  
  	const vm = this;
 
 	function getRandomIntInclusive(min, max) {
  		min = Math.ceil(min);
  		max = Math.floor(max);
  		return Math.floor(Math.random() * (max - min + 1)) + min;
	}

 	var images = ["images/ISicily_latin_reduced.jpg", "images/ISicily_greek_reduced.jpg", "images/ISicily_english_reduced.jpg"]
 	vm.headerImage = images[getRandomIntInclusive(0,2)];

 	var originatorEv;
	vm.openMenu = function($mdOpenMenu, ev) {
	  originatorEv = ev;
	  $mdOpenMenu(ev);
	};
	vm.notificationsEnabled = true;
	vm.toggleNotifications = function() {
	  vm.notificationsEnabled = !vm.notificationsEnabled;
	};
	
	vm.changeLanguage = function(langKey){
		var inscriptionsURI = config.BASE_URI + '?lang=' + langKey
        $window.open(inscriptionsURI);
    	//$translate.use(langKey);
	}

	function getPageURL() {
  		return $state.href($state.current.name, {}, {absolute:true});
	}

    vm.emailPage = function(){
      $window.location.href="mailto:?subject="+document.title+"&body="+escape($window.location.href);
  	}

	vm.fbShare = function() {
	    var pageURL = getPageURL();
	    FB.ui(
	      {
	        method: 'share',
	        href: pageURL
	      },
	      // callback
	      function(response) {
	        if (response && !response.error_message) {
	          alert('Posting completed.');
	        } else {
	          //alert('Error while posting.');
	        }
	      }
	    )
  	}
  
  	vm.copyLink = function(ev){
    	$mdDialog.show(
            $mdDialog.alert()
              .clickOutsideToClose(true)
              .title('You may link to this page as')
              .textContent(getPageURL())
              .ariaLabel('Page Link.')
              .ok('Done')
              .targetEvent(ev)
            );
  	}

}

export default {
  name: 'LayoutCtrl',
  fn: LayoutCtrl
}
