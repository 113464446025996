function UsersManager($http, config, store) {
'ngInject';

 function updateUser(user) {
    var uri = config.USER_URI + '/' + user.id ;
    return $http({
      url: uri,
      method: 'PUT',
      data: user,
      headers: {
        'Content-Type': 'application/json'
      }
   //   withCredentials: true,
    //  headers: {
    //   'X-Authorization': `Bearer ${store.get('jwt')}`
    //  }
    }).then(function(response) {
      // maybe flash a Saved overlay.
    }, function(error) {
      alert(error.data);
    });
 }

function getUsers() {
  return $http({
        url: config.USERS_URI,
        method: 'GET'
      }).then(
    function(usersResponse) { 
      return usersResponse.data;
    },
    function(errorResponse) {  
      console.log('error in call to get user list');
      console.log(errorResponse);  
    }
  );

}

    return {
        getUsers: getUsers,
        updateUser: updateUser
    }

}

export default {
  name: 'UsersManager',
  fn: UsersManager
};


