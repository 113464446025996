"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _sharedRules = _interopRequireDefault(require("./sharedRules.js"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function isInterveningBreak(currentNode) {
  return currentNode.nodeType === Node.ELEMENT_NODE && ['lb', 'ab', 'cb', 'div'].includes(currentNode.nodeName);
}

function isInterveningText(currentNode) {
  return currentNode.nodeType === Node.TEXT_NODE && currentNode.nodeValue.trim().length;
}

var removeAllChildNodes = function removeAllChildNodes(parent) {
  while (parent.firstChild) {
    parent.removeChild(parent.firstChild);
  }
};

var splitAround = function splitAround(root, splittingElement) {
  // divide the tree starting at root to the left and right of splittingElement
  // from https://stackoverflow.com/questions/27497718/splitting-node-content-in-javascript-dom
  for (var parent = splittingElement.parentNode; root != parent; parent = grandparent) {
    var right = parent.cloneNode(false);

    while (splittingElement.nextSibling) {
      right.appendChild(splittingElement.nextSibling);
    }

    var grandparent = parent.parentNode;
    grandparent.insertBefore(right, parent.nextSibling);
    grandparent.insertBefore(splittingElement, right);
  }
};

var findNextAdjacentSupplied = function findNextAdjacentSupplied(tw) {
  // let priorNode = tw.currentNode;
  var result = null;
  var done = false;

  while (!done && tw.nextNode()) {
    if (isInterveningText(tw.currentNode) || isInterveningBreak(tw.currentNode)) {
      done = true; // no adjacent <supplied>, so done
    } else if (tw.currentNode.nodeType === Node.ELEMENT_NODE && ['supplied', 'gap'].includes(tw.currentNode.nodeName)) {
      result = tw.currentNode; // we've found another adjacent supplied or gap

      done = true;
    }
  } // tw.currentNode = priorNode  // reset treewalker 


  return result; // return supplied node if any
};

var getTextFromSuppliedAndAdjacentSupplieds = function getTextFromSuppliedAndAdjacentSupplieds(suppliedNode, tw) {
  var containedLineBreak = suppliedNode.querySelector('lb');

  if (containedLineBreak) {
    // split the supplied in two and then continue
    splitAround(suppliedNode.parentNode, containedLineBreak);
  }

  var suppliedText = '';
  var reason = suppliedNode.getAttribute('reason');

  if (reason === 'omitted' || reason === 'subaudible') {
    suppliedNode.textContent = ''; // ignore these supplied elements
  } else {
    // remove any supplied expansions of abbreviations
    suppliedNode.querySelectorAll('ex').forEach(function (exNode) {
      return exNode.textContent = '';
    });

    if (suppliedNode.nodeName === 'supplied') {
      suppliedText = suppliedNode.textContent.trim();
    } else if (suppliedNode.nodeName === 'gap') {
      var quantity = suppliedNode.getAttribute('quantity');

      if (quantity && !isNaN(quantity)) {
        suppliedText = 'X'.repeat(parseInt(quantity));
      }
    }

    removeAllChildNodes(suppliedNode);
    var adjacentSupplied = findNextAdjacentSupplied(tw); // see if there is an adjacent supplied

    if (adjacentSupplied) {
      suppliedText = suppliedText + getTextFromSuppliedAndAdjacentSupplieds(adjacentSupplied, tw);
      adjacentSupplied.parentNode.removeChild(adjacentSupplied); //adjacentSupplied.textContent = ''
    }
  }

  return suppliedText;
};

var diplomaticRules = _objectSpread({
  'supplied': function supplied(node, tw) {
    var finalText = getTextFromSuppliedAndAdjacentSupplieds(node, tw);

    if (!finalText) {
      node.textContent = '';
    } else if (finalText.length < 5) {
      node.textContent = "[ ".concat('.'.repeat(finalText.length), "]");
    } else {
      node.textContent = "[.. ".concat(finalText.length, " ..]");
    }

    tw.currentNode = node; // reset treewalker
  },
  // 'unclear': node => node.textContent = '',
  // 'gap': node => node.textContent = '',
  'desc': function desc(node) {
    return node.textContent = '';
  },
  'note': function note(node) {
    return node.textContent = '';
  },
  'ex': function ex(node) {
    return node.textContent = '';
  }
}, (0, _sharedRules["default"])(false));

var _default = diplomaticRules;
exports["default"] = _default;