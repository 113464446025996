function ModalsManager($uibModal, VocabsManager, MuseumsManager, $mdDialog, $mdMedia){
'ngInject';

    function openPlaceLookup(searchString, ev) {
      return $mdDialog.show({
        controller: 'PlaceLookupModalCtrl as placeLookupModalVM',
        templateUrl: 'modals/place-lookup.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose:true,
        fullscreen: false,
        locals: {searchString: searchString}
      })
    };

    function openPersonLookup(searchString) {
      return $uibModal.open({
        templateUrl: 'modals/person-lookup.html' + Date.now(),
        controller: 'PersonLookupModalCtrl',
        size: 'md',
        resolve: {
            searchString: function () {
                return searchString;
            }
        },
        windowClass: 'app-modal-window'
      }).result;
    };

    function openMuseumListEdit(inscription, ev) {
      return $mdDialog.show({
        controller: 'MuseumListEditModalCtrl as museumListModalVM',
        templateUrl: 'modals/museum-list-edit.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose:true,
        fullscreen: false,
        resolve: {museums: MuseumsManager.loadMuseums(), inscription: inscription }
      });
    };

  
    function openEditionEdit(inscription, ev) {
      return $uibModal.open({
        templateUrl: 'modals/edition-edit.html' + Date.now(),
        controller: 'EditionEditModalCtrl',
        size: 'lg',
        windowClass: 'app-modal-window',
        resolve: {
            inscription: function () {
                return inscription;
            }
        },
      }).result;
    }

    function openDateEdit(inscription, ev) {
      return $uibModal.open({
        templateUrl: 'modals/date-edit.html' + Date.now(),
        controller: 'DateEditModalCtrl',
        size: 'md',
        windowClass: 'app-modal-window',
        resolve: {
            inscription: function () {
                return inscription;
            }
        },
      }).result;
    }

  function openExport(gridAPI, ev) {
    return $mdDialog.show({
        controller: 'ExportModalCtrl as exportModalVM',
        templateUrl: 'modals/export.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose:true,
        fullscreen: false,
        locals: {gridAPI: gridAPI}
      })
    };

  function openComment(fieldName, inscriptionId, ev) {
    return $mdDialog.show({
              controller: 'CommentModalCtrl as commentModalVM',
              templateUrl: 'modals/comment.html',
              parent: angular.element(document.body),
              targetEvent: ev,
              clickOutsideToClose:true,
              fullscreen: false,
              locals: { fieldName: fieldName, inscriptionId: inscriptionId}
      });
    };

function openInscriptionTypeEdit(inscription, ev) {
       return openTermEdit('Inscription Type', ev, inscription, VocabsManager.loadInscriptionTypes);
    }
    function openExecutionTypeEdit(inscription, ev) {
       return openTermEdit('Inscription Type', ev, inscription, VocabsManager.loadExecutionTypes);
    }
    function openObjectTypeEdit(inscription, ev) {
       return openTermEdit('Object Type', ev, inscription, VocabsManager.loadObjectTypes);
    }

 function openMaterialEdit(inscription, ev) {
       return openTermEdit('Material', ev, inscription, VocabsManager.loadMaterials);
    }

function openTermEdit(termName, ev, inscription, termLoader) {

     // var useFullScreen = ($mdMedia('sm') || $mdMedia('xs'))  && $scope.customFullscreen;
      return $mdDialog.show({
        controller: 'TermEditModalCtrl as termModalVM',
        templateUrl: 'modals/term-edit.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose:true,
        fullscreen: false,
        locals: { termName: termName, inscription: inscription},
        resolve: {terms: termLoader}
      });
      //$scope.$watch(function() {
      //  return $mdMedia('xs') || $mdMedia('sm');
      //}, function(wantsFullScreen) {
      //  $scope.customFullscreen = (wantsFullScreen === true);
      //});
    };

function openMuseumPeriodEdit(museum, ev) {
   return $mdDialog.show({
        controller: 'MuseumPeriodEditModalCtrl as periodModalVM',
        templateUrl: 'modals/museum-period-edit.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose:true,
        fullscreen: false,
        locals: { museum: museum}
      });
}



    return {
      openExport: openExport,
      openEditionEdit: openEditionEdit,
      openDateEdit: openDateEdit,
      openPersonLookup: openPersonLookup,
      openPlaceLookup: openPlaceLookup,
      openMuseumListEdit: openMuseumListEdit,
      openInscriptionTypeEdit: openInscriptionTypeEdit,
      openExecutionTypeEdit: openExecutionTypeEdit,
      openObjectTypeEdit: openObjectTypeEdit,
      openMaterialEdit: openMaterialEdit,
      openMuseumPeriodEdit: openMuseumPeriodEdit,
      openComment: openComment
    }
}

export default {
  name: 'ModalsManager',
  fn: ModalsManager
}