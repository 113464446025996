import $ from 'jquery';
import CodeMirror from 'codemirror';
import _ from 'lodash';
function InscriptionEditCtrl(MuseumsManager, DataManager, $log, $mdDialog, OpenSeadragonHelper, materials, executionTypes, objectTypes, inscriptionTypes, museums, $scope) {
'ngInject';

  const vm = this;
  vm.museums = museums;
  var editorOptions = {
        lineWrapping : true,
        lineNumbers: true,
        mode: 'xml',
        autofocus: true
    };
    vm.languageChoices = [
      {code: 'la', fullForm: 'Latin'}, 
      {code: 'grc', fullForm: 'Ancient Greek'},
      {code: 'xpu', fullForm: 'Punic'},
      {code: 'phn', fullForm: 'Phoenician'},
      {code: 'osc', fullForm: 'Oscan'},
      {code: 'scx', fullForm: 'Sikel'},
      {code: 'xly', fullForm: 'Elymian'},
      {code: 'he', fullForm: 'Hebrew'}
    ]
  

  // 1.
  vm.user = {firstName: null, lastName: null, email: null, orcid: null, shouldNotCite: false};
  // 2.
  vm.ids = {isicily: null, tm: null, edr: null, edh: null, phi: null, edcs: null, other: null};
  // 3.
  vm.biblRefs = null;
  // 4.
  vm.autopsy = {undertaken: false, date: null, name: null};
  // 5.
  vm.location = {
    site: 'unknown',           // 'site' values: museum, situ, other, lost, unknown
    selectedMuseum: null, 
    otherMuseum: {settlement: null, locationDescription: null}, 
    museum: {inventoryNumber: null, otherInformation: null},
    other: {description: null, latitude: null, longitude: null}
  };
  // 6.
  // note: will probably use the findspot info for the in situ entry.
  vm.findspot = {description: null, latitude: null, longitude: null};
  // 7.
  vm.languages = {main: null, other: null, comment: null};
  // 8.
  vm.objectTypes = {availableTerms: objectTypes, selectedTerms: null, other: null, certain:true};
  // 9.
  vm.materials = {availableTerms: materials, selectedTerms: null, other: null, certain: true};
  // 10.
  vm.inscriptionTypes = {availableTerms: inscriptionTypes, selectedTerms: null, other: null, certain:true};
  // 11. dimensions
  vm.dimensions = {height: null, width: null, depth: null, approximate: false};
  // 12. 
  vm.objectDescription = null;
  // 13.
  vm.generalDescription = null;
  // 14.
  vm.executionTypes = {availableTerms: executionTypes, selectedTerms: null, other: null, certain:true};
  // 15.
  vm.formOfTextComments = null;
  // 16.
  vm.letterHeights = null;
  // 17. transcription
  var transcriptionEditor = CodeMirror.fromTextArea(document.getElementById('transcription'), editorOptions);
  var transcription = transcriptionEditor.getDoc();
  // 18.
  vm.apparatus = null;
  // 19.
  var translationEditor = CodeMirror.fromTextArea(document.getElementById('translation'), editorOptions);
  var translation = translationEditor.getDoc();

  vm.translationLanguage = null;

  //20.
  vm.date = {notBefore: null, notAfter: null};

  // 21.
  vm.commentary = null;

  // 22.
  vm.hasImagesToSubmit = false;

  function getEpidocForTerms(elementName, terms) {
    var result;
    if (terms.selectedTerms) {
      result = terms.selectedTerms.map(term=>`<${elementName} n="${term.term}" ref="${term.uri}" cert="${term.certainty}">${term.term}</${elementName}>`).join('\n');
    };
    if (!terms.selectedTerms || terms.other) {
      result += `<${elementName}>${terms.other}</${elementName}>`;
    }
    return result;
  }

  function getEpidocForExecutionType() {
    var result;
    if (vm.executionTypes.selectedTerms) {
      result = vm.executionTypes.selectedTerms.map(term=>`<rs type="execution" ref="${term.uri}" cert="${term.certainty}">${term.term}</rs>`).join('\n');
    };
    if (!vm.executionTypes.selectedTerms || vm.executionTypes.other) {
      result += `<rs type="execution">${vm.executionTypes.other}</rs>`;
    }
    return result;
  }

  vm.submitInscription = function(ev){
   

    if (! $scope.inscriptionForm.$valid) {  
        $mdDialog.show(
            $mdDialog.alert()
              .clickOutsideToClose(true)
              .title('Not quite there.')
              .textContent("Please complete all marked fields.")
              .ariaLabel('Error.')
              .ok('OK')
              .targetEvent(ev)
            );
      return;
    }

    var otherIds = vm.ids.other?`<idno type="other">TODO ${vm.ids.other}</idno>`:'';

    
    var msIdentifier;
    if (vm.location.site == 'lost' || vm.location.site == 'unknown') {
      msIdentifier = 
      `<msIdentifier><!--Default country and region-->                   
        <country>Italy</country>                   
        <region>Sicily</region>                   
        <settlement>${_.capitalize(vm.location.site)}</settlement>
      </msIdentifier>`;
    } else if (vm.location.site=="museum") {
        if (vm.location.selectedMuseum == "notInList") {
          msIdentifier = `<msIdentifier>                  
            <country>Italy</country>                   
            <region>Sicily</region>                   
            <settlement>${vm.location.otherMuseum.settlement  || ''}</settlement>
            <idno type="inventory">${vm.location.museum.inventoryNumber  || ''}</idno>
            <!--
              'Not in List' Museum Location Description: ${vm.location.otherMuseum.locationDescription} 
              TODO  Further Provided Museum Information: ${vm.location.museum.otherInformation}
            --> 
          </msIdentifier>`;
        } else {
          msIdentifier = `<msIdentifier>                
            <country>Italy</country>                   
            <region>Sicily</region>                   
            <settlement>${vm.location.selectedMuseum.data.town}</settlement>
            <repository role="museum" ref="${vm.location.selectedMuseum.data.uri}">${vm.location.selectedMuseum.data.name}</repository>
            <idno type="inventory">${vm.location.museum.inventoryNumber || ''}</idno>
            <!--
              TODO Further Provided Museum Information: ${vm.location.museum.otherInformation  || ''}
            --> 
          </msIdentifier>`;
        }
    } else if (vm.location.site=="other") {
          msIdentifier = `<msIdentifier>
            <country>TODO</country>                   
            <region>TODO</region>                   
            <settlement>TODO</settlement>
            <repository>TODO</repository>
            <idno type="inventory">TODO</idno>
            <!--
              'Let me Describe It' Description: ${vm.location.other.description || ''}
              Latitude: ${vm.location.other.latitude || ''}
              Longitude: ${vm.location.other.longitude || ''}
            --> 
            <!--
              TODO Further Provided Museum Information: ${vm.location.museum.otherInformation  || ''}
            --> 
          </msIdentifier>`;
    } else if (vm.location.site="situ") {
           msIdentifier = `<msIdentifier><!--Default country and region-->                   
            <country>Italy</country>                   
            <region>Sicily</region>                   
            <settlement>TODO</settlement>
            <repository>TODO</repository>
            <idno type="inventory">TODO</idno>
            <!--
              TODO Further Provided Museum Information: ${vm.location.museum.otherInformation  || ''}
            --> 
          </msIdentifier>`;
    }

    
    var textLang = '';
    var langUsage = '';
    var transcriptionLanguageAttribute = '';

    if (vm.languages.main) {
       textLang = `<textLang mainLang="${vm.languages.main.code}"
        ${vm.languages.other?' otherLangs="' + vm.languages.other.map(lang=>lang.code).join(' ')+'"':''}
      >
        ${vm.languages.comment?'<!-- TODO: comment from submitter: '+vm.languages.comment+'-->':''}
        ${vm.languages.main.fullForm} ${vm.languages.other?' and ' + vm.languages.other.map(lang=>lang.fullForm).join(' and '):''}
      </textLang>  `;

        langUsage = 
      `<language ident="${vm.languages.main.code}">${vm.languages.main.fullForm}</language>
        ${vm.languages.other?vm.languages.other.map(lang=>'<language ident="' + lang.code + '">' + lang.fullForm + '</language>').join('\n'):''}`;
      
       transcriptionLanguageAttribute = `xml:lang="${vm.languages.main.code}${vm.languages.other?' '+vm.languages.other.map(lang=>lang.code).join(' '):''}"`;
    }

    
    var translationLanguageAttribute = vm.translationLanguage?`xml:lang="${vm.translationLanguage}"`:'';

    


    var newXMLDoc = `<?xml version="1.0" encoding="utf-8"?>
<?xml-model href="http://www.stoa.org/epidoc/schema/latest/tei-epidoc.rng" type="application/xml" schematypens="http://relaxng.org/ns/structure/1.0"?>
<TEI xmlns="http://www.tei-c.org/ns/1.0" xml:lang="en">
   <teiHeader>
      <fileDesc>
         <titleStmt>
            <title>I.Sicily inscription 0000</title>
            <editor ref="#JP">Jonathan Prag</editor>
            <principal ref="#JP">Jonathan Prag</principal>
            <funder>John Fell OUP Research Fund</funder>
            <respStmt>
               <name xml:id="JP" ref="http://orcid.org/0000-0003-3819-8537">Jonathan Prag</name>
               <resp>original data collection and editing</resp>
            </respStmt>
            <respStmt>
               <name xml:id="JCu" ref="http://orcid.org/0000-0002-6686-3728">James Cummings</name>
               <resp>conversion to EpiDoc</resp>
            </respStmt>
            <respStmt>
               <name xml:id="JCh" ref="http://orcid.org/0000-0001-6823-0265">James Chartrand</name>
               <resp>site construction and encoding</resp>
            </respStmt>
            <respStmt>
               <name xml:id="VV" ref="http://orcid.org/0000-0002-9695-0240">Valeria Vitale</name>
               <resp>editing of geo data</resp>
            </respStmt>
            <respStmt>
               <name xml:id="MM">Michael Metcalfe</name>
               <resp>museum data collection</resp>
            </respStmt>
            <respStmt>
              <name xml:id="TODO" ${vm.user.orcid?`ref="http://orcid.org/${vm.user.orcid}"`:''}>${vm.user.firstName + ' ' + vm.user.lastName}</name>
              <resp>TODO${' email:  ' + vm.user.email} ${vm.user.shouldNotCite?'   ****SHOULD NOT CITE****':''}</resp>
            </respStmt>
         </titleStmt>
         <publicationStmt>
            <authority>I.Sicily</authority>
            <idno type="filename">${vm.ids.isicily || ''}</idno>
            <idno type="TM">${vm.ids.tm || ''}</idno>
            <idno type="EDR">${vm.ids.edr || ''}</idno>
            <idno type="EDH">${vm.ids.edh || ''}</idno>
            <idno type="EDCS">${vm.ids.edcs || ''}</idno>
            <idno type="PHI">${vm.ids.phi || ''}</idno>
            ${otherIds}

            <availability>
               <licence target="http://creativecommons.org/licenses/by/4.0/">Licensed under a Creative Commons-Attribution 4.0 licence.</licence>
            </availability>
         </publicationStmt>
         <sourceDesc>
            <msDesc>
               ${msIdentifier}
               <msContents>
                  ${textLang}
               </msContents>
               <physDesc>
                  <objectDesc>
                     <supportDesc>
                        <support>
                           ${getEpidocForTerms('material', vm.materials)}<!-- attributes using EAGLE vocab, with term spelled out in element also, e.g., n="marble" ref="http://www.eagle-network.eu/voc/material/lod/48.html"-->
                           ${getEpidocForTerms('objectType', vm.objectTypes)}<!--attributes using EAGLE vocab, with term spelled out in element also, e.g., n="sarcophagus"                                      ref="http://www.eagle-network.eu/voc/objtyp/lod/214.html" -->
                           ${vm.objectDescription || ''}
                           <dimensions>
                              <height unit="cm" precision="${vm.dimensions.precision?'low':'high'}">${vm.dimensions.height || ''}</height>
                              <width unit="cm" precision="${vm.dimensions.precision?'low':'high'}">${vm.dimensions.width || ''}</width>
                              <depth unit="cm" precision="${vm.dimensions.precision?'low':'high'}">${vm.dimensions.depth || ''}</depth>
                           </dimensions>
                        </support>
                     </supportDesc>
                     <layoutDesc>
                        <layout><!--This is default value and requires checking-->
                          ${getEpidocForExecutionType()}
                           ${vm.generalDescription || ''}
                        </layout>
                     </layoutDesc>
                  </objectDesc>
                  <handDesc>
                     <handNote><!--Default value and requires editing-->
                        <p>${vm.formOfTextComments}</p>
                        <locus from="line1" to="line1"></locus>
                        <dimensions type="letterHeight">
                           <p>${vm.letterHeights || ''}</p>
                        </dimensions>
                        <locus from="line1" to="line2">Interlineation line 1 to 2</locus>
                        <dimensions type="interlinear">
                           <height unit="mm"/>
                        </dimensions>
                     </handNote>
                  </handDesc>
               </physDesc>
               <history>
                  <origin>
                  ${vm.location.site == 'situ'?'TODO - marked as in situ':''}
                     <origPlace datingMethod="#julian">
                        <placeName type="ancient"><geo></geo>
                        </placeName><!-- @ref using Pleiades with <geo/> element for decimal degree representative point -->
                        <placeName type="modern"><geo></geo>
                        </placeName><!-- @ref using Geonames with <geo/> element for decimal degree representative point -->
                     </origPlace>
                     <origDate notBefore-custom="${vm.date.notBefore||''}" notAfter-custom="${vm.date.notAfter||''}"></origDate><!-- date in plain text in element and numerical in @notBefore -->
                  </origin>
                  <provenance type="found">${vm.findspot.description || ''} ${(vm.findspot.latitude && vm.findspot.longitude)?'<geo>'+vm.findspot.latitude+','+vm.findspot.longitude+'</geo>':' '}</provenance>
                  <provenance type="observed" subtype="autopsied" resp="${vm.autopsy.name || ''}">${vm.autopsy.date || ''}</provenance>
                  <acquisition></acquisition><!-- at present repeats the current repository information -->
               </history>
            </msDesc>
         </sourceDesc>
      </fileDesc>
      <profileDesc>
         <calendarDesc>
            <calendar xml:id="julian">
               <p>Julian Calendar</p>
            </calendar>
         </calendarDesc>
         <langUsage><!--English and Italian added by default (for translations), to delete where not appropriate; ancient languages to add as per inscription-->
            <language ident="en">English</language>
            <language ident="it">Italian</language>
            ${langUsage}
          </langUsage>
         <textClass>
            <keywords scheme="http://www.eagle-network.eu/voc/typeins.html">
               ${getEpidocForTerms('term', vm.inscriptionTypes)}<!--attributes as per EAGLE vocab, with term spelled out in element also, e.g., n="funerary" ref="http://www.eagle-network.eu/voc/typeins/lod/92.html" -->
            </keywords>
         </textClass>
      </profileDesc>
      <revisionDesc status="unchecked">
         <listChange>
            <change></change><!--date and resp to add to any comment, e.g., when="2016-10-18" who="#JC" -->
         </listChange>
      </revisionDesc>
   </teiHeader>
   <facsimile><!-- default values below: filename template and default image sizes -->
      <surface type="front">
         <graphic n="screen"
                  url="ISic0000_tiled.tif"
                  height="4912px"
                  width="7360px">
            <desc>Add Description Here</desc>
         </graphic>
         <graphic n="print" url="ISic0000.jpg" height="3680px" width="5520px">
            <desc>Add Description Here</desc>
         </graphic>
      </surface>
   </facsimile>
   <text>
      <body>
         <div type="edition" ${transcriptionLanguageAttribute}> 
            <ab>${transcription.getValue().split('\n').reduce((finalString, line, lineNumber)=>finalString+'<lb n="'+(lineNumber+1)+'"/>'+line+'\n', '')}</ab>
         </div>
         <div type="apparatus">
            <p>${vm.apparatus || ''}</p>
         </div>
         <div type="translation" ${translationLanguageAttribute}>
            <p>${translation.getValue().split('\n').reduce((finalString, line, lineNumber)=>finalString+'<lb n="'+(lineNumber+1)+'"/>'+line+'\n', '')}</p>
         </div>
         <div type="commentary">
            <p>
              ${vm.commentary?'TODO:  '+vm.commentary:''}
              ${vm.hasImagesToSubmit?'TODO:  the submitter has images they want to contribute.':''}
            </p>
         </div>
         <div type="bibliography">
            <p>TODO:  ${vm.biblRefs || ''}</p>
         </div>
      </body>
   </text>
</TEI>
`;
  
  
  DataManager.submitInscription(newXMLDoc).then(
    result=>{

      $mdDialog.show(
            $mdDialog.alert()
              .clickOutsideToClose(true)
              .title('Thank you.')
              .textContent('Your inscription has been submitted.')
              .ariaLabel('Doc.')
              .ok('OK')
              .targetEvent(ev)
            )},
    error=>{
      console.log('error in DataManager.submitInscription in inscription-edit.js')
      console.log(error);
      $mdDialog.show(
            $mdDialog.alert()
              .clickOutsideToClose(true)
              .title('Error.')
              .textContent("Sorry, something went wrong.  Please copy the TEI below and email it to jonathan.prag@merton.ox.ac.uk \n" + newXMLDoc)
              .ariaLabel('Doc.')
              .ok('OK')
              .targetEvent(ev)
            )
    }); 
  }
}
  
export default {
  name: 'InscriptionEditCtrl',
  fn: InscriptionEditCtrl
};

