function MuseumsCtrl(MuseumsManager, $state, store, jwtHelper, ModalsManager, GridHelper, museums, config, $stateParams, $window) {
'ngInject';   
  
  const vm = this;  
    
  vm.admin = store.get('jwt')?jwtHelper.decodeToken(store.get('jwt')).role == 'ADMIN':false;

   vm.errorMessage = null;
   vm.rowCount = 'Loading...';

   vm.showMuseums = true;
   vm.showSites = true;

  var sicilyCentre = {lat:37.625, lng:14.100};
  var map = new google.maps.Map(document.getElementById('museums-map'), {
    zoom: 8,
    center: sicilyCentre,
    scrollwheel: false
  })
  //var useShortContent = false; 
  //MuseumsManager.addAllMuseumMarkersToMap(map, useShortContent); 

 // vm.$on('$destroy', function() {  });
/*
  map.addListener('zoom_changed', function() {
      var icon;
      var zoomLevel = map.getZoom();
      if (zoomLevel < 11) {
        icon = {
          url: 'https://maps.gstatic.com/mapfiles/place_api/icons/museum-71.png',
          size: new google.maps.Size(17, 17),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(2, 5),
          scaledSize: new google.maps.Size(17, 17)
        }
      } else if (zoomLevel < 16) {
        icon = {
          url: 'https://maps.gstatic.com/mapfiles/place_api/icons/museum-71.png',
          size: new google.maps.Size(35, 35),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(35, 35)
        }
      } else {
        icon = {
          url: 'https://maps.gstatic.com/mapfiles/place_api/icons/museum-71.png',
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(71, 71)
        }
      }
      $.each(museumMarkers, function(index, marker) {marker.setIcon(icon)});
  }); */

  

  var getPeriods = function(params) {
    var result = '';
      if (params.data.data.first_period) result += MuseumsManager.getPeriodName('first_period') + ', ' ;
      if (params.data.data.second_period) result += MuseumsManager.getPeriodName('second_period') + ', ' ;
      if (params.data.data.third_period) result += MuseumsManager.getPeriodName('third_period') + ', ' ;
      if (params.data.data.fourth_period) result += MuseumsManager.getPeriodName('fourth_period') + ', ' ;
      if (params.data.data.fifth_period) result += MuseumsManager.getPeriodName('fifth_period') + ', ' ;
      if (params.data.data.sixth_period) result += MuseumsManager.getPeriodName('sixth_period') + ', ' ;
      // remove trailing comma
    return result.slice(0, -2);
  }

  var columnDefs = [
      {headerName: 'Name', valueGetter: 'data.data.name', width:239, filter:'text'},
      {headerName: 'Description', valueGetter: 'data.data.collection_description', width:135, filter:'text'},
      {headerName: 'Type', valueGetter: 'data.data.site_type', width:80, filter: 'set'},
      {headerName: 'Inscriptions', valueGetter: 'data.data.inscriptions_on_display', width:135, filter:'text'},
      {headerName: 'Size', valueGetter: 'data.data.size', width:80, filter:'set'},
      {headerName: 'Periods', valueGetter: getPeriods, width:80, filter:'set'},
      {headerName: 'Latitude', valueGetter: 'data.data.latitude', width:70, filter:'number'},
      {headerName: 'Longitude', valueGetter: 'data.data.longitude', width:75, filter:'number'},
      {headerName: 'Town', valueGetter: 'data.data.town', width:100, filter:'set'},
      {headerName: 'Province', valueGetter: 'data.data.province', width:100, filter:'set'},
      {headerName: 'Country', valueGetter: 'data.data.country', width:100, filter:'set'},
      {headerName: 'Address',valueGetter: 'data.data.location_description', width:135, filter: 'text'},
      {headerName: 'Contact', valueGetter: 'data.data.contact_details', width:135,filter:'text'},
      {headerName: 'Pleiades URI', valueGetter: 'data.data.pleiades_uri', width:135,filter:'text'},
      {headerName: 'URI', valueGetter: 'data.data.uri', width:135,filter:'text'}
  ];


  
 // GridHelper.addColumnStyling(columnDefs);

  vm.gridOptions = GridHelper.getBaseGridOptions();

  vm.gridOptions.columnDefs = columnDefs;
  vm.gridOptions.onModelUpdated = modelUpdated;
  vm.gridOptions.onRowClicked = rowClicked;
  vm.gridOptions.isExternalFilterPresent = isExternalFilterPresent;
  vm.gridOptions.doesExternalFilterPass = doesExternalFilterPass;
  vm.gridOptions.onGridReady = function() {
      vm.gridOptions.api.showLoadingOverlay();
      vm.gridOptions.api.setRowData(museums);
  }

  function updateMap(gridModel) {
    var useShortContent = false;

    let filteredRows = gridModel.rowsAfterFilter?gridModel.rowsAfterFilter:gridModel.rowsToDisplay

    for (let museum of museums) {
      museum.removeFromMap(map);
    }
    for (let museum of filteredRows) { 
      museum.data.addToMap(map, useShortContent)
    }
}
  

  function modelUpdated() {
      var gridModel = vm.gridOptions.api.getModel();
      vm.rowCount = GridHelper.getRowCount(gridModel);
     // GridHelper.adjustGridHeight(gridModel, '#museum-grid');
      updateMap(gridModel);
  }

  function isExternalFilterPresent() {
    return (! vm.showSites) || (! vm.showMuseums);
  }

  function doesExternalFilterPass(node) {
    return ( vm.showSites && node.data.data.site_type == 'SITE' ) ||
      ( vm.showMuseums && node.data.data.site_type == 'MUSEUM' )
  }

  vm.applyTypeFilter = function () {
    vm.gridOptions.api.onFilterChanged();
  };

  function rowClicked(params) {
     // $state.go('museum', {id: params.data.data.id});   
    var museumURI = config.MUSEUM_URI + params.data.data.id;
    if ($stateParams.lang == 'it') {museumURI = museumURI + '?lang=it'};
    $window.open(museumURI); 
  }

  vm.addMuseum = function() {
    $state.go('museum-edit');
  }

  vm.exportCSV = function(){
      ModalsManager.openExport(vm.gridOptions.api);
  }
}

export default {
  name: 'MuseumsCtrl',
  fn: MuseumsCtrl
};