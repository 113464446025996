import translations from './translations';

function OnConfig($stateProvider, $locationProvider, $mdThemingProvider, $urlRouterProvider, $httpProvider, jwtInterceptorProvider, $translateProvider) {
  'ngInject';


  //$qProvider.errorOnUnhandledRejections(false);

  $locationProvider.html5Mode(true);

  //jwtInterceptorProvider.urlParam = 'access_token';
  //jwtInterceptorProvider.tokenGetter = function(store) {
  //  'ngInject';
   // return store.get('jwt');
  //}
  //$httpProvider.interceptors.push('jwtInterceptor');

  // add the jwt token to all outgoing http requests, in the header as X-Authorization
  var jwtTokenInterceptor = function(store) {
    'ngInject';
      return {
        'request': function(config) {
          config.headers['X-Authorization'] = `Bearer ${store.get('jwt')}`;
          return config;
         }
      }
  };
  $httpProvider.interceptors.push(jwtTokenInterceptor);

  $mdThemingProvider.theme('docs-dark', 'default')
      .primaryPalette('yellow')
      .dark();

  // to allow CORS
  $httpProvider.defaults.useXDomain = true;
  $httpProvider.defaults.headers.put = { 'X-Authorization' : 'value' }
  // for now, while default auth is on.
  //$httpProvider.defaults.withCredentials = true;
  //$httpProvider.defaults.headers.common.Authorization = 'Basic isicily:epidoc';

  $translateProvider
      .translations('en', translations.translations_en)
      .translations('it', translations.translations_it)
      .preferredLanguage('en');

  $stateProvider
  .state('master', {
    abstract: true,
    
    views: {
      layout: {
        templateUrl: 'layout.html',
        controller: 'LayoutCtrl as layoutVM'
      }
    }
  })
  .state('inscriptions', {
    url: '/inscriptions/?lang',
    controller: 'InscriptionsCtrl as inscriptionsVM',
    templateUrl: 'inscriptions.html',
    title: 'Inscriptions',
    parent: 'master'
  })
  .state('inscription', {
    url: '/inscription/:id?lang',
    controller: 'InscriptionCtrl as inscriptionVM',
    templateUrl: 'inscription.html',
    title: 'Inscription',
    parent: 'master',
    resolve: {
      inscription: function(InscriptionsManager, $stateParams){'ngInject'; return InscriptionsManager.getInscription($stateParams.id)}
    }
  })
  .state('inscription-submit', {
    url: '/inscription-submit',
    controller: 'InscriptionEditCtrl as inscriptionEditVM',
    templateUrl: 'inscription-edit.html',
    title: 'Inscription Submission',
    parent: 'master',
    resolve: {
      //inscription: function(InscriptionsManager, $stateParams){'ngInject'; return InscriptionsManager.getEditableInscription($stateParams.id)},
      materials: function(VocabsManager){'ngInject'; return VocabsManager.loadMaterials()},
      executionTypes: function(VocabsManager){'ngInject'; return VocabsManager.loadExecutionTypes()},
      objectTypes: function(VocabsManager){'ngInject'; return VocabsManager.loadObjectTypes()},
      inscriptionTypes: function(VocabsManager){'ngInject'; return VocabsManager.loadInscriptionTypes()},
      museums: function(MuseumsManager){'ngInject'; return MuseumsManager.loadMuseums()}
    },
    data: {
      requiresRole: ['BASIC', 'ADMIN']
    }
  })
  .state('inscription-printable', {
    url: '/inscription-printable/:id?lang',
    title: 'Inscription Printable',
    templateUrl: 'inscription-printable.html',
    controller: 'InscriptionPrintableCtrl as inscriptionVM',
    resolve: {
      inscription: function(InscriptionsManager, $stateParams){'ngInject'; return InscriptionsManager.getInscription($stateParams.id)}
    }
  })
  .state('publications', {
    url: '/publications?lang', 
    templateUrl: 'publications.html',
    controller: 'PublicationsCtrl as publicationsVM',
    title: 'Publications',
    parent: 'master',
    resolve: {
      publications: function(PublicationsManager){'ngInject'; return PublicationsManager.loadPublications()}
    }
      })
  .state('publication', {
        url: '/publication/:id?lang', 
        templateUrl: 'publication.html',
        controller: 'PublicationCtrl as publicationVM',
        title: 'Publication',
        parent: 'master',
        resolve: {
          publication: function(PublicationsManager, $stateParams){'ngInject'; return PublicationsManager.getPublication($stateParams.id)}
        }
      })
  .state('museums', {
    url: '/museums?lang',
    templateUrl: 'museums.html',
    controller: 'MuseumsCtrl as museumsVM',
    title: 'Museums',
    parent: 'master',
    resolve: {
      museums: function(MuseumsManager){'ngInject'; return MuseumsManager.loadMuseums()}
    }
  })
  .state('museum', {
    url: '/museum/:id?lang',
    templateUrl: 'museum.html',
    controller: 'MuseumCtrl as museumVM',
    title: 'Museum',
    parent: 'master',
    resolve: {
      museum: function(MuseumsManager, $stateParams){'ngInject'; return MuseumsManager.get($stateParams.id)}
    }
  })
  .state('museum-edit', {
    url: '/museum-edit/:id',
    templateUrl: 'museum-edit.html',
    controller: 'MuseumEditCtrl as museumEditVM',
    title: 'Museum Edit',
    parent: 'master',
    resolve: {
      museum: function(MuseumsManager, $stateParams){'ngInject'; return MuseumsManager.get($stateParams.id)}
    },
    data: {
      requiresRole: ['ADMIN']
    }
  })
  .state('about', {
        url: '/about?lang', 
        templateUrl: 'about.html',
        title: 'About',
        parent: 'master'
  })
  .state('welcome', {
          url: '/?lang',
          templateUrl: 'welcome.html',
          controller: 'WelcomeCtrl as welcomeVM',
          title: 'Welcome'

      })
  .state('admin', {
    url: '/admin',
    templateUrl: 'admin.html',
    controller: 'AdminCtrl as adminVM',
    title: 'Admin',
    parent: 'master',
    resolve: {
      users: function(UsersManager){'ngInject'; return UsersManager.getUsers()},
      comments: function(CommentsManager){'ngInject'; return CommentsManager.getComments()},
    },
    data: {
      requiresRole: ['ADMIN']
    }
  })
  .state('signup', {
    url: '/signup/:nextState/:id?lang',
    templateUrl: 'signup.html',
    controller: 'SignupCtrl as signupVM',
    title: 'Signup',
    parent: 'master'
  })
  .state('login', {
    url: '/login/:nextState/:id?lang',
    templateUrl: 'login.html',
    controller: 'LoginCtrl as loginVM',
    title: 'Login',
    parent: 'master'
  })
  .state('logout', {
    title: 'Logout'
  });

//
 // .state("otherwise", {
 //   url: "*path",
 //   templateUrl: "/en"
//});
  $urlRouterProvider.otherwise('/');


}

export default OnConfig;


