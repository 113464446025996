"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _sharedRules = _interopRequireDefault(require("./sharedRules.js"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var getDescendants = function getDescendants(node, accum) {
  accum = accum || [];

  _toConsumableArray(node.childNodes).forEach(function (child) {
    accum.push(child);
    getDescendants(child, accum);
  });

  return accum;
};

var addSingleSpaceSpan = function addSingleSpaceSpan(node) {
  var whitespaceElem = document.createElement('span');
  whitespaceElem.className += ' single-space-holder';
  node.appendChild(whitespaceElem);
};

function underline(node) {
  var underlineSpan = document.createElement('span');
  underlineSpan.className = 'underline';

  _toConsumableArray(node.childNodes).forEach(function (child) {
    return underlineSpan.appendChild(child);
  });

  node.appendChild(underlineSpan);
}

function addOpeningBracket(reason, node) {
  if (reason === 'lost') {
    node.prepend('[');
  } else if (reason === 'omitted') {
    node.prepend('<');
  } else if (reason === 'subaudible') {
    node.prepend('(scil. ');
  }
}

function addClosingBracket(reason, node) {
  if (reason === 'lost') {
    node.append(']');
  } else if (reason === 'omitted') {
    node.append('>');
  } else if (reason === 'subaudible') {
    node.append(')');
  }
}
/* 
    when we hit a supplied, prepend a square bracket, and then start looking for an adjacent supplied.
    As soon as we hit a text node with actual text, stop, and append a bracket to the last supplied we found.
    If we hit another supplied, then start looking for another.

    */


var mergeAdjacentSupplied = function mergeAdjacentSupplied(node, tw) {
  var isUncertain = node.getAttribute('cert') === 'low';
  var reason = node.getAttribute('reason');
  var lastVisitedSupplied = node;
  addOpeningBracket(reason, node);
  if (isUncertain) node.append('(?)');
  var descendants = getDescendants(node);
  var currentNode = tw.nextNode();

  while (currentNode) {
    if (descendants.includes(currentNode)) {
      // skip all descendants of 'supplied'
      // POSSIBLE TODO:  check for breaks (lb ab cb) in descendants, and if there, add an
      // opening bracket before, and a closing bracket after.
      currentNode = tw.nextNode();
    } else if (isInterveningText(currentNode) || isBreak(currentNode) || isNotElidableSupplied(currentNode, reason)) {
      currentNode = null; // we are done
    } else if (currentNode.nodeType === Node.ELEMENT_NODE && currentNode.nodeName === 'supplied') {
      // we've found another adjacent supplied
      lastVisitedSupplied = currentNode;
      if (currentNode.getAttribute('cert') === "low") currentNode.append('(?)');
      currentNode.setAttribute('leiden-processed', 'true'); // this is so we don't apply our rule to this 'supplied' later

      descendants = getDescendants(currentNode); // now ignore the descendants of this 'supplied' node 

      currentNode = tw.nextNode();
    } else {
      // skip over any other nodes, e.g, empty text nodes, other elements, etc.
      currentNode = tw.nextNode();
    }
  } // need to append the end bracket here if we've reached the end of the elements, 
  // without having hit a text node earlier


  addClosingBracket(reason, lastVisitedSupplied); // reset tree walker back to original node

  tw.currentNode = node;
};

function isNotElidableSupplied(currentNode, firstReason) {
  return currentNode.nodeType === Node.ELEMENT_NODE && currentNode.nodeName === 'supplied' && (currentNode.getAttribute('reason') !== firstReason || currentNode.getAttribute('evidence') === 'previouseditor');
}

function isBreak(currentNode) {
  return currentNode.nodeType === Node.ELEMENT_NODE && ['lb', 'ab', 'cb', 'div'].includes(currentNode.nodeName);
}

function isInterveningText(currentNode) {
  return currentNode.nodeType === Node.TEXT_NODE && currentNode.nodeValue.trim().length;
}

function processHi(node) {
  var rend = node.getAttribute('rend');

  if (rend === 'ligature') {
    // add circumflex over every character except last
    node.textContent = node.textContent.split('').join("\u0302");
  } else if (rend === "apex") {
    var oldText = node.textContent;
    node.textContent = oldText.charAt(0) + "\u0301" + oldText.substring(1);
  } else if (rend === "reversed") {
    node.prepend('((');
    node.append('))');
  } else if (rend === "intraline") {
    var strikethrough = document.createElement('span');
    strikethrough.textContent = node.textContent;
    strikethrough.className += ' strikethrough';
    node.textContent = '';
    node.appendChild(strikethrough);
    /* const strikethrough = document.createElement('s');
    strikethrough.textContent = node.textContent;
    node.textContent = '';
    node.appendChild(strikethrough); */
  } else if (rend === "supraline") {
    var supraline = document.createElement('span');
    supraline.textContent = node.textContent;
    supraline.className += ' supraline';
    node.textContent = '';
    node.appendChild(supraline);
  } else if (rend === "underline") {
    var _underline = document.createElement('span');

    _underline.textContent = node.textContent;
    _underline.className += ' underline';
    node.textContent = '';
    node.appendChild(_underline);
  } else if (rend === "superscript") {
    var sup = document.createElement('sup');
    sup.textContent = node.textContent;
    node.textContent = '';
    node.appendChild(sup);
  }
}

var hyperlinkNode = function hyperlinkNode(node) {
  var ref = node.getAttribute('ref');

  if (ref) {
    var a = document.createElement('a');
    var href = document.createAttribute('href');
    href.value = ref;
    a.setAttributeNode(href);

    _toConsumableArray(node.childNodes).forEach(function (child) {
      return a.appendChild(child);
    });

    node.appendChild(a);
  }
};

var makePopupable = function makePopupable(node, popupText, openPopup) {
  var sup = document.createElement('sup'); // lighter arrow: \u2197   darker arrow: \u2B08

  sup.append("\u2997\u2197\u2998");
  var span = document.createElement('span');
  span.addEventListener("click", function () {
    return openPopup(popupText);
  }); // copy the nodes children to the new span

  _toConsumableArray(node.childNodes).forEach(function (child) {
    return span.appendChild(child);
  });

  span.appendChild(sup);
  node.appendChild(span);
};

var appendSpaceToNode = function appendSpaceToNode(node, tw) {
  /****  IMPORTANT: textContent removes all children and sets text of this node to a concatentation of children's text */
  node.textContent = node.textContent + ' ';
};

var rules = _objectSpread({
  'w': function w(node) {
    if (node.getAttribute('part') === 'I') {
      var exChild = node.querySelector('ex');

      if (exChild) {
        exChild.append('-');
      }
    }
  },
  'ex': function ex(node) {
    var cert = node.getAttribute('cert');
    node.prepend('(');
    if (cert === 'low') node.append('?');
    node.append(')');
  },
  'abbr': function abbr(node) {
    if (node.parentNode.nodeName !== 'expan') node.append('(- - -)');
  },
  'am': function am(node) {
    node.textContent = '';
  },
  'del': function del(node) {
    var rend = node.getAttribute('rend');

    if (rend === "erasure") {
      node.prepend('⟦');
      node.append('⟧');
    }
  },
  'handShift': function handShift(node) {
    var newAttribute = node.getAttribute('new');
    var n = newAttribute.lastIndexOf('h');
    var handNumber = '';

    if (n) {
      var number = newAttribute.substring(n + 1);

      if (number) {
        handNumber = ' ' + number;
      }
    }

    node.textContent = "((hand".concat(handNumber, "))");
  },
  'subst': function subst(node, tw, openPopup) {
    var del = node.querySelector('del');

    if (del) {
      var rend = del.getAttribute('rend');

      if (rend === 'corrected') {
        var popupText = "Deleted: ".concat(del.textContent);
        del.parentNode.removeChild(del);
        makePopupable(node, popupText, openPopup);
      }
    }
  },
  'num': function num(node, tw, openPopup) {
    var value = node.getAttribute('value');
    var atLeast = node.getAttribute('atLeast');
    var atMost = node.getAttribute('atMost');
    var popupText;

    if (value) {
      popupText = value;
    } else if (atLeast && atMost) {
      popupText = "".concat(atLeast, "-").concat(atMost);
    }

    if (popupText) {
      makePopupable(node, popupText, openPopup);
    }
  },
  'add': function add(node) {
    var place = node.getAttribute('place');

    if (place === 'overstrike') {
      node.prepend('«');
      node.append('»');
    } else if (place === 'above') {
      node.prepend('`');
      node.append('´');
    }
  },
  'surplus': function surplus(node) {
    node.prepend('{');
    node.append('}');
  },
  'desc': function desc(node) {
    node.prepend('(');
    node.append(')');
  },
  'note': function note(node) {
    node.prepend('(');
    node.append(')');
  },
  'g': appendSpaceToNode,
  // 'name': appendSpaceToNode,
  'placename': hyperlinkNode,
  'persname': hyperlinkNode,
  'supplied': function supplied(node, tw) {
    // ignore 'supplied' that we merged into a prior 'supplied'
    if (node.getAttribute('leiden-processed') === 'true') return null;

    if (node.getAttribute('evidence') === 'previouseditor') {
      // simply underline if previouseditor, no square brackets
      underline(node);
    } else {
      mergeAdjacentSupplied(node, tw);
    }
  },
  'hi': function hi(node) {
    processHi(node);
  },
  'choice': function choice(node, tw, openPopup) {
    var reg = node.querySelector('reg');
    var corr = node.querySelector('corr');

    if (reg) {
      var popupText = "Regularized: ".concat(reg.textContent);
      reg.parentNode.removeChild(reg);
      makePopupable(node, popupText, openPopup);
    } else if (corr) {
      var _popupText = "Corrected: ".concat(corr.textContent);

      corr.parentNode.removeChild(corr);
      makePopupable(node, _popupText, openPopup);
    }
  }
}, (0, _sharedRules["default"])(true));

var _default = rules;
exports["default"] = _default;