function AdminCtrl($http, $mdDialog, config, DataManager, InscriptionsManager, MuseumsManager, PublicationsManager, UsersManager, CommentsManager, VocabsManager, GridHelper, users, comments) {
'ngInject';
  
  const vm = this;

  vm.GridHelper = GridHelper;
  vm.errorMessage = null;
  
  vm.inscription_types = null;
  vm.execution_types = null;
  vm.object_types = null;
  vm.materials = null;

  vm.addInscriptionType = function() {
    VocabsManager.addInscriptionType(vm.newInscriptionTerm, vm.newInscriptionURI).then(
     function(types) {
      vm.inscription_types = types;
    })
  };

  vm.deleteInscriptionType = function(id) {
    VocabsManager.deleteInscriptionType(id).then(
     function(types) {
      vm.inscription_types = types;
    })
  };

  vm.addObjectType = function() {
    VocabsManager.addObjectType(vm.newObjectTerm, vm.newObjectURI).then(
     function(types) {
      vm.object_types = types;
    })
  };

  vm.deleteObjectType = function(id) {
    VocabsManager.deleteObjectType(id).then(
     function(types) {
      vm.object_types = types;
    })
  };

  vm.addExecutionType = function() {
    VocabsManager.addExecutionType(vm.newExecutionTerm, vm.newExecutionURI).then(
     function(types) {
      vm.execution_types = types;
    })
  };

  vm.deleteExecutionType = function(id) {
    VocabsManager.deleteExecutionType(id).then(
     function(types) {
      vm.execution_types = types;
    })
  };

   vm.addMaterial = function() {
    VocabsManager.addMaterial(vm.newMaterialTerm, vm.newMaterialURI).then(
     function(types) {
      vm.materials = types;
    })
  };

  vm.deleteMaterial = function(id) {
    VocabsManager.deleteMaterial(id).then(
     function(types) {
      vm.materials = types;
    })
  };



  VocabsManager.loadInscriptionTypes().then(
    function(types) {
      vm.inscription_types = types;
    });

  VocabsManager.loadObjectTypes().then(
    function(types) {
      vm.object_types = types;
    });

  VocabsManager.loadExecutionTypes().then(
    function(types) {
      vm.execution_types = types;
    });

  VocabsManager.loadMaterials().then(
    function(types) {
      vm.materials = types;
    });

  
  function showPopup(title, message) {
   // swal({
    ///  title: title,
   //   text: message,
   //   type: type,
   //   showCancelButton: false,
   //   confirmButtonText: 'OK',
   //   closeOnConfirm: true
   // })

    $mdDialog.show(
      $mdDialog.alert()
        .clickOutsideToClose(true)
        .title(title)
        .textContent(message)
        .ariaLabel('ISicily message')
        .ok('OK')
    );

  }

  vm.refreshData = function(type) {
    
    DataManager.refreshCaches(type).then(
      function(data) { 
        InscriptionsManager.loadInscriptions();
        MuseumsManager.loadMuseums();
        PublicationsManager.loadPublications();
        $mdDialog.show(
          $mdDialog.alert()
            .clickOutsideToClose(true)
            .title('Done.')
            .textContent('All data has been rebuilt.')
            .ariaLabel('ISicily message')
            .ok('OK')
        );
      },
      function(errorResponse) {  
        console.log('error in call to reload data: ');
        console.log(errorResponse);  
        showPopup('Error.', 'There was a problem refreshing data: ' + errorResponse, 'error');  
      }
    )
    $mdDialog.show(
          $mdDialog.alert()
            .clickOutsideToClose(true)
            .title('Rebuilding.')
            .textContent('We\'ll let you know when it\'s done.')
            .ariaLabel('ISicily message')
            .ok('OK')
        );
  }

 function updateUser(params) {
    UsersManager.updateUser(params.data);
 }

 function updateCommentStatus(params) {
    CommentsManager.updateStatus(params.data.id, params.data.status);
 }

  var roles = ['ADMIN','BASIC','PENDING','DISABLED'];
  var commentStatuses = ['PENDING','DONE','FLAG_1', 'FLAG_2', 'FLAG_3'];

  var columnDefs = [
      {headerName: 'Id', field: 'id', width:70},
      {headerName: 'First Name', field: 'first_name', width:260, filter:'text', editable: true, onCellValueChanged: updateUser},
      {headerName: 'Last Name', field: 'last_name', width:260, filter:'text', editable: true, onCellValueChanged: updateUser},
      {headerName: 'Email', field: 'email', width:260, filter:'text', editable: true, onCellValueChanged: updateUser},
      {headerName: 'Role', field: 'role', width:110, filter:'set', cellRenderer: roleEditor}
  ];
  var commentsColumnDefs = [
      {headerName: 'Incription', field: 'inscription_id', width:70, onCellClicked:showComment},
      {headerName: 'Field', field: 'field_name', width:260, filter:'text', editable: false, onCellClicked:showComment},
      {headerName: 'Comment', field: 'comment_text', width:260, filter:'text', editable: false, onCellClicked:showComment},
      {headerName: 'Orcid', field: 'orcid_id', width:260, filter:'text', editable: false, onCellClicked:showComment},
      {headerName: 'Submitted By', field: 'submitted_by', width:260, filter:'text', editable: false, onCellClicked:showComment},
      {headerName: 'Email', field: 'email', width:260, filter:'text', editable: false, onCellClicked:showComment},
      {headerName: 'Status', field: 'status', width:110, filter:'set', cellRenderer: statusEditor}
  ];

function onCellClicked(params){

}

  GridHelper.addColumnStyling(columnDefs);
  GridHelper.addColumnStyling(commentsColumnDefs);

 

  function statusEditor(params) {
     return dropDownEditor(params, commentStatuses, updateCommentStatus);
  }

  function roleEditor(params) {
    return dropDownEditor(params, roles, updateUser);
  }


  function dropDownEditor(params, values, changeCallback) {

        var editing = false;

        var eCell = document.createElement('span');
        var eLabel = document.createTextNode(params.value);
        eCell.appendChild(eLabel);

        var eSelect = document.createElement('select');

        values.forEach(function(item) {
            var eOption = document.createElement('option');
            eOption.setAttribute('value', item);
            eOption.innerHTML = item;
            eSelect.appendChild(eOption);
        });
        eSelect.value = params.value;

        eCell.addEventListener('click', function () {
            if (!editing) {
                eCell.removeChild(eLabel);
                eCell.appendChild(eSelect);
                eSelect.focus();
                editing = true;
            }
        });

        eSelect.addEventListener('blur', function () {
            if (editing) {
                editing = false;
                eCell.removeChild(eSelect);
                eCell.appendChild(eLabel);
            }
        });

        eSelect.addEventListener('change', function () {
            if (editing) {
                editing = false;
                var newValue = eSelect.value;
                params.data[params.colDef.field] = newValue;
                eLabel.nodeValue = newValue;
                eCell.removeChild(eSelect);
                eCell.appendChild(eLabel);
                changeCallback(params);
                
            }
        });
        return eCell;
    }

  function modelUpdated() {
     var gridModel = vm.gridOptions.api.getModel();
      vm.rowCount = GridHelper.getRowCount(gridModel);
      GridHelper.adjustGridHeight(gridModel, '#user-grid');
  }

  function commentModelUpdated() {
     var gridModel = vm.commentsGridOptions.api.getModel();
      vm.commentsRowCount = GridHelper.getRowCount(gridModel);
      GridHelper.adjustGridHeight(gridModel, '#comments-grid');
  }

  function showComment(params) {
    var inscriptionId = params.data.inscription_id;
    var field = params.data.field_name;
    var commentText = params.data.comment_text;
    var submittedBy = params.data.submitted_by || 'No name provided.';
    var orcidId = params.data.orcid_id || 'No ORCID Id provided.';
    var email = params.data.email || 'No email provided.';

    var commentHTML = `<div>${commentText}</div></br><div><b>Submitted By</b>: ${submittedBy}</div><div><b>ORCID Identifier</b>: ${orcidId}</div><div><b>Email</b>: ${email}</div>`;
    $mdDialog.show(
          $mdDialog.alert()
            .clickOutsideToClose(true)
            .title(`Inscription: ${inscriptionId} -- Field: ${field}`)
            .htmlContent(commentHTML)
            .ariaLabel('Comment')
            .ok('OK')

        );
  }


  vm.gridOptions = GridHelper.getBaseGridOptions();
  vm.gridOptions.rowHeight=48;
  vm.gridOptions.columnDefs = columnDefs;
  vm.gridOptions.onModelUpdated = modelUpdated;
  vm.gridOptions.onGridReady = function() {
      vm.gridOptions.api.showLoadingOverlay();
      vm.gridOptions.api.sizeColumnsToFit();
      vm.gridOptions.api.setRowData(users);
  }

  //angularCompileRows: true,
  
  vm.commentsGridOptions = GridHelper.getBaseGridOptions();
  vm.commentsGridOptions.columnDefs = commentsColumnDefs;
  //vm.commentsGridOptions.onRowClicked = showComment;
  vm.commentsGridOptions.onModelUpdated = commentModelUpdated;
  vm.commentsGridOptions.onGridReady = function() {
      vm.commentsGridOptions.api.showLoadingOverlay();
      vm.commentsGridOptions.api.sizeColumnsToFit();
      vm.commentsGridOptions.api.setRowData(comments);
  }
 

}
export default {
  name: 'AdminCtrl',
  fn: AdminCtrl
};