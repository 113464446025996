import $ from 'jquery';
import _ from 'lodash';
//var convert = require('@isicily/epidoc-viewer-core');
import {convert} from '@isicily/epidoc-viewer-core';
import angular from 'angular';

// angular modules
import constants from './constants';
import onConfig  from './on_config';
import onRun     from './on_run';
import 'angular-ui-router';
import 'angular-ui-bootstrap';
import 'angular-animate';
import 'angular-aria';
import 'angular-messages';
import 'angular-material';
import 'angular-sanitize';
import {LicenseManager} from 'ag-grid-enterprise/main';

//console.log('the convert function:')
//console.log(convert)
LicenseManager.setLicenseKey("I.Sicily_I.Sicily_Non_Commercial_License_1Devs27_February_2018__MTUxOTY4OTYwMDAwMA==7e645a1c94f2ff20526a6d816cbd9edb");

// ag grid
import {initialiseAgGridWithAngular1} from 'ag-grid/main';
import 'ag-grid';

import CodeMirror from 'codemirror';
//import 'angular-ui-codemirror';
import 'ui-select';
import 'angular-jwt';
import 'angular-storage';
import 'vkbeautify';

import 'angular-translate';
import './templates';
import './filters';
import './controllers';
import './services';
import './directives';


initialiseAgGridWithAngular1(angular);

// create and bootstrap application
const requires = [
  'ui.router',
  'templates',
  'app.filters',
  'app.controllers',
  'app.services',
  'app.directives',
  'ngAnimate',
  'ngAria',
  'ngMessages',
  'ngMaterial',
  'ui.bootstrap',
  //'ui.codemirror',
  'ngSanitize', 
  'ui.select',
  'angular-jwt',
  'angular-storage',
  'agGrid',
 // 'angular-multi-select',
  'pascalprecht.translate'
];

// mount on window for testing
window.app = angular.module('app', requires);

angular.module('app').constant('config', constants);

angular.module('app').config(onConfig);

angular.module('app').run(onRun);

angular.bootstrap(document, ['app'], {
  strictDi: true
});
