import $ from 'jquery';
import _ from 'lodash';
// bunch of things shared by grids across the site

function GridHelper(MuseumsManager, InscriptionsManager, PublicationsManager, DataManager, MapHelper, $q, $stateParams, TEIHelper, InscriptionModal){
'ngInject';

    
    function resetGrid(gridOptions) {
        var gridApi = gridOptions.api;
        var columnAPI = gridOptions.columnApi;

        gridApi.setFilterModel({});
        gridApi.setSortModel({});

        gridOptions.quickFilterText = '';

        columnAPI.resetState();
       // $.each(columnAPI.getPivotedColumns(), function(i, column) {columnAPI.removePivotColumn(column)});
    }

    function getRowCount(gridModel) {
        const removeDeprecated = true
    var totalRows = InscriptionsManager.getInscriptionCount(removeDeprecated);
    var processedRows = gridModel.getRowCount();
    if (totalRows > 0) {
          return processedRows.toLocaleString() + '/' + totalRows.toLocaleString();
    } else {
          return 'Nothing to show.';
    }    
  }

  function adjustGridHeight(gridModel, gridDOMId) {
    var processedRows = gridModel.getRowCount();
    $(gridDOMId).height(function(index,currentheight) {
          if (processedRows > 15) {
            return 500;
          } else {
            return 115 + processedRows * 30;
          }
        }) 
  }

  function yearConverter(params) {
      if (!params.value || params.value.trim().length == 0) return '';
      var theYear = +params.value;
      if (theYear < 0) {
        return Math.abs(theYear).toString() + ' BC';
      } else if (theYear == 0) {
        return '0';
      } else {
        return theYear.toString() + ' AD';
      }
  }

  function getBaseGridOptions() {
    var baseOptions = {
      rowData: [],
      enableColResize: true,
        floatingFilter: true,
      enableSorting: true,
      groupHeaders: true,
      enableFilter: true,
      suppressCellSelection: true,
      rowHeight: 30,
      rowStyle: { 'font-size': '16px', 'font-family': 'Helvetica,Verdana,Arial,sans-serif', 'display': 'table-cell',
  'vertical-align': 'middle'},
  icons: {
        checkboxChecked: '<img src="data:image/png;base64,..."/>'
    },
      pinnedColumnCount: 1,
      rowGroupPanelShow: 'always',
      showToolPanel:false,
      toolPanelSuppressValues:true,
        toolPanelSuppressPivots: true,
        toolPanelSuppressPivotMode: true
    };
    if ($stateParams.lang=='it') {
      baseOptions.localeText = {rowGroupColumnsEmptyMessage: 'Trascina colonne qui per raggruppare'}
    }

    return baseOptions
  }

  function addColumnStyling(columns) {
      for (let column of columns) {
        if (column.children) {
            addColumnStyling(column.children);
        } else {
            if (!column.headerClass) column.headerClass='grid-header-text';
            if (!column.cellClass) column.cellClass = 'ag-isicily-cell';
        }
      }
  }

 
    var _getCorpusValue = function(params) {
    
    var biblArray = [].concat(params.data.bibl);
    var result = biblArray.filter(
      biblItem=>biblItem && biblItem.n && biblItem.n === params.colDef.colId
    ).map(
      corpusMatch=>{
        let stringToShow = ''
        if (corpusMatch.n === 'NSA' ) {
          stringToShow += corpusMatch.date + ': ';
        }
        if (corpusMatch.citedRange) {
          stringToShow += corpusMatch.citedRange.ref?corpusMatch.citedRange.ref['#text']:corpusMatch.citedRange
        } else {
          stringToShow += corpusMatch
        }
        return stringToShow
      }
    ).join('; ')
    return result || '';
  }

  // closure to create single copy of corpora abbreviations
  var corporaAbbreviations = getCorporaColumns().map((corpora)=>corpora.colId);

  function getCorporaAbbreviations() {
    return corporaAbbreviations;
  }
  
  function getCorporaColumns(){

       let columns =  [
           { headerName: 'AE', colId: 'AE', longName: ' - L’Année épigraphique', zoteroKey: 'R46KDTZX'},
           { headerName: 'BE', colId: 'BE', longName: ' - Bulletin Épigraphique', zoteroKey: '77P5MNIR'},
           { headerName: 'CIG', colId: 'CIG', longName: ' - Corpus Inscriptionum Graecarum', zoteroKey: 'HTUDQBXS'},
           { headerName: 'CIL', colId: 'CIL', longName: ' - Corpus Inscriptionum Latinarum', zoteroKey: 'GQN8UZSI'},
           { headerName: 'CIS', colId: 'CIS', longName: ' - Corpus Inscriptionum Semiticarum', zoteroKey: 'JAK7C48W'},
           { headerName: 'IG', colId: 'IG', longName: ' - Inscriptiones Graecae', zoteroKey: 'Q2SBPG9F'},
           { headerName: 'NSA', colId: 'NSA', longName: 'Notizie degli Scavi di Antichità', zoteroKey: '4MCP2PVV'},
           { headerName: 'SEG', colId: 'SEG', longName: ' - Supplementum Epigraphicum Graecum', zoteroKey: 'F2GG87EQ'},
           { headerName: 'IFPCO', colId: 'IFPCO', longName: ' - Le iscrizioni fenicie e puniche delle colonie in Occidente', zoteroKey: 'Q6R2U5KS'},
           { headerName: 'IGDS', colId: 'IGDS', longName: ' - Inscriptions grecques dialectales de Sicile', zoteroKey: 'RWXNUFEX'},
           { headerName: 'IGRR', colId: 'IGRR', longName: ' - Inscriptiones Graecae ad Res Romanas Pertinentes', zoteroKey: 'KSH4D9GK'},
           { headerName: 'I.G.Palermo', colId: 'IGMusPalermo', longName: ' - Iscrizioni greche lapidarie del Museo di Palermo', zoteroKey: 'F66S4X3M'},
           { headerName: 'I.G.Termini', colId: 'IGMusTermini', longName: ' - Iscrizione greche del museo civico di Termini Imerese', zoteroKey: '9WZ7KU5C'},
           { headerName: 'I.Messina', colId: 'IMusMessina', longName: ' - Le Iscrizioni Greche e Latine Di Messina', zoteroKey: 'M9HMEP2R'},
           { headerName: 'I.Catania', colId: 'IMusCatania', longName: ' - Le Iscrizioni del Museo Civico di Catania', zoteroKey: '2EUC82DH'},
           { headerName: 'I.Lipara', colId: 'ILipara', longName: ' - Meligunìs Lipára. XII. Le iscrizioni lapidarie greche e latine delle isole eolie', zoteroKey: 'VDAQH5D2'},
           { headerName: 'I.L.Termini', colId: 'ILMusTermini', longName: ' - Iscrizioni latine lapidarie del museo civico di Termini Imerese', zoteroKey: 'JCV85C79'},
           { headerName: 'I.L.Palermo', colId: 'ILMusPalermo', longName: ' - Iscrizioni latine lapidarie del Museo di Palermo', zoteroKey: 'FZWWPUD6'}
       ]

        columns.forEach((column)=>{
            column.valueGetter = _getCorpusValue;
            column.filter = 'text';
            column.hide = true
        })

        return columns;       
    }

    function getCitedRange(biblEntry) {
      if (biblEntry && biblEntry.citedRange) {
          if (biblEntry.citedRange.ref && biblEntry.citedRange.ref['#text']) {
              return ` (${biblEntry.citedRange.ref['#text']})`
          } else {
              return ` (${biblEntry.citedRange})`
          }
      } else {
          return ''
      }
    }
  // creates a valueGetterFunction for use in the grid.
  // We do it this round-about way so we can make the selectedPubIdentifier available to the function
  function getBiblValueGetter(vm) {
    // called by the valueGetter for each grid row to get the string to show in the Selected Publications column
    // shows the bibl information for any publications selected in the publication dropdown
    return (params)=> {
        var selectedPubs = [...vm.pubsDropdown.selectedPubs];
        if (vm.publication) {
            // if we've gotten here then we are on the publication page
            // which means we've in a sense filtered the inscriptions down to this publication
            // so have to add it to our selectedPubs list
            // We use unshift to add it to the front of the array so it appears first in the Selected Publications.
            selectedPubs.unshift(vm.publication)
        }

        if (selectedPubs.length) {
            return $.map(selectedPubs, function (selectedPub) {
                let matchingBiblEntry = _.find(params.data.bibl, (biblItem) => {
                    return biblItem && biblItem.ptr && biblItem.ptr.target && biblItem.ptr.target.endsWith(selectedPub.key)
                })
                if (matchingBiblEntry) {
                    if (matchingBiblEntry.author) {
                        return `${matchingBiblEntry.author} ${matchingBiblEntry.date} ${getCitedRange(matchingBiblEntry)}`;
                    } else if (matchingBiblEntry.n) {
                        return `[${matchingBiblEntry.n}] ${selectedPub.meta.parsedDate} ${getCitedRange(matchingBiblEntry)}`
                    }
                }
            }).join('; ');
        }
        return ''
    }
  }


function getMuseumInventoryNumber(params) {
      if (params.data.msIdentifier && params.data.msIdentifier.idno && params.data.msIdentifier.idno['#text']) {
          return params.data.msIdentifier.idno['#text']
      } else {
          return ''
      }

}
  
  function getStandardColumnDefs(vm){
      return [
      {headerName: 'Id', field: 'isicily_id', width:110},
      {headerName: 'Selected Publications', colId: 'pubs', hide: true, valueGetter: getBiblValueGetter(vm), width:200, filter:'text', volatile:true},
     
      { headerName: 'Selected Corpora',
        children: getCorporaColumns()
        
      },
      {headerName: 'Title', field: 'title', width:155, filter:'text', hide:true},
      {headerName: 'Date', field: 'date', width:120, filter:'text'},
      {headerName: 'Date Range',
        children: [
          {
            headerName: 'After', 
            field: 'date_not_before', 
            width:75,
            filter:'number',
            cellRenderer: yearConverter
          },
          {
            headerName: 'Before', 
            field: 'date_not_after', 
            width:75, 
            filter:'number',
            cellRenderer: yearConverter
          }
        ]
      },
      { headerName: 'Place',
        children: [
          {headerName: 'Ancient', field: 'ancient_place_name', width:100, filter:'set',enableRowGroup:true},
          {headerName: 'Modern', field: 'modern_place_name', width:100, filter:'set',enableRowGroup:true},
        ]
      },
      {headerName: 'Material', field: 'materialForGrid', width:100, filter:'set',enableRowGroup:true},
      {headerName: 'Object Type', field: 'objectTypeForGrid', width:120, filter:'set',enableRowGroup:true},
      {headerName: 'Inscription Type', field: 'inscriptionTypeForGrid', width:140, filter:'set',enableRowGroup:true},
      {headerName: 'Execution Type', field: 'executionTypeForGrid', width:140, filter:'set',enableRowGroup:true },
      {headerName: 'Language', field: 'language', width:80, filter:'set',enableRowGroup:true},
      {headerName: 'Museum', colId: 'museum', field: 'museum', width:100, filter:'set',enableRowGroup:true},
          {headerName: 'Inventory Number', colId:'inventory', valueGetter:getMuseumInventoryNumber, filter: 'text', width:150, hide: true, volatile:true},
      {headerName: 'Status', field: 'status', width:100, filter:'set',enableRowGroup:true},
      {headerName: 'City of Origin (coords)', field: 'orig_place_lat_long', width:170, filter: 'number', hide: true},
      {headerName: 'Findspot (coords)', field: 'provenance_lat_long', width:170, filter: 'number', hide: true},
      {headerName: 'Commentary', field: 'commentary', width:200, filter:'text', hide: true},
      {headerName: 'Transcription', field: 'edition', width:200, filter:'text', tooltipField: 'edition', hide:true},
      {headerName: 'Translation', field: 'translation', width: 200, filter: 'text', hide:true},
      { headerName: 'Other Identifiers',
        children: 
          [
                {headerName: 'Trismegistos', field: 'tm_id', width:70, filter:'number'},
                {headerName: 'EDR', field: 'edr_id', width:70, filter:'number'},
                {headerName: 'PHI', field: 'phi_id', width:70, filter:'number'},
                {headerName: 'EDCS', field: 'edcs', width:70, filter:'number'}
          ]
      }
      
  ]

    }

  function getExternalFilterForRows(vm) {
    
    // we form a closure here to capture the 'vm' variable, which otherwise wouldn't be available to the function since the 
    // function is called directly by ag-grid and we can't control what arguments ag-grid passes.
        
    // called automagically by ag-grid for each row, to determine if the row meets the filter criteria and
    // should be included in the reuslt.  'node' contains the row data
        return function(node) {

          // first check mapMarker.  If there is a marker selected, and the inscription doesn't match it's latLong, we're done.
          if (vm.selectedMapMarker && node.data.mainMapLatLng !== vm.selectedMapMarker.isicilyLatLong) return false;

          // and if there are no selected pubs or corpora, then we can ignore that filter check.
          if (vm.allSelectedPubs.length < 1) return true;

          var biblList = node.data.bibl;
          if (biblList && $.isArray(biblList)) {
            let combinedBiblIdentifiers= $.map(biblList, function(biblEntry) { 
           /*   if (biblEntry && biblEntry.type) {
                // a corpus identifier, i.e,. the corpus abbreviation
                return biblEntry.type
              } else */

                  if (biblEntry && biblEntry.ptr && biblEntry.ptr.target) {
                // the identifier for the bibl entry, i.e., the zotero uri in the ptr target.
                return biblEntry.ptr.target
              }
            });
            
            if (vm.mustMatchAllSelectedPubs) {
              return _.every(vm.allSelectedPubs, (pubOrCorpusIdSelectedInFilter) =>
                 _.some(combinedBiblIdentifiers, (pubOrCorpusIDForInscription)=>
                    pubOrCorpusIDForInscription.endsWith(pubOrCorpusIdSelectedInFilter)))
            } else { 
              return _.some(vm.allSelectedPubs, (pubOrCorpusIdSelectedInFilter) =>
                 _.some(combinedBiblIdentifiers, (pubOrCorpusIDForInscription)=>
                    pubOrCorpusIDForInscription.endsWith(pubOrCorpusIdSelectedInFilter)))
            }
          } else {
            return false;
          }
        }
    }

    // adds columns to the grid for any selected corpora.  and shows the publication column if any 
    // publications have been selected in the filter
    function getPubFilter(vm) {
     return ()=>{
        let shouldShowPubColumn = vm.pubsDropdown.selectedPubs.length > 0 ;

        vm.gridOptions.columnApi.setColumnVisible('pubs', shouldShowPubColumn);

        getCorporaAbbreviations().forEach((corpusAbbreviation)=> {
          // has the corpus been selected?
          let shouldShowCorpusColumn =  vm.corporaDropdown.selectedCorpora.some((selectedCorpus)=>selectedCorpus.colId === corpusAbbreviation);
          // if yes, show the column for that corpora
          vm.gridOptions.columnApi.setColumnVisible(corpusAbbreviation, shouldShowCorpusColumn);
        });

         // populate allSelectedPubs with a list of corpus abbreviations( e.g., SEG) 
          // and zotero keys (e.g. R46KDTZX) for authored pubs.
          // They are those pubs and corpi that the user selected in the two filter dropdowns.
          vm.allSelectedPubs = []
          vm.pubsDropdown.selectedPubs.forEach((pub)=>vm.allSelectedPubs.push(pub.key));
         // vm.corporaDropdown.selectedCorpora.forEach((corpora)=>vm.allSelectedPubs.push(corpora.colId));
         vm.corporaDropdown.selectedCorpora.forEach((corpora)=>vm.allSelectedPubs.push(corpora.zoteroKey));
        refreshAllFilters(vm)
      }
    };

    function refreshAllFilters(vm) {
      vm.gridOptions.api.onFilterChanged();
      vm.gridOptions.api.softRefreshView();
    }

    function getFilterResetter(vm) {
      return ()=>{
        resetGrid(vm.gridOptions);
        
        vm.corporaDropdown.selectedCorpora = [];
        vm.pubsDropdown.selectedPubs = [];

        vm.mustMatchAllSelectedPubs = true;

        MapHelper.closeInfoWindow()
        vm.selectedMapMarker = null;

        vm.filterByPubs();
      }
    }

    function loadData(vm) {

      DataManager.getCounts().then((counts)=>vm.counts = counts)

      PublicationsManager.loadPublications().then((publications)=>{
        vm.publications = publications
        // the list that appears in the drop down for the publication filter.
        vm.pubFilters = $.map(publications, function(pub) { return {author: pub.meta.creatorSummary || '', date: pub.meta.parsedDate || '', title: pub.data.title || '', key: pub.key || ''}});
      });
    }


    function initializeInscriptionGridMap(mapId) {
      return new google.maps.Map(document.getElementById(mapId), {
            zoom: 8,
            center: {lat:37.625, lng:14.100},  // centre of sicily
            scrollwheel: false,
            mapTypeId: google.maps.MapTypeId.TERRAIN
          });
    }

    function filterByMapMarker(mapMarker, vm) {
      vm.selectedMapMarker = mapMarker;
      refreshAllFilters(vm)
    }

    function updateMap(map, gridModel, $scope, vm) {
        MapHelper.resetAllMarkers();

       // let filteredRows = gridModel.rowsAfterFilter?gridModel.rowsAfterFilter:gridModel.rowsToDisplay
        gridModel.forEachNodeAfterFilter((row)=>{MapHelper.addInscriptionToMarker(row.data)})
       // filteredRows.forEach((row)=>MapHelper.addInscriptionToMarker(row.data))
        // pass an anonymous function here as the 'click' callback, rather than addMapFilter directly,
        // so that we can capture vm in a closure.
        // the second callback is called when the info window for a map marker is closed, i.e., the marker is
        // deselected.
        MapHelper.showMarkers(map, (mapMarker)=>filterByMapMarker(mapMarker, vm), ()=>filterByMapMarker(null,vm))
    }

    function initializeStandardInscriptionGrid(vm, gridId, withMap, mapId, $scope) {
      if (withMap) {
        // use 'var' rather than 'let' to hoist 'map' up to function scope
        var map = initializeInscriptionGridMap(mapId)
      }
      vm.allSelectedPubs = []
      vm.selectedMapMarker = null;
      vm.showOptions = false;
      vm.toggleShowOptions = ()=>vm.showOptions = !vm.showOptions;
     
      vm.corporaDropdown = {};
      vm.corporaDropdown.selectedCorpora = [];

      vm.pubsDropdown = {};
      vm.pubsDropdown.selectedPubs = [];

      vm.mustMatchAllSelectedPubs = true;


      vm.resetFilters = getFilterResetter(vm)
      // this is called from the pub filters in the web page when a pub filter is selected.
      // it is also called from resetFilters 
      vm.filterByPubs = getPubFilter(vm)
      vm.corporaFilters = getCorporaColumns()
      vm.gridOptions = getBaseGridOptions();

     // vm.gridOptions.rowHeight=48;
      vm.gridOptions.columnDefs = getStandardColumnDefs(vm);
      
      vm.gridOptions.isExternalFilterPresent = ()=>{return vm.pubsDropdown.selectedPubs.length || vm.corporaDropdown.selectedCorpora.length || vm.selectedMapMarker}
      vm.gridOptions.doesExternalFilterPass = getExternalFilterForRows(vm);
      vm.gridOptions.onGridReady = ()=>vm.gridOptions.api.showLoadingOverlay();
      vm.gridOptions.onModelUpdated = ()=>{
        var gridModel = vm.gridOptions.api.getModel();
        if (vm.publication) vm.gridOptions.columnApi.setColumnVisible('pubs', true);
        vm.rowCount = getRowCount(gridModel);
        adjustGridHeight(gridModel, gridId);
        if (withMap) updateMap(map, gridModel, $scope, vm);  
      }

     vm.gridOptions.onRowClicked = (params)=>{
          //$state.go('inscription', {id: params.data.isicily_id})
         // var inscriptionURI = config.INSCRIPTION_URI + params.data.isicily_id
        //  if ($stateParams.lang == 'it') {inscriptionURI = inscriptionURI + '?lang=it'}
        //  $window.open(inscriptionURI)
        InscriptionModal.open(params.data)

      }

      loadData(vm);
      
    }

    return {
      
      resetGrid: resetGrid,
      getRowCount: getRowCount,
      adjustGridHeight: adjustGridHeight,
      yearConverter: yearConverter,
      getBaseGridOptions: getBaseGridOptions,
      addColumnStyling: addColumnStyling,
      initializeStandardInscriptionGrid: initializeStandardInscriptionGrid
    }

}

export default {
  name: 'GridHelper',
  fn: GridHelper
};




