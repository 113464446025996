"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

require("./addCSS.js");

var _rules = _interopRequireDefault(require("./rules.js"));

var _diplomaticRules = _interopRequireDefault(require("./diplomaticRules.js"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var parser = new DOMParser();

function normalizeText(text) {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
}

function isBreak(node) {
  return node.nodeType === Node.ELEMENT_NODE && ['lb', 'ab', 'cb', 'div'].includes(node.nodeName);
}

var convert = function convert(tei, openPopup, showInterpreted, overridingRules) {
  // openPopup takes two args:  title, body
  var fixedTEI = tei.replace(/[\r\n\t]/g, "");
  var showDiplomatic = !showInterpreted;

  if (showDiplomatic) {
    fixedTEI = normalizeText(fixedTEI);
  }

  var parent = document.createElement('div');
  parser.parseFromString(fixedTEI, "application/xml").querySelectorAll('div[type="edition"]').forEach(function (node) {
    return parent.appendChild(node);
  }); // parser.preserveWhitespace=true;

  var tw = document.createTreeWalker(parent); // choose interpreted or diplomatic rules

  var rulesToApply = _objectSpread(_objectSpread({}, showInterpreted ? _rules["default"] : _diplomaticRules["default"]), overridingRules);

  while (tw.nextNode()) {
    if (tw.currentNode.nodeType === Node.TEXT_NODE && showDiplomatic && !['note', 'desc', 'gap'].includes(tw.currentNode.parentNode.nodeName)) {
      tw.currentNode.nodeValue = tw.currentNode.nodeValue.toUpperCase();
    }

    var rule = rulesToApply[tw.currentNode.nodeName];
    if (rule) rule(tw.currentNode, tw, openPopup);
  } // second pass to remove adjacent brackets that should be elided
  // start by setting treewalker back to root


  tw.currentNode = parent;
  var nextBracketToMatch = null;
  var nodeWithLastBracketMatched = null;

  while (tw.nextNode()) {
    if (tw.currentNode.nodeType === Node.TEXT_NODE && tw.currentNode.nodeValue.trim()) {
      if (nextBracketToMatch) {
        if (tw.currentNode.nodeValue.trim().startsWith(nextBracketToMatch)) {
          // found two adjacent brackets , e.g., ][ or )( or ><
          // so remove both brackets
          nodeWithLastBracketMatched.nodeValue = nodeWithLastBracketMatched.nodeValue.trim().slice(0, -1);
          tw.currentNode.nodeValue = tw.currentNode.nodeValue.trim().slice(1);
        } else {
          // something else was in the text node besides the bracket we were looking for so
          // clear our matches
          nextBracketToMatch = null;
          nodeWithLastBracketMatched = null;
        }
      } else if (tw.currentNode.nodeValue.trim().endsWith(']')) {
        nextBracketToMatch = '[';
        nodeWithLastBracketMatched = tw.currentNode;
      } else if (tw.currentNode.nodeValue.trim().endsWith(')')) {
        nextBracketToMatch = '(';
        nodeWithLastBracketMatched = tw.currentNode;
      } else if (tw.currentNode.nodeValue.trim().endsWith('>')) {
        nextBracketToMatch = '<';
        nodeWithLastBracketMatched = tw.currentNode;
      }
    }
  }

  return parent;
};

var _default = convert;
exports["default"] = _default;