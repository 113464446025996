function PlaceLookupModalCtrl($mdDialog, searchString) {
'ngInject';
  const vm = this;
  vm.searchString = searchString;
  vm.pleiades_uri = null;
      
  vm.cancel = function() {
    $mdDialog.cancel();
  };

  vm.done = function() {
    var pleiadesRegEx = new RegExp('^http://pleiades\\.stoa\\.org/places/\\d+(/.+)?$', 'i');
      if (pleiadesRegEx.test(vm.pleiades_uri)) {
          $mdDialog.hide(vm.pleiades_uri);
      } else {
        vm.errorMessage = 'A Pleiades URI must be of the form http://pleiades.stoa.org/places/[uniquePlaceNumber] or http://pleiades.stoa.org/places/[uniquePlaceNumber]/[nameOfPlaceOrLocationOfPlace].' + 
            'The URI for Syracuse, for example, is http://pleiades.stoa.org/places/462503. ' + 
            'The URI is listed on the Pleiades page for a place as "Canonical URI for this page".';
      } 
  };

  vm.acknowledgeError = function () {
    vm.errorMessage = null;
  };
    
}

export default {
  name: 'PlaceLookupModalCtrl',
  fn: PlaceLookupModalCtrl
};