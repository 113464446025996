import $ from 'jquery';
function ScrollToElement() {

  return {
       restrict: 'A',
       link: ( $scope, element) => { 
          element.ready(() => {
           
                $('html, body').animate({scrollTop: $(element).offset().top - 10}, 1000);
          })
       }
    };
}

export default {
  name: 'scrollToElement',
  fn: ScrollToElement
};






