function PersonLookupModalCtrl($scope, $uibModalInstance, $timeout, Person, searchString) {
'ngInject';

      $scope.searchString = searchString;
      $scope.selectedPerson = null;
      $scope.newPersonCreated = false;
      $scope.searchResults = {};
      $scope.search = function () {
        $scope.selectedPerson = null;
         Person.find($scope.searchString).then(
            function(result) { $scope.searchResults = result.people } 
          )
      };
      if ($scope.searchString) {
        $scope.search();
      }      
      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };
       $scope.tag = function () {
        $uibModalInstance.close($scope.selectedPerson);
      };
      $scope.selected = function (person) {
        $scope.selectedPerson = person;
      };
      $scope.deselected = function () {
        $scope.selectedPerson = null;
      };
      $scope.new = function () {
        $scope.showPersonForm = true;
        $scope.personName = $scope.searchString;
        $scope.selectedPerson = null;
      };
      $scope.acknowledgeError = function () {
        $scope.errorMessage = null;
      };
      $scope.save = function () {
        Person.create(
          $scope.personName, 
          $scope.date_of_birth, 
          $scope.date_of_death, 
          $scope.date_of_birth_bce, 
          $scope.date_of_death_bce, 
          $scope.date_of_birth_precision, 
          $scope.date_of_death_precision, 
          $scope.notes).then(
            function(response) {
                $scope.newPersonCreated = true;
                $scope.selectedPerson = response.data;
                $scope.showPersonForm = false;
                $scope.searchResults = {};

            },
            function(errorResponse) {  
              if (errorResponse.status == 409) $scope.errorMessage = 'A record already exists for that name, birth year, and death year.';
            }
          )
      };
  }

export default {
  name: 'PersonLookupModalCtrl',
  fn: PersonLookupModalCtrl
};