function CommentsManager($http, config, store) {
'ngInject';

 function addComment(fieldName, inscriptionId, orcidId, submittedBy, commentText, email) {
    var comment = {
      fieldName: fieldName,
      inscriptionId: inscriptionId,
      orcidId: orcidId,
      submittedBy: submittedBy,
      commentText: commentText,
      email: email
    };
    return $http({
      url: config.COMMENTS_URI,
      method: 'POST',
      data: comment
    });
 }

 function updateStatus(commentId, status) {
    var uri = `${config.COMMENTS_URI}/${commentId}/${status}`;
    return $http({
      url: uri,
      method: 'PUT'
     // headers: {
      // 'X-Authorization': `Bearer ${store.get('jwt')}`
     // }
    });
 }

function getComments() {
  return $http({
        url: config.COMMENTS_URI,
        method: 'GET'
      }).then((result)=>{return result.data});
}

    return {
        getComments: getComments,
        addComment: addComment,
        updateStatus:  updateStatus
    }

}

export default {
  name: 'CommentsManager',
  fn: CommentsManager
};


