function MuseumListEditModalCtrl($mdDialog, GridHelper, museums, inscription) {
'ngInject';      
      
  const vm = this;
  vm.museums = {};
  vm.museums.selectedMainMuseum = {};
  vm.museums.selectedAlternateMuseums = [];
  vm.museums.availableMuseums = museums;

  vm.cancel = function() {
    $mdDialog.cancel();
  };

  vm.done = function() {
    var result = {
            name: selectedMainMuseum.name, 
            country: selectedMainMuseum.country, 
            settlement: selectedMainMuseum.town, 
            idno: selectedMainMuseum.inscription_identifier,
            uri: selectedMainMuseum.uri
    };
    if (vm.museums.selectedAlternateMuseums.length > 0) {
      // add alternate museums
      result.altIdentifiers = [];
      $.each(vm.museums.selectedAlternateMuseums, function(index, museum) {
          result.altIdentifiers.push( {
            name: museum.name, 
            country: museum.country, 
            settlement: museum.town, 
            idno: museum.inscription_identifier,
            uri: selectedMainMuseum.uri
          });
      })
    }
    $mdDialog.hide(result);
  }
 
}

export default {
  name: 'MuseumListEditModalCtrl',
  fn: MuseumListEditModalCtrl
};