"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var sharedRules = function sharedRules(isInterpreted) {
  return {
    'div': function div(node) {
      var type = node.getAttribute('type');
      var subtype = node.getAttribute('subtype');
      var n = node.getAttribute('n');

      if (type === 'textpart') {
        var title = document.createElement('span');
        title.className += ' section-heading';
        title.append("".concat(subtype, " ").concat(n));
        node.prepend(title);
      } else if (type === 'edition' && subtype === 'transliteration') {
        var _title = document.createElement('span');

        _title.className += ' section-heading';

        _title.append("Transliteration");

        node.prepend(_title);
      }
    },
    'ab': function ab(node) {
      var span = document.createElement('span');
      span.className += ' leiden-transcription';

      _toConsumableArray(node.childNodes).forEach(function (child) {
        return span.appendChild(child);
      });

      node.appendChild(span);
    },
    'milestone': function milestone(node) {
      var sup = document.createElement('sup');
      sup.textContent = "".concat(node.getAttribute('n'));
      node.append('|');
      node.append(sup);
    },
    'cb': function cb(node) {
      var title = document.createElement('span');
      title.className += ' section-heading';
      title.append("Col. ".concat(node.getAttribute('n')));
      node.prepend(title);
    },
    'lb': function lb(node) {
      var breakAttr = node.getAttribute('break');
      var n = node.getAttribute('n');
      var style = node.getAttribute('style');
      var textIndicator = ' ';

      if (style === "text-direction:r-to-l") {
        textIndicator = '←';
      } else if (style === "text-direction:l-to-r") {
        textIndicator = '→';
      } else if (style === "text-direction:spiral-clockwise") {
        textIndicator = '↻';
      } else if (style === "text-direction:circular-clockwise") {
        textIndicator = '↻';
      } else if (style === "text-direction:spiral-anticlockwise") {
        textIndicator = '↺';
      } else if (style === "text-direction:circular-anticlockwise") {
        textIndicator = '↺';
      } else if (style === "text-direction:upwards") {
        textIndicator = '↑';
      } else if (style === "text-direction:downwards") {
        textIndicator = '↓';
      }

      if (breakAttr === 'no' && isInterpreted) node.append('-');
      if (n !== 1) node.append(document.createElement('br'));
      var numSpan = document.createElement('span');
      numSpan.className += ' leiden-num-span';
      numSpan.append("".concat(n, ". ").concat(textIndicator));
      node.append(numSpan);
    },
    'space': function space(node) {
      var extent = node.getAttribute('extent');
      var unit = node.getAttribute('unit'); // character or line

      var isUncertain = node.getAttribute('cert') === 'low';
      var quantity = node.getAttribute('quantity');
      var textContent = '(';

      if (unit === 'line') {
        textContent += 'vacat';
      } else {
        if (quantity || extent === 'unknown' && isUncertain) {
          textContent += 'vac.';
          if (quantity > 1) textContent += quantity;
          if (isUncertain) textContent += '?';
        } else if (extent === 'unknown') {
          textContent += 'vacat';
        }
      }

      textContent += ')';
      node.textContent = textContent;
    },
    'gap': function gap(node) {
      var elementText;
      var reason = node.getAttribute('reason'); // 'lost' 'illegible' 'omitted'

      var extent = node.getAttribute('extent'); // always 'unknown' if present?  - never in combination with quantity or atLeast/atMost

      var quantity = node.getAttribute('quantity'); // not in combination with extent or atLeast/atMost

      var unit = node.getAttribute('unit'); // character, line, or some other unit like cm

      var atLeast = node.getAttribute('atLeast'); // not in combination with extent or quantity

      var atMost = node.getAttribute('atMost'); // not in combination with extent or quantity

      var precision = node.getAttribute('precision'); // 'low' output: ca. 

      var precisionOutput = precision && precision === 'low' ? 'ca.' : '';
      var isLine = unit && unit === 'line';
      var closingDelimiter = '';

      if (reason === 'lost') {
        if (isLine) {
          if (extent === 'unknown') {
            elementText = ' - - - - - ';
          } else {
            elementText = '  [- - - - - -';
            closingDelimiter = ']  ';
          }
        } else {
          // Dots are used only when exact number of characters is known.
          // Dashes otherwise.
          elementText = '[';

          if (extent === 'unknown') {
            elementText += '- - ? - -';
          } else if (atLeast || atMost) {
            elementText += " - ".concat(atLeast, "-").concat(atMost, " - ");
          } else if (quantity && quantity < 5) {
            elementText += '. '.repeat(quantity).trim();
          } else if (quantity && quantity >= 5) {
            if (precision === 'low' || unit !== 'character' && unit !== 'line') {
              // note that we display the unit if it isn't 'character' or 'line' because it is likely 'cm'
              elementText += "- - ".concat(precisionOutput).concat(quantity).concat(unit !== 'character' && unit !== 'line' ? unit : '', " - - ");
            } else {
              elementText += ". . ".concat(quantity, " . . ");
            }
          }

          closingDelimiter = ']';
        }
      } else if (reason === 'illegible') {
        var beforeText = isLine ? '(Traces of ' : '. . ';
        var afterText = isLine ? ' lines)' : ' . .';

        if (extent === 'unknown') {
          elementText = isLine ? "".concat(beforeText.trim()).concat(afterText) : "".concat(beforeText, "?").concat(afterText);
        } else if (atLeast || atMost) {
          elementText = "".concat(beforeText).concat(atLeast, "-").concat(atMost).concat(afterText);
        } else if (quantity && quantity < 5) {
          elementText = '. '.repeat(quantity).trim();
        } else if (quantity && quantity >= 5) {
          elementText = "".concat(beforeText).concat(precisionOutput).concat(quantity).concat(afterText);
        }
      } else if (reason === 'omitted') {
        elementText = '<- - ? - ';
        closingDelimiter = '->';
      }

      node.prepend(elementText);
      node.append(closingDelimiter);
    },
    'unclear': function unclear(node) {
      //&#x30A; is equivalent to U+030A and \u030A
      var combiningChar = isLatinSpecifiedInAncestor(node) ? "\u030A" : "\u0323";
      node.textContent = node.textContent.split('').map(function (character) {
        return character + combiningChar;
      }).join('').trim();
    }
  };
};

var isLatinSpecifiedInAncestor = function isLatinSpecifiedInAncestor(node) {
  if (!node) {
    return false;
  } else if (node.getAttribute('xml:lang') === 'xpu-Latn') {
    return true;
  } else {
    return isLatinSpecifiedInAncestor(node.parentNode);
  }
};

var _default = sharedRules;
exports["default"] = _default;