function OnRun($rootScope, config, $state, store, jwtHelper, $translate) {
  'ngInject';



  $rootScope.$on('$viewContentLoaded', function(event){ 
    var loadingDiv = document.getElementById("loading");
    if (loadingDiv) {
      loadingDiv.parentNode.removeChild(loadingDiv);
    }
  });


  $rootScope.$on('$stateChangeStart', function(e, to, toParams, from, fromParams) {
     // $rootScope.stateIsLoading = true;
      if (toParams.lang == 'it') {
        $translate.use(toParams.lang);
        $rootScope.lang = toParams.lang;
      };
     
      if (to.data && to.data.requiresRole) {
        if (!store.get('jwt') || jwtHelper.isTokenExpired(store.get('jwt'))) {
          e.preventDefault();
          var newParams = {nextState : to.name};
          for(let k in toParams) newParams[k]=toParams[k];
          $state.go('login', newParams);
        } else if (to.data.requiresRole.indexOf(jwtHelper.decodeToken(store.get('jwt')).role) < 0 ) {
          e.preventDefault();
          alert("You don't have the appropriate rights to view the " + to.name + " page.  Please login with a different account, or request the appropriate rights from the administrators.")
        }
      }
      if (to.name == 'logout') {
        e.preventDefault();
        delete $rootScope.username;
        delete $rootScope.role;
        store.remove('jwt');
        $state.go('inscriptions');
      }
  });


  // change page titles and username as state changes
  $rootScope.$on('$stateChangeSuccess', (event, toState) => {
   // $rootScope.stateIsLoading = false;
    $rootScope.pageTitle = '';
    if ( toState.title ) {
      $rootScope.pageTitle += toState.title;
      $rootScope.pageTitle += ' \u2014 ';
    }
    $rootScope.pageTitle += config.appTitle;
    if (store.get('jwt')) {
      $rootScope.username = jwtHelper.decodeToken(store.get('jwt')).first_name;
      $rootScope.role = jwtHelper.decodeToken(store.get('jwt')).role;
    } else {
      delete $rootScope.username;
      delete $rootScope.role;
    }
  });

}

export default OnRun;
