function MuseumCtrl($state, store, jwtHelper, ModalsManager, GridHelper, InscriptionsManager, museum) {
'ngInject';
  
  const vm = this;  
  vm.museum = museum;

  var sicilyCentre = {lat:37.500, lng:14.000};
  var map = new google.maps.Map(document.getElementById('museum-map'), {
          zoom: 8,
          center: sicilyCentre,
          scrollwheel: false
  });

  var useShortContent = true;
  var centerMapOnMuseum = true;
  museum.addToMap(map, useShortContent, centerMapOnMuseum);

  function loadInscriptions() {
    if (vm.museum.data.inscriptions && vm.museum.data.inscriptions.length > 0) {
      InscriptionsManager.getInscriptions(vm.museum.data.inscriptions).then(
        function(inscriptions) {vm.gridOptions.api.setRowData(inscriptions)});
    }
  }

  vm.admin = store.get('jwt')?jwtHelper.decodeToken(store.get('jwt')).role == 'ADMIN':false;
  
  vm.editMuseum = function(){
    $state.go('museum-edit', {id: vm.museum.data.id});
  }
 
 GridHelper.initializeStandardInscriptionGrid(vm, '#museum-grid', false)

 // hide museum column
  vm.gridOptions.onGridReady = ()=>{
    vm.gridOptions.api.showLoadingOverlay();
    vm.gridOptions.columnApi.setColumnVisible('museum', false);
  }

 loadInscriptions()

  vm.exportCSV = (ev)=>ModalsManager.openExport(vm.gridOptions.api, ev)

}

export default {
  name: 'MuseumCtrl',
  fn: MuseumCtrl
}


