import $ from 'jquery';

function InscriptionsCtrl($scope, $window, $stateParams, config, GridHelper, ModalsManager, InscriptionsManager ) {
'ngInject';
  // ViewModel
  const vm = this;
  vm.isLoading = true;
  
  GridHelper.initializeStandardInscriptionGrid(vm, '#main-grid', true, 'inscriptions-map',  $scope)

  InscriptionsManager.loadInscriptions().then((inscriptions)=>{
        const inscriptionsWithoutDeprecated = inscriptions.filter(inscription=>inscription.status!=='deprecated')
        vm.inscriptions = inscriptions;

        vm.gridOptions.api.setRowData(inscriptionsWithoutDeprecated);
        vm.isLoading = false;
      });

  vm.exportCSV = (ev)=>ModalsManager.openExport(vm.gridOptions.api, ev)
  vm.downloadEpiDoc = ()=>$window.open(config.ALL_EPIDOC)
}

export default {
  name: 'InscriptionsCtrl',
  fn: InscriptionsCtrl
};

