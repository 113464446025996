function TermEditModalCtrl($mdDialog, termName, inscription, terms) {
'ngInject';
  const vm = this;
  vm.termName = termName;
  vm.terms = {};
  vm.terms.selectedTerms = [];
  vm.terms.availableTerms = terms;

  vm.cancel = function() {
    $mdDialog.cancel();
  };
  vm.done = function() {
    console.log(vm.terms.selectedTerms);
    $mdDialog.hide(vm.terms.selectedTerms);
  };
}

export default {
  name: 'TermEditModalCtrl',
  fn: TermEditModalCtrl
};
