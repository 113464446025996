import $ from 'jquery';
import _ from 'lodash';
function TEIHelper($sce, config) {
'ngInject';

    var transcriptionXPath = "TEI text body div[type='edition'] ab";
    var apparatusXPath = "TEI text body div[type='apparatus'] ab";
    var translationXPath = "TEI text body div[type='translation'] p";
    var commentaryXPath = "TEI text body div[type='commentary'] p";
    var biblXPath = "TEI text body div[type='bibliography'] listBibl bibl author";
    var biblIdXPath = "TEI text body div[type='bibliography'] listBibl bibl[type]";

    var isiciclyIdXPath = "TEI teiHeader fileDesc publicationStmt idno[type='filename']";
    var titleXPath = "TEI teiHeader fileDesc titleStmt title";
    var museumNameXPath = "TEI teiHeader fileDesc sourceDesc msDesc msIdentifier repository";
    //var museumCountryXPath = "TEI teiHeader fileDesc sourceDesc msDesc msIdentifier country";
    //var museumSettlementXPath = "TEI teiHeader fileDesc sourceDesc msDesc msIdentifier settlement";

    // physical description section 
    var supportDescXPath = "TEI teiHeader fileDesc sourceDesc physDesc objectDesc supportDesc support p";
    var materialXPath = "TEI teiHeader fileDesc sourceDesc physDesc objectDesc supportDesc support material";
    var typeXPath = "TEI teiHeader fileDesc sourceDesc physDesc objectDesc supportDesc support objectType";
    var heightXPath = "TEI teiHeader fileDesc sourceDesc physDesc objectDesc supportDesc support dimensions height";
    var widthXPath = "TEI teiHeader fileDesc sourceDesc physDesc objectDesc supportDesc support dimensions width";
    var depthXPath = "TEI teiHeader fileDesc sourceDesc physDesc objectDesc supportDesc support dimensions depth";
    
    //epigraphic descripton section
    var layoutDescXPath = "TEI teiHeader fileDesc sourceDesc physDesc objectDesc layoutDesc layout";
    var letter_heightsXPath = "TEI teiHeader fileDesc sourceDesc physDesc handDesc handNote height";
    var letter_formsXPath = "TEI teiHeader fileDesc sourceDesc physDesc handDesc handNote";
    
    // provenance section
    var ancientPlaceXPath = "TEI teiHeader fileDesc sourceDesc msDesc history origin origPlace placeName[type='ancient']";
    var modernPlaceXPath = "TEI teiHeader fileDesc sourceDesc msDesc history origin placeName[type='modern']";
    var provenanceXPath = "TEI teiHeader fileDesc sourceDesc msDesc history provenance[type='found']";
    var lastRecordedXPath = "TEI teiHeader fileDesc sourceDesc msDesc history provenance[type='observed']";
    
    var languageXPath = "TEI teiHeader profileDesc langUsage language";
    var dateXPath = "TEI teiHeader fileDesc sourceDesc msDesc history origin origDate";
    var acquisitionXPath = "TEI teiHeader fileDesc sourceDesc msDesc history acquisition";
    
    function getDOMForXML() {
      var inscription = {};
      inscription.tei_xml = tei_response.data;
      inscription.tei_dom = $( $.parseXML( inscription.tei_xml ) );
      inscription.view.isicily_id = inscription.tei_dom.find(isiciclyIdXPath).text();
      inscription.view.letter_heights = inscription.tei_dom.find(letter_heightsXPath).attr('quantity');
      inscription.view.transcription.leiden = inscription.tei_dom.find( transcriptionXPath );
      inscription.view.transcription.xml = (new XMLSerializer()).serializeToString(inscription.tei_dom.find( transcriptionXPath )[0]);
      inscription.view.apparatus = inscription.tei_dom.find( apparatusXPath ).text();
      inscription.view.translation = inscription.tei_dom.find( translationXPath );
      inscription.view.commentary = inscription.tei_dom.find( commentaryXPath);
      inscription.view.biblIds = inscription.tei_dom.find( biblIdXPath).map(
        function(index, element) {
          var biblId = $( this );
          return {type: biblId.attr("type"), value: biblId.text()};
        }
      );

      inscription.view.bibls = inscription.tei_dom.find(biblXPath).parent().map(
        function(index, element) {
          var bibl = $( this );
          return {author: bibl.find("author").text(), date: bibl.find("date").text(), scope: bibl.find("biblScope").text()};
        }
      );
       

      if (inscription.view.translation[0]) {
        inscription.edit.translation = (new XMLSerializer()).serializeToString(inscription.view.translation[0]);
      }
    }
/*
    function saveDOMThings()  {

      var transcriptionElement = inscription.tei_dom.find(transcriptionXPath);
      transcriptionElement.replaceWith($(inscription.view.transcription.xml));

      var translationElement = inscription.tei_dom.find(translationXPath);
      translationElement.replaceWith($(inscription.edit.translation));

      var titleElement = inscription.tei_dom.find(titleXPath);
      titleElement.text(inscription.edit.metadata.title);
      
      var serializedDOM = (new XMLSerializer()).serializeToString(inscription.tei_dom[0]);
      var req = {
        method: 'PUT',
        url: inscription.teiDraftURI,
        headers: {
          'Content-Type': 'application/xml'
        },
        transformRequest: function(data) {
                      // we specify this tranformRequest to
                      // override the default $http tranform
                      // which converts everything to json.
                      // We instead want to send it as-is, as
                      // an xml string.
                        return data;
        },              
        data: serializedDOM
      };

      $http(req).then(
          function(response) { 
            return true;
          },
          function(errorResponse) {       
            return $q.reject(errorResponse);
          }
        );

    }
*/
    function addAnnotationMarkersForAnnotation(annotation) {
      // get the scope for compiling out new html snippet into the angular object model
      var scope = angular.element(transcriptionEditorElement).scope();
    
      // endNode is a DOM node, not an angular element, and not a jquery element
      $(endNode).attr('ng-click', 'annotationService.editAnnotationAtIndex(' + annotationIndex + ')');
      //$(endNode).attr('ng-mouseover', 'annotationService.highlightAnnotationAtIndex(' + annotationIndex + ')');

      $compile(endNode)(scope);
    }


    function hyperlinkPeople(parentElement) {
      var scope = angular.element(parentElement).scope();
      $(parentElement).find('persName').attr('ng-mouseover', 'annotationService.editAnnotationAtIndex(' + annotationIndex + ')');
      //$(endNode).attr('ng-mouseover', 'annotationService.highlightAnnotationAtIndex(' + annotationIndex + ')');
      $compile(parentElement)(scope);
    }

  

    function hyperlinkAll(xmlString) {
      
      if (!xmlString || xmlString.trim().length == 0) return null;

      
      // add a wrapping span to allow using jquery wrap if there is no parent
      // element around the placeName
      var teiDomFragment = $( $.parseXML( `<span>${xmlString}</span>` ) );
      
      return hyperlinkJQueryFragment(teiDomFragment)

      
     // teiDomFragment.find('persName name').attr( 'ng-click', 
      //  function() { return 'openPlaceWindow("' + $(this).attr('nymRef') + '")';}).wrap('<a></a>');
     // teiDomFragment.find('bibl').attr( 'ng-click', 
     //   function() { return 'openBiblWindow("' + $(this).attr('n') + '")';}).wrap('<a></a>');

    }

    function hyperlinkJQueryFragment(teiDomFragment) {
      // geo tags are interfering with display, so get rid of them
      teiDomFragment.find('geo').remove();

      // same with dimensions
      teiDomFragment.find('dimensions').remove();

      // same with objectType
      teiDomFragment.find('objectType').remove();

      // same with material
      teiDomFragment.find('material').remove();

      // same with execution type
      teiDomFragment.find('rs').remove();

      // if there's nothing left then return nothing
      if (_.isEmpty(teiDomFragment.text().trim())) return null;

      teiDomFragment.find("placeName, rs[type='execution'], material").each(function() {
        if ($(this).attr('ref')) {
          $(this).wrap('<a href="' + $(this).attr('ref') + '" target="_blank"></a>')
        }
      });

        teiDomFragment.find('ref').each(function() {
            if ($(this).attr('target')) {
                $(this).wrap('<a href="' + $(this).attr('target') + '" target="_blank"></a>')
            }
        });

      var newString = (new XMLSerializer()).serializeToString(teiDomFragment[0]);
      
      return $sce.trustAsHtml(newString);
    }


      function convertToLeiden(xmlString, isDiplomatic) {

          // add enclosing element because sometimes there are multiple edition divs without an enclosing parent
          var teiDomFragment = $( $.parseXML( `<span>${xmlString}</span>` ) );
            
          let editionElement = teiDomFragment.find('div[n][type="edition"]')
          if (editionElement) {
            editionElement.each(function(index){
              $( this ).prepend(`<div style="font-weight:bold">${index?'<br/><br/>':''}Edition: ${editionElement.attr('n')}</div>`)
            })
          }

          teiDomFragment.find('placeName').attr( 'ng-click', 
            function() { return 'openPlaceWindow("' + $(this).attr('key') + '")';}).wrap('<a></a>');
          teiDomFragment.find('persName name').attr( 'ng-click', 
            function() { return 'openPlaceWindow("' + $(this).attr('nymRef') + '")';}).wrap('<a></a>');
          teiDomFragment.find('bibl').attr( 'ng-click', 
            function() { return 'openPlaceWindow("' + $(this).attr('n') + '")';}).wrap('<a></a>');

            // popup the lemma if there.
          teiDomFragment.find('w').attr('popover-trigger', 'mouseenter').attr('popover', 
          function() { return 'Lemma: ' + $(this).attr('lemma');});

          if (isDiplomatic) {
            teiDomFragment.remove('supplied');
            teiDomFragment.remove('unclear');
            teiDomFragment.remove('gap');
              } else {

                var leidenRules = {
                  gap: {
                    lost:   function(element) { }, 
                    illegible: null
                  }, 
                  unclear: {}
                }
            teiDomFragment.find('supplied').before('[').after(']');
            //  before($('<span class='leiden_supplied_start'>').
            //  text('[')).after($('<span class='leiden_supplied_end'>').
            //  text(']'));

            // put a dot under each character within the unclear element
            teiDomFragment.find('unclear').each(
              function() {
                var unclearElement = $(this);
                unclearElement.text(
                  unclearElement.text().split('').map(function(character) { return character + '\u0323'}).join().trim()
                );
              });

            teiDomFragment.find('hi').each(
              function() {
                var hiElement = $(this);
                var rend = hiElement.attr('rend');
                if (rend == 'ligature') {
                  var oldText = hiElement.text();
                  hiElement.text(oldText.charAt(0) + '\u0302' + oldText.substring(1));
                }
              }
            )

            teiDomFragment.find('ex').before('(').after(')');

            teiDomFragment.find('gap').each(
              function() {
                var elementText;
                var gapElement = $(this);
                var reason = gapElement.attr('reason');
                var extent = gapElement.attr('extent');
                var unit = gapElement.attr('unit');
                if (reason == 'lost') {
                  elementText = '[';
                  if (extent == 'unknown') {
                    elementText += '---';
                  } else {
                    elementText += _.repeat('.', extent);
                  }
                  elementText += ']';
                } else if (reason == 'illegible') {
                  elementText = _.repeat('+', extent);
                }
                gapElement.text(elementText);
              }
            )

            teiDomFragment.find('g[ref="interpunct"]').text('·');

            teiDomFragment.find('space').each(
              function() {
                var spaceElement = $(this);
                var extent = spaceElement.attr('extent');
                spaceElement.text('(vac.' + extent + ')');
              })
          }


        teiDomFragment.find('note').attr('popover-trigger', 'mouseenter').attr('popover', 
          function() { return 'Note: ' + $(this).text();}).wrap( '<a></a>' ).text('');

        return teiDomFragment

      }

      const languageMap = {it: 'Italian', en: 'English'}

      function getTranslationDivs(translation) {
        return $( $.parseXML( `<span>${translation}</span>` ) ).find('div[type="translation"][xml\\:lang]')
      }

      function getLanguageString(transcriptionDivElement) {
        return languageMap[$(transcriptionDivElement).attr('xml:lang')] || $(transcriptionDivElement).attr('xml:lang')
      }

      function splitTranslations(translation) {
        if (!translation) return null;

         let result = getTranslationDivs(translation).map(function(){
            return {
              html:hyperlinkJQueryFragment($(this)),
              language: getLanguageString(this)
            }
          }).get()

         return result.length?result:null
      }

      function getJoinedTranslations(translation) {
        if (!translation) return null;

         let result = getTranslationDivs(translation).map(function(index){
            return `<div><div style="font-weight:bold">${getLanguageString(this)}</div> ${hyperlinkJQueryFragment($(this))}</div>`
          }).get().join('')

         return result.length?result:null
      }


    return {
      hyperlinkAll:hyperlinkAll,
      convertToLeiden: convertToLeiden,
      splitTranslations:splitTranslations,
      getJoinedTranslations:getJoinedTranslations
    }

}

export default {
  name: 'TEIHelper',
  fn: TEIHelper
};

