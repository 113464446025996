function MuseumPeriodEditModalCtrl($mdDialog, museum) {
'ngInject';
     
  const vm = this;
  vm.museum = museum;

  vm.cancel = function() {
    $mdDialog.cancel();
  };
  vm.done = function() {
    $mdDialog.hide(museum);
  };
}

export default {
	name: 'MuseumPeriodEditModalCtrl',
	fn: MuseumPeriodEditModalCtrl
}