import $ from 'jquery';
import _ from 'lodash';
function PublicationsCtrl($stateParams, ModalsManager, GridHelper, publications, config, $window) {
    'ngInject';
    const vm = this;
    
    vm.resetGrid = function() {
        GridHelper.resetGrid(vm.pubGridOptions);
        GridHelper.resetGrid(vm.authorGridOptions);
      //  var columnApi = vm.authorGridOptions.columnApi;
        // add the author pivot back-in to the author grid.
      //  columnApi.addPivotColumn(columnApi.getColumn('lastname'));
    }

    vm.isAuthorView = false;

    vm.groupByAuthor = function() {
        vm.isAuthorView = true;
    }
    vm.groupByTitle = function() {
        vm.isAuthorView = false;
    }

    function createAuthorDataFromPubData() {
        var authorRows = [];
        $.each(publications, function(i, pubRow) {
            $.each(pubRow.data.creators, function(i, author) {
                if (author.creatorType == 'author') {
                    var newRow = $.extend(true, {}, pubRow);
                    newRow.data.authorLastName = author.lastName;
                    newRow.data.authorFirstName = author.firstName;
                    newRow.data.authorType = author.creatorType;
                    authorRows.push(newRow);
                }
            })
        })
      //  var sortedAuthorRows = _.sortBy(authorRows, 'data.authorLastName');  
        vm.authorGridOptions.api.setRowData(authorRows);            
    }

    
   

     function authorsValueGetter(params) {
        if (params.data) {
            return $.map(

                params.data.data.creators, 
                function(creator) {
                    if (creator.creatorType == 'author' ) {
                        return  creator.lastName + ', ' + creator.firstName ;
                    }
                }).join('; ');
        }
    }



     var authorColumnDefs = [
        {headerName: 'Last Name', colId: 'lastname', field: 'lastname', valueGetter: 'data.data.authorLastName', width:120,  filter:'text', suppressSorting: true, rowGroupIndex: 0},
        {headerName: 'First Name', valueGetter: 'data.data.authorFirstName', width:70, filter:'text'},
      //  {headerName: 'Author Type', valueGetter: 'data.data.authorType', width:80, filter:'set'},
        
        {headerName: 'Title', valueGetter: 'data.data.title || data.data.publicationTitle', width:155, filter:'text'},
        {headerName: 'All Authors', valueGetter: authorsValueGetter, width:70, filter:'text'},
        {headerName: 'Pub Type', valueGetter: 'data.data.itemType', width:60, filter: 'set'},
        {headerName: 'Date', valueGetter: 'data.data.date', width:40, filter:'text'},
        {headerName: 'Zotero Record', valueGetter: 'data.links.alternate.href', width:70, suppressMenu: true, suppressSorting: true,
            cellRenderer: function(params) {
                return params.value?'<a href="'+params.value+'">'+params.value+'</a>':'';
            }
        } 
    ];

    var pubColumnDefs = [
        {headerName: 'Authors', valueGetter: authorsValueGetter, width:70, filter:'text'},
        {headerName: 'Date', valueGetter: 'data.data.date', width:40, filter:'text'},        
        {headerName: 'Title', valueGetter: 'data.data.title || data.data.publicationTitle', width:155, filter:'text'},
        {headerName: 'Type', valueGetter: 'data.data.itemType', width:30, filter: 'set'},
        {headerName: 'Zotero Record', valueGetter: 'data.links.alternate.href', width:70, suppressMenu: true, suppressSorting: true,
            cellRenderer: function(params) {
                return '<a href="'+params.value+'">'+params.value+'</a>';
            }
        },
        {headerName: 'AuthorLastName', valueGetter: 'data.meta.creatorSummary', width:40, filter:'text'}
        
    ];

  GridHelper.addColumnStyling(authorColumnDefs);
  GridHelper.addColumnStyling(pubColumnDefs);

  vm.pubGridOptions = GridHelper.getBaseGridOptions();
  vm.authorGridOptions = GridHelper.getBaseGridOptions();

  vm.pubGridOptions.columnDefs = pubColumnDefs;
  vm.authorGridOptions.columnDefs = authorColumnDefs;

  vm.pubGridOptions.onModelUpdated = pubModelUpdated;
  vm.authorGridOptions.onModelUpdated = authorModelUpdated;

  vm.pubGridOptions.onRowClicked = rowClicked;
  vm.authorGridOptions.onRowClicked = rowClicked;

  vm.pubGridOptions.onGridReady = function() {
    vm.pubGridOptions.api.sizeColumnsToFit();
    vm.pubGridOptions.api.showLoadingOverlay();
    vm.pubGridOptions.api.setRowData(publications);
  }
  vm.authorGridOptions.onGridReady = function() {
    vm.authorGridOptions.api.sizeColumnsToFit();
    vm.authorGridOptions.api.showLoadingOverlay();
    createAuthorDataFromPubData();
  }

    function authorModelUpdated() {
      var gridModel = vm.authorGridOptions.api.getModel();
      vm.authorRowCount = GridHelper.getRowCount(gridModel);
      GridHelper.adjustGridHeight(gridModel, '#author-grid');
  }

  function pubModelUpdated() {
      var gridModel = vm.pubGridOptions.api.getModel();
      vm.pubRowCount = GridHelper.getRowCount(gridModel);
      GridHelper.adjustGridHeight(gridModel, '#pub-grid');
  }

     function rowClicked(params) {
       // $state.go('publication', {id: params.data.key})
       var pubURI = config.PUBLICATION_URI + params.data.key;
        if ($stateParams.lang == 'it') {pubURI = pubURI + '?lang=it'};
        $window.open(pubURI);
    }


    vm.exportPubsAsCSV = function() {
        ModalsManager.openExport(vm.pubGridOptions.api)
    }

    vm.exportAuthorPubsAsCSV = function() {
        ModalsManager.openExport(vm.authorGridOptions.api)
    }
    

  }

export default {
    name: 'PublicationsCtrl',
    fn: PublicationsCtrl
}
