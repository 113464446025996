import $ from 'jquery';
import _ from 'lodash';
function MuseumsManager($http, $q, config, Museum, DataManager){
'ngInject';

    ///var museumsPromise = null;
    ///var loadAllPromise = null;
    var museums = [];
    var museumsByName = Object.create(null);
    var museumsById = Object.create(null);
    var museumsByURI = Object.create(null);

    var periodNames = {first_period: 'Classical',
      second_period: 'Early Middle Ages',
      third_period: 'High Middle Ages',
      fourth_period: 'Late Middle Ages',
      fifth_period: 'Spanish',
      sixth_period: 'Bourbon'
    }


    function get(id) { 
      // no id means this is new record so return empty promise.
      if (!id) { return $q.resolve( null )};
      
      return  loadMuseums().then(
        function(response) {
          return museumsById[id]
        },
        function(error) {console.log(error)}
      )  
    }

    function create(museum) {
      return $http({
        method: 'POST',
        url: config.MUSEUM_POST_URI,      
        data: museum.data
      }).then(
          function(response) { 
            museum.data = response.data;
            return museum;
          },
          function(errorResponse) { return $q.reject(errorResponse)}
      );
    }

    function save(museum) { 
      return museum.save();
    }

  function loadMuseums() {
    
      return DataManager.getMuseumData().then(
          function(museumsData) {    
            
            if (_.isEmpty(museums)) {
              $.each(museumsData, function(index, museumData){
                var museum = new Museum(museumData);
                museums.push(museum);
                // PROBABLY DON'T NEED TO CREATE THESE OBJECT MAPS, SINCE THERE IS 
                // NO REASON TO HAVE A FAST LOOKUP BY EITHER ID OR NAME.  THE NAME
                // LOOKUP WILL ALSO EVENTUALLY BE REPLACED WITH A URI LOOKUP.
                museumsById[museum.data.id] = museum;
                museumsByURI[museum.data.uri] = museum;
                museumsByName[museum.data.name.replace(/ /g,'')] = museum;
                
              });
             
            }
            return museums;
          },
          function(errorResponse) {  
            console.log('error in call to get museum list');
            console.log(errorResponse);  
          }
        )
  }


// TODO:  THIS WILL BE REPLACED WITH GETMUSEUMBYURI, ONCE THE MUSEUM URI IS ADDED TO THE EPIDOC FOR 
// THE INSCRIPTIONS.  RATHER THAN CREATE ANOTHER OBJECT MAP TO INDEX BY URI, THOUGH, I COULD USE THE
// GETMUSEUMBYPROPERTY METHOD THAT'S COMMENTED OUT BELOW, SINCE LOOKUP SPEED ISN'T IMPORTANT IN THIS CASE - 
// IT'S JUST A SINGLE LOOKUP WHENEVER WE ARE LOADING AN INSCRIPTION PAGE. 
    function getMuseumByName(museumName) {
      if (! museumName) return $q.reject( false );
      var strippedMuseumName = museumName.replace(/ /g,'');
      return loadMuseums().then(
        function(museums) {
          return museumsByName[strippedMuseumName];
        }
      );
    }

    function getMuseumByURI(museumURI) {
      if (! museumURI) return $q.reject( false );
      return loadMuseums().then(
        function(museums) {
          return museumsByURI[museumURI];
        }
      );
    }

  /*  function getMuseumByProperty(propertyName, propertyValue) {
      return loadMuseums().then(
          function(museums) {
              for (var i=0, museumsLength=museums.length; i<museumsLength; i++) {
                if (museums[i]['data'][propertyName] == propertyValue) {
                  return museums[i]
                } else {
                  return null
                }
              }
          },
          function(error) {console.log(error)}
      )
    } */
/*
    function addAllMuseumMarkersToMap(map,useShortContent) {
      loadMuseums().then(
          function(museums) {$.each(museums, function(index, museum) {museum.addToMap(map, useShortContent)})},
          function(error) {console.log(error)}
      )
    }
*/
/*
    function removeAllMuseumMarkersFromMap() {
      setMapForAll(null);
    }
    */

    function getPeriodName(periodId) {
       return periodNames[periodId];
    }



    return {
        get: get,
        create: create,
        save: save,
        loadMuseums: loadMuseums,
        getMuseumByName: getMuseumByName,
        getMuseumByURI: getMuseumByURI,
       // getMuseumByProperty: getMuseumByProperty,
       // addAllMuseumMarkersToMap: addAllMuseumMarkersToMap,
      //  removeAllMuseumMarkersFromMap: removeAllMuseumMarkersFromMap,
        getPeriodName: getPeriodName

    }

  }

export default {
  name: 'MuseumsManager',
  fn: MuseumsManager
}

