function DataManager($http, $q, config) {
  'ngInject';

  //const service = {};

  var dataPromise = null;
  var data = null;

    function getInscriptionData() {   
      return  loadData().then(
        (response)=>data.inscriptions,
        (error)=>console.log(error)
      )  
    }

    function getMuseumData() { 
      return  loadData().then(
        (response)=>data.museums,
        (error)=>console.log(error)
      )  
    }

    function getPublicationData() { 
      return  loadData().then(
        (response)=>data.pubs,
        (error)=>console.log(error)
      )  
    }

    function loadData() {
      var dataURL = config.DATA_URI;
       if (dataPromise == null) {
        dataPromise = $http({
          url: dataURL,
          method: 'GET'
        }).then(
     //   dataPromise = $http.get(dataURL).then(
          (response)=> {  
            data = response.data;
            return data;
          },
          (errorResponse)=> {  
            console.log('error in call to get data');
            console.log(errorResponse);  
          }
        )
      }
      return dataPromise;
    }

    function refreshCaches(type) {
      return $http.get(`${config.REFRESH_CACHE_URL}?refresh=${type}`).then(
          function(response) {    
            data = response.data;
            return data;
            
          },
          function(errorResponse) {  
            console.log('error in call to get data');
            console.log(errorResponse);  
          }
        )
    }

    function getEditableInscriptionData(id) {
      var getURL = `${config.EDITABLE_INSCRIPTION_BASE_URI}${id}`;
      return $http.get(getURL).then(
          function(response){return response.data},
          function(error) {console.log(error)}
      )
    }

    function getCounts() {
      return $http.get(config.COUNTS_URI).then(
          function(response) {    
            data = response.data;
            return data;
            
          },
          function(errorResponse) {  
            console.log('error in call to get counts');
            console.log(errorResponse);  
          }
        )
    }

    function submitInscription(xmlString) {
      var req = {
        method: 'POST',
        url: config.INSCRIPTION_SUBMIT_BASE_URI,
        headers: {
          'Content-Type': 'application/xml'
        },
        transformRequest: function(data) {
                      // we specify this empty tranformRequest to
                      // override the default $http transform
                      // which converts everything to json.
                      // We instead want to send the data as-is, as
                      // an xml string.
                        return data;
        },              
        data: xmlString
      };

      return $http(req).then(
          function(response) { 
          //  console.log('response in DataManager.submitInscription')
          //  console.log(response)
            return true;
          },
          function(errorResponse) {    
            console.log('error in DataManager.submitInscription')
            console.log(errorResponse)   
            return $q.reject(errorResponse);
          }
        );
    }

    return {
        getMuseumData: getMuseumData,
        getPublicationData: getPublicationData,
        getInscriptionData: getInscriptionData, 
        refreshCaches: refreshCaches,
        getEditableInscriptionData: getEditableInscriptionData,
        submitInscription: submitInscription,
        getCounts: getCounts
    }

  //return service;

}

export default {
  name: 'DataManager',
  fn: DataManager
};


