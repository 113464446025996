function Inscription(){
'ngInject';
     // constructor for Inscription objects
    function Inscription(inscriptionData) {
      if (inscriptionData) {
        angular.extend(this, inscriptionData);
      }

      if (this.orig_place_lat_long ) {
        this.mainMapLatLng = this.orig_place_lat_long.replace(/\s/g, "");
      }


    }

    Inscription.prototype = {
      getEdition: getEdition,
      addToMainMap: addToMainMap,
      removeFromMainMap: removeFromMainMap,
      addToFindspotMap: addToFindspotMap,
      showInfoWindow: showInfoWindow,
      hideInfoWindow: hideInfoWindow
    }

    var markerIcon = {
      url: 'images/monument.png',
      size: new google.maps.Size(17, 17),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(2, 5),
      scaledSize: new google.maps.Size(17, 17)
    }

    var infoWindow = new google.maps.InfoWindow({
      content: '',
      centreBeforeInfoWindowClick:null,
      mapShown: null
    })

    infoWindow.addListener('closeclick', function() {
      this.mapShown.panTo(this.centreBeforeInfoWindowClick);
    })

    function getEdition() {
        if (! this.edition) {
          //$http.get('inscription/' + this.id + '/draft/transcription').then(function(transcription){this.edition=transription});
        } 
        return this.edition
    }

    function removeFromMainMap(map) {
      if (this.mainMapMarker) {
        this.mainMapMarker.setMap(null);
      }
    }


    function addToMainMap(map) {
      if (this.orig_place_lat_long) {
        var latLongSplit = this.orig_place_lat_long.split(',');
      } else {
        return
      }
      if (! this.mainMapMarker) { 
        this.mainMapMarkerContent = 
        `<div style="max-height:70px;overflow: scroll;">
          <div style="margin-bottom:.1em;font-weight:700">
            <a href="/#/inscription/${this.isicily_id}">${this.title}</a> - ${this.date}
          </div>
          <div>${this.ancient_place_name}</div>
          <div>${this.inscription_type} (${this.object_type})</div>
          <div>${this.language}</div>
        </div>`;
        
        this.mainMapLatLng = new google.maps.LatLng(parseFloat(latLongSplit[0]), parseFloat(latLongSplit[1]));
        this.mainMapMarker = new google.maps.Marker(this.mainMapLatLng);  
        this.mainMapMarker.setPosition(this.mainMapLatLng);
        this.mainMapMarker.setIcon(markerIcon);
      }
      var thisInscription = this;
      this.mainMapMarker.addListener('click', function() {
            thisInscription.showInfoWindow(map, thisInscription.mainMapMarkerContent, thisInscription.mainMapMarker);
        });
      this.mainMapMarker.setMap(map);
     
    }

    function addToFindspotMap(map) {
      var showCircle = false;
        if (this.provenance_lat_long) {
            var coords = this.provenance_lat_long;
        } else if (this.orig_place_lat_long) {
            var coords = this.orig_place_lat_long;
            showCircle = true;
        } else {
            return;
        }
        
        if (this.findspotMapMarker == null) { 
          this.findspotMarkerContent = '<div style="max-height:70px;overflow: scroll;">'+this.provenance+'</div>';
          var latLongSplit = coords.split(',');
          this.findspotLatLng = new google.maps.LatLng(parseFloat(latLongSplit[0]), parseFloat(latLongSplit[1]));
          this.findspotMapMarker = new google.maps.Marker(this.findspotLatLng);  
          this.findspotMapMarker.setPosition(this.findspotLatLng);
         // this.mapMarker.setIcon(markerIcon);
          var thisInscription = this;
          this.findspotMapMarker.addListener('click', function() {
              thisInscription.showInfoWindow(map, thisInscription.findspotMarkerContent, thisInscription.findspotMapMarker);
          });
        }

        this.findspotMapMarker.setMap(map);
        map.setCenter(this.findspotLatLng);
        map.setZoom(14);

        if (showCircle) {
         // this.showInfoWindow(map, this.findspotMarkerContent, this.findspotMapMarker);
          var cityCircle = new google.maps.Circle({
            strokeColor: '#FF0000',
            strokeOpacity: 0.1,
            strokeWeight: 0,
            fillColor: '#FF0000',
            fillOpacity: 0.1,
            map: map,
            center: this.findspotLatLng,
            radius: 800
          });
        }
        
    }

      function showInfoWindow(map, markerContent, marker) {
          infoWindow.centreBeforeInfoWindowClick = map.getCenter();
          infoWindow.mapShown = map;
          infoWindow.setContent(markerContent);
          infoWindow.open(map, marker);
       
      }

      function hideInfoWindow() {
        if (infoWindow) {
          infoWindow.close();
        }
      }

 
    return Inscription;

  }

export default {
  name: 'Inscription',
  fn: Inscription
};


