const translations = {
  	translations_en : {
	  INSCRIPTIONS: 'Inscriptions',
	  SEARCH: 'Search',
	  MUSEUMS_LABEL: 'Museums/Sites',
	  BIBLIOGRAPHY: 'Bibliography',
	  TITLE: 'A digital corpus of Sicilian inscriptions',
	  ABOUT: 'About',
	  LOGIN: 'Login/Register',
	  LOGOUT: '{{username}} - Logout',
	  ADMIN: 'Admin',
	  INSCRIPTION: {
	  	STATUS: 'Status',
	  	NO_IMAGES: 'No images available',
	  	IMAGE_SHARE_NOTE: 'If you have images to share, click here to send us a note.',
	  	EDITIONS: 'Editions',
		  DISCUSSIONS: 'Discussions',
	  	NO_BIBLIOGRAPHY: 'No Bibliography',
		  NO_DISCUSSION: 'No Discussions',
	  	DIGITAL_EDITIONS_AND_IDS: 'Digital editions and identifiers',
	  	LOCATION: 'Location',
	  	PROVENANCE: 'Provenance',
	  	ORIGINAL_LOCATION: 'Original Location',
	  	LAST_RECORDED_LOCATION: 'Last Recorded Location',
	  	AUTOPSY: 'Autopsy',
	  	PHYSICAL_DESCRIPTION: 'Physical Description',
	  	MATERIAL: 'Material',
	  	OBJECT_TYPE: 'Object Type',
	  	DIMENSIONS: 'Dimensions',
	  	HEIGHT: 'H',
	  	WIDTH: 'W',
	  	DEPTH: 'D',
	  	EPIGRAPHIC_DESCRIPTION: 'Epigraphic Description',
	  	EPIGRAPHIC_TYPE: 'Epigraphic Type',
	  	EXECUTION_TYPE: 'Execution Type',
	  	LETTER_HEIGHTS: 'Letter Heights',
	  	INTERLINEAR_HEIGHTS: 'Interlinear Heights',
	  	LETTER_FORMS: 'Letter Forms',
	  	DATE: 'Date',
	  	TEXT: 'Text',
	  	INTERPRETED: 'INTERPRETED',
	  	DIPLOMATIC: 'DIPLOMATIC',
	  	EPIDOC: 'EPIDOC',
	  	APPARATUS: 'Apparatus',
	  	NO_APPARATUS: 'No Apparatus',
	  	TRANSLATION: 'Translation',
	  	COMMENTARY: 'Commentary',
	  	EDITED_BY: 'Edited By',
	  	TECHNICAL_DEVELOPMENT: 'Technical Development',
	  	CONTRIBUTIONS: 'Contributions',
	  	LAST_REVISION: 'Last revision',
	  	SOURCE: 'XML Source',
		  HOW_TO_CITE: 'How to cite'
	  },
	  MUSEUMS: {
	    FILTER: {
	      SHOW_MUSEUMS: 'Include Museums',
	      SHOW_SITES: 'Include Archeological Sites'
	    }
	  },
	  MAIN: {
	    FILTER: { 
	      MESSAGE: 'Match:',
	      ANY: 'Match Any Corpus or Pub Filter',
	      ALL: 'Match All Corpus and Pub Filters',
	      BY_CORPORA: 'Filter by corpora: ',
	      BY_PUB: 'Filter by publications: ',
	      DROPDOWN: {
	        selectAll       : 'Select All',
	        selectNone      : 'Select None',
	        reset           : 'Reset',
	        search          : 'Search...',
	        nothingSelected : 'None selected'     
	      }
	    }
	  }
	},
	translations_it : {
	  INSCRIPTIONS: 'Iscrizioni',
	  SEARCH: 'Ricerca',
	  MUSEUMS_LABEL: 'Musei/Siti',
	  BIBLIOGRAPHY: 'Bibliografia',
	  TITLE: 'Un corpus digitale di iscrizioni siciliani',
	  ABOUT: 'Il progetto',
	  LOGIN: 'Accesso',
	  LOGOUT: '{{username}} - Disconnettersi',
	  ADMIN: 'Gestione',
	  INSCRIPTION: {
	  	STATUS: 'Stato',
	  	NO_IMAGES: 'Immagini non disponibili',
	  	IMAGE_SHARE_NOTE: 'If you have images to share, click here to send us a note.',
	  	EDITIONS: 'Edizioni',
	  	NO_BIBLIOGRAPHY: 'No Bibliography',
	  	DIGITAL_EDITIONS_AND_IDS: 'Edizioni digitali e identificatori',
	  	LOCATION: 'Ubicazione',
	  	PROVENANCE: 'Provenienza',
	  	ORIGINAL_LOCATION: 'Ubicazione Originaria',
	  	LAST_RECORDED_LOCATION: 'Ultima Ubicazione Registrata',
	  	AUTOPSY: 'Autopsia',
	  	PHYSICAL_DESCRIPTION: 'Descrizione Fisica',
	  	MATERIAL: 'Materiale',
	  	OBJECT_TYPE: 'Tipologia Oggetto',
	  	DIMENSIONS: 'Dimensioni',
	  	HEIGHT: 'h',
	  	WIDTH: 'lungh.',
	  	DEPTH: 'largh',
	  	EPIGRAPHIC_DESCRIPTION: 'Descrizione Epigrafica',
	  	EPIGRAPHIC_TYPE: 'Tipologia epigrafica',
	  	EXECUTION_TYPE: 'Tipologia esecutiva',
	  	LETTER_HEIGHTS: 'Altezza lettere',
	  	INTERLINEAR_HEIGHTS: 'Altezza interlinea',
	  	LETTER_FORMS: 'Forma lettere',
	  	DATE: 'Datazione',
	  	TEXT: 'Testo',
	  	INTERPRETED: 'Interpretativo',
	  	DIPLOMATIC: 'Diplomatico',
	  	EPIDOC: 'Epidoc',
	  	APPARATUS: 'Apparato',
	  	NO_APPARATUS: 'Nessuno',
	  	TRANSLATION: 'Traduzione',
	  	COMMENTARY: 'Note',
	  	EDITED_BY: 'A cura di',
	  	TECHNICAL_DEVELOPMENT: 'Realizzazione Tecnica',
	  	CONTRIBUTIONS: 'Contributi di',
	  	LAST_REVISION: 'Ultima revisione'
	  },
	  MUSEUMS: {
	    FILTER: {
	      SHOW_MUSEUMS: 'Include Museums',
	      SHOW_SITES: 'Include Archeological Sites'
	    }
	  },
	  MAIN: {
	    FILTER: {
	      MESSAGE: 'Partita:',
	      ANY: 'Una',
	      ALL: 'Tutti',
	      BY_CORPORA: 'Filtra per corpora: ',
	      BY_PUB: 'Filtra per pubblicazioni: ',
	      DROPDOWN: {
	        selectAll       : 'Seleziona tutto',
	        selectNone      : 'Non selezionare niente',
	        reset           : 'Undo all',
	        search          : 'Ricerca...',
	        nothingSelected : 'Nessuno selezionato'     
	      }
	    }
	  }
	}
};

export default translations;


