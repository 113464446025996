function MapHelper(TEIHelper){
'ngInject';
    
    //private variable hidden in closure
    const inscriptionMarkersByLatLong = Object.create(null);
    //const inscriptionMarkersByURI = new Map()


    const markerIcon = {
      url: 'images/information.png',
      size: new google.maps.Size(17, 17),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(2, 5),
      scaledSize: new google.maps.Size(17, 17)
    }

    const biggerMarkerIcon = {
      url: 'images/information.png',
      size: new google.maps.Size(20, 20),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(2, 5),
      scaledSize: new google.maps.Size(20, 20)
    }

    const evenBiggerMarkerIcon = {
      url: 'images/information.png',
      size: new google.maps.Size(25, 25),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(2, 5),
      scaledSize: new google.maps.Size(25, 25)
    }

    const biggestMarkerIcon = {
      url: 'images/information.png',
      size: new google.maps.Size(30, 30),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(2, 5),
      scaledSize: new google.maps.Size(30, 30)
    }

    let infoWindow = new google.maps.InfoWindow({
      content: '',
      centreBeforeInfoWindowClick:null,
      mapShown: null
    })

    infoWindow.addListener('closeclick', function() {
      this.mapShown.panTo(this.centreBeforeInfoWindowClick);
    })

    function fitMarkers(map, markers) {  	
		var bounds = new google.maps.LatLngBounds();
		for(var i=0;i<markers.length;i++) {
		   bounds.extend(markers[i].getPosition());
		}
		//center the map to the geometric center of all markers
		map.setCenter(bounds.getCenter());
		map.fitBounds(bounds);
		//remove one zoom level to ensure no marker is on the edge.
		map.setZoom(map.getZoom()-2); 
	}



	function addInscriptionToMarker(inscription){

		if (!inscription.mainMapLatLng) return;
	
		var latLong = inscription.mainMapLatLng;

		// first create the marker for this latLong, if it doesn't exist


		if (!inscriptionMarkersByLatLong[latLong]) {
			var latLongSplit = latLong.split(',');
			var googleLatLong = new google.maps.LatLng(parseFloat(latLongSplit[0]), parseFloat(latLongSplit[1]));
			var marker = new google.maps.Marker(googleLatLong);
			marker.inscriptions = [];
			marker.setPosition(googleLatLong);
			marker.setIcon(markerIcon);
			marker.isicilyLatLong = latLong;
			inscriptionMarkersByLatLong[latLong] = marker;
		}
		 
		// now add the inscription
		inscriptionMarkersByLatLong[latLong].inscriptions.push(inscription);

   	}


	function showMarkers(map, clickCallback, infoWindowCloseCallback) {
		
		Object.keys(inscriptionMarkersByLatLong).forEach(function (latLong) {
  			let marker = inscriptionMarkersByLatLong[latLong];
  			if (marker.inscriptions.length > 0) {	
  				marker.setMap(map);
  				marker.addListener('click', function() {
			      infoWindow.centreBeforeInfoWindowClick = map.getCenter();
		          infoWindow.mapShown = map;
		          infoWindow.setContent(getContent(marker));
		          infoWindow.open(map, marker);
		          infoWindow.addListener('closeclick', function() {
				      this.mapShown.panTo(this.centreBeforeInfoWindowClick);
				      if (infoWindowCloseCallback) infoWindowCloseCallback();
				    })
		          if (clickCallback) clickCallback(marker)
				});
				if (marker.inscriptions.length < 3) {
					marker.setIcon(markerIcon);
				} else if (marker.inscriptions.length < 10) {
					marker.setIcon(biggerMarkerIcon);
				} else if (marker.inscriptions.length < 20) {
					marker.setIcon(evenBiggerMarkerIcon);
				} else {
					marker.setIcon(biggestMarkerIcon);
				} 
  			} 

		});
	}

	function getContent(marker) {
		var inscriptions = marker.inscriptions;
		// look for an ancient place name in any of the inscriptions for the place
		var placeHTML;
		var inscriptionWithAncientName = inscriptions.find((inscription)=>inscription.ancient_place_name)
		if (inscriptionWithAncientName) {
			placeHTML = inscriptionWithAncientName.ancient_place_name_for_display
		} else {
			var inscriptionWithModernName = inscriptions.find((inscription)=>inscription.modern_place_name)
			if (inscriptionWithModernName) {
				placeHTML = inscriptionWithModernName.modern_place_name_for_display;
			} else {
				placeHTML = 'place name unknown'
			}
		}
		
		var ancientPlaceForTitle = TEIHelper.hyperlinkAll(placeHTML);
    	//var modernPlaceForTitle = TEIHelper.hyperlinkAll(inscriptions[0].modern_place_name_for_display);

		var contentString = `<div>	
			<span style="font-weight:bold;font-size:1.7em">${ancientPlaceForTitle} </span> ${marker.getPosition().toString()}
      		<div><u>Inscriptions</u>: </div>
            <div style="max-height:70px;overflow: scroll;"">`;
        contentString += inscriptions.map((insc) => '<a href="inscription/'+insc.isicily_id+'">'+insc.isicily_id+'</a>').join(', ');
        contentString += '</div>';
 
      contentString += '</div>';

     // $compile(contentString)(scope);
     return contentString;

	}

	function getMarker(latLong) {
		return inscriptionMarkersByLatLong[latLong]
	}

	function resetAllMarkers() {
		Object.keys(inscriptionMarkersByLatLong).forEach(function (latLong) {
			let marker = inscriptionMarkersByLatLong[latLong]
			marker.setMap(null);
			marker.inscriptions = [];
		})
	}

	function closeInfoWindow() {
		infoWindow.close()
	}

    return {
      	fitMarkers: fitMarkers,
      	addInscriptionToMarker: addInscriptionToMarker,
      	showMarkers: showMarkers,
      	resetAllMarkers: resetAllMarkers,
      	closeInfoWindow: closeInfoWindow,
		getMarker: getMarker
    }

}

export default {
	name: 'MapHelper',
	fn: MapHelper
};
    

