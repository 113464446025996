function WelcomeCtrl(store, $state) {
    'ngInject';
    // ViewModel
    const vm = this;
    const storeKey = 'hideisicilysplash';

    console.log('the store key:')
    console.log(store.get(storeKey))
    if (store.get(storeKey)) {
        $state.go('inscriptions');
    }

    vm.updateShow = function() {
        if (vm.dontshow) {
            store.set(storeKey, true)
            console.log('should have set the storekey')
        } else {
            store.remove(storeKey)
        }
    }

}

export default {
    name: 'WelcomeCtrl',
    fn: WelcomeCtrl
};

