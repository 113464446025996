var baseURI = `http://sicily.classics.ox.ac.uk/`;
//var baseURI = `http://localhost/`;
var servicesBaseURI = `${baseURI}services/`;
const AppSettings = {
    BASE_URI: baseURI,
    INSCRIPTION_URI: `${baseURI}inscription/`,
    PUBLICATION_URI: `${baseURI}publication/`,
    MUSEUM_URI: `${baseURI}museum/`,
    SERVICES_BASE_URI: servicesBaseURI,
    INSCRIPTION_SUBMIT_BASE_URI: `${servicesBaseURI}inscription`,
    PERSON_URI: `person`,
    COMMENTS_URI: `${servicesBaseURI}comments`,
    USERS_URI: `${servicesBaseURI}users`,
    USER_URI: `${servicesBaseURI}user`,
    MUSEUM_POST_URI: `${servicesBaseURI}museum`,
    MUSEUM_PUT_URI: `${servicesBaseURI}museum`,
    DATA_URI: `${servicesBaseURI}alldata`,
    ALL_EPIDOC: `${servicesBaseURI}inscriptions/epidoc`,
    COUNTS_URI: `${servicesBaseURI}counts`,
    IIP_URL: `${baseURI}deepzoom/images/`,
    //IIP_URL: `${baseURI}iipsrv/iipsrv.fcgi?DeepZoom=/images/`,
    REFRESH_CACHE_URL: `${servicesBaseURI}refresh`,
    LOGIN_URL: `${servicesBaseURI}login`,
    REGISTER_URL: `${servicesBaseURI}register`,
    INSCRIPTION_TYPES_URI: `${servicesBaseURI}inscription-types`,
    OBJECT_TYPES_URI: `${servicesBaseURI}object-types`,
    EXECUTION_TYPES_URI: `${servicesBaseURI}execution-types`,
    MATERIALS_URI: `${servicesBaseURI}materials`,
    INSCRIPTION_TYPE_URI: `${servicesBaseURI}inscription-type`,
    OBJECT_TYPE_URI: `${servicesBaseURI}object-type`,
    EXECUTION_TYPE_URI: `${servicesBaseURI}execution-type`,
    MATERIAL_URI: `${servicesBaseURI}material`,
    appTitle: `I.Sicily`
};
 


export default AppSettings;
