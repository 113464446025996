import $ from "jquery";
import {convert} from "@isicily/epidoc-viewer-core";

if (typeof window !== 'undefined' && !window.$) {
    window.jQuery = window.$ = require('jquery');
} 
require('bootstrap');
function InscriptionModal($compile, config, $window, $stateParams, TEIHelper) {
'ngInject';



    let handleOpenPopup = (textToShow) => {
       // $('#leiden-content-modal').html(textToShow)
       // $('#leidenModal').modal('show')
      //  $('#leiden-popover').popover({title: textToShow, placement: 'top'})
      //  $('#leiden-popover').popover('show')
       // alert(textToShow)
        $('#leiden-popover').html('EXPLANATION: ' + textToShow)
    };


    function open(inscription) {
    
      //  let interpretedLeiden = (new XMLSerializer()).serializeToString(TEIHelper.convertToLeiden(inscription.edition, false)[0])
       // let diplomaticLeiden = (new XMLSerializer()).serializeToString(TEIHelper.convertToLeiden(inscription.edition, true)[0])
        const interpretedLeiden = convert(inscription.edition, handleOpenPopup, true)
        let diplomaticLeiden = convert(inscription.edition, handleOpenPopup, false)
        let joinedTranslations = TEIHelper.getJoinedTranslations(inscription.translation)
        $('#title-modal').html(inscription.title)
        $('#interpreted-modal').html(interpretedLeiden)
        $('#diplomatic-modal').html(diplomaticLeiden)
        $('#commentary-modal').html(inscription.commentary)
        $('#translation-modal').html(joinedTranslations)
        $('#leiden-popover').html('')

       /* let parentElement = angular.element($('#inscriptionModal'))
        let scope = parentElement.scope()
        
        $compile(parentElement.contents())(scope)*/
        
        $('#open-inscription-btn').click(function(event){
                $('#inscriptionModal').modal('hide');
                var inscriptionURI = config.INSCRIPTION_URI + inscription.isicily_id
                if ($stateParams.lang == 'it') {inscriptionURI = inscriptionURI + '?lang=it'}

                $window.open(inscriptionURI)
            });

        $('#inscriptionModal').modal('show').
            on('shown.bs.modal', function () {
                $(".modal").css('display', 'block')
        }).on('hidden.bs.modal', function() {
            //$(this).remove()
            $('#open-inscription-btn').off('click')
        });

       
    }


    return {
      open:open
    }

}

export default {
  name: 'InscriptionModal',
  fn: InscriptionModal
};


