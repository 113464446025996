import vkbeautify from 'vkbeautify';
function EpidocTranscription($compile) {
'ngInject';	
	    return function(scope, element, attrs) { 

	    	var refreshEpiDocView = function() {
	    			
	    			//var teiDomFragment = scope.$eval(attrs.teihtml);
	    			var xmlString = scope.$eval(attrs.edition);
	    			//var xmlString = (new XMLSerializer()).serializeToString(teiDomFragment[0]);
	    			if (xmlString) {
		    			var xml_formatted = vkbeautify.xml(xmlString)
		    			var xml_escaped = xml_formatted.replace(/&/g,'&amp;').replace(/</g,'&lt;').replace(/>/g,'&gt;').replace(/ /g, '&nbsp;').replace(/\n/g,'<br />');

		              	element.html(xml_escaped);

		            	$compile(element.contents())(scope);
		            }
	         }
		    scope.$watch(attrs.edition, refreshEpiDocView);
	    }
}
export default {
	name: 'epidocTranscription',
	fn: EpidocTranscription
};