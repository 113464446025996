import $ from 'jquery';
import 'openseadragon';

function OpenSeadragonHelper(config, $timeout){
'ngInject';
    
    var viewer = null;
    var imagePath = config.IIP_URL;


    function openseadragon(images, inscriptionId){ 
      if (viewer != null) {
          viewer.destroy();
          viewer = null;
        }

      var imagePaths;
      var imageCaptions;

      if (images && images.graphic) {
        imagePaths = [].concat(images.graphic).filter(graphic=>graphic.n=='screen').map(graphic=>`${imagePath}${inscriptionId}/${graphic.url}.dzi`)
        imageCaptions = [].concat(images.graphic).filter(graphic=>graphic.n=='screen').map(graphic=>{return graphic.desc || ''})
      } else {
        imagePaths = [`${imagePath}placeholder_tiled.tif.dzi`]
      } 

        viewer = OpenSeadragon({
            id: 'openseadragon',
            prefixUrl: '/images/openseadragon/',
            tileSources: imagePaths,
            showNavigator:  true,
            navigationControlAnchor: 'BOTTOM_LEFT',
            sequenceMode: true,
            showReferenceStrip: true,
            showSequenceControl: true,
            sequenceControlAnchor: 'TOP_LEFT',
            referenceStripScroll: 'horizontal',
            autoHideControls: false,
            defaultZoomLevel: 0,
            minZoomLevel: 0,
            showRotationControl: true
           // debugMode:  true
        })

         
        var tileLoadCounter = 0;
        $timeout(()=>viewer.viewport.goHome(true), 4000);

       viewer.addHandler("tile-loaded", function (event) {
          if (tileLoadCounter < 1) {
            tileLoadCounter ++;
            var height = event.image.naturalHeight;
            var width = event.image.naturalWidth;
            var ratio = height/width;
            var elementWidth = $(viewer.element).width();
            var newHeight = elementWidth * ratio;
            $(viewer.element).height(newHeight);

          } 
        });

        viewer.addHandler('page', function(event) {
            if (imageCaptions && imageCaptions.length) $('#imageCaption').text(imageCaptions[event.page]);
        });

        if (imageCaptions && imageCaptions.length) $('#imageCaption').text(imageCaptions[0]);

        return true;
    };

    return {
      openseadragon: openseadragon
    };

}

export default {
  name: 'OpenSeadragonHelper',
  fn: OpenSeadragonHelper
};
