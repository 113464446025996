function ExportModalCtrl($mdDialog, gridAPI) {
'ngInject';
     
  const exportModalVM = this;

  exportModalVM.cancel = function() {
    $mdDialog.cancel();
  };
  exportModalVM.export = function() {
    var params = {
            skipHeader: exportModalVM.skipHeader === true,
            skipFooters: exportModalVM.skipFooters === true,
            skipGroups: exportModalVM.skipGroups === true,
            fileName: exportModalVM.fileName
        };

    gridAPI.exportDataAsCsv(params);
    $mdDialog.hide();
  };

}

export default {
  name: 'ExportModalCtrl',
  fn: ExportModalCtrl
}