import $ from 'jquery';
import _ from 'lodash';
function InscriptionsManager($http, $q, config, Inscription, DataManager, EditableInscription) {
'ngInject';
   
    // cache the promise to avoid repeated calls
    //var inscriptionsPromise = null;
    // store inscriptions by id for faster lookup
    var inscriptionsById = Object.create(null);
    var inscriptions = [];
    var deprecatedInscriptions = []
    
    function loadInscriptions() {
      return DataManager.getInscriptionData().then(
          function(inscriptionsData) { 
            if (_.isEmpty(inscriptions)) {
              $.each(inscriptionsData, function(index, inscriptionData){
                var inscription = new Inscription(inscriptionData);
                inscriptions.push(inscription);
                inscriptionsById[inscription.isicily_id] = inscription;
                if (inscriptionData.status === 'deprecated') {
                    deprecatedInscriptions.push(inscription)
                }
              });
            }
            return inscriptions;
          },
          function(errorResponse) {  
            console.log('error in call to get inscription data');
            console.log(errorResponse);  
          }
        )
      }

      function getInscriptionCount(withoutDeprecated) {
        if (withoutDeprecated) {
            return inscriptions.length - deprecatedInscriptions.length
        }
        return inscriptions.length;
      }

   
    function getInscription(id) {
      // note, the loadInscriptions() promise, along with it's result, is cached, so the
      // loadInscriptions call below 
      // shouldn't trigger a new call to the server if we've already loaded the inscriptions
      return loadInscriptions().then(
        function(inscriptions) {
          var inscription = inscriptionsById[id];
          if (inscription) {
            return inscription;
          } else {
            return null;
          }
        },
        function(error) {}
      );
    }

    function getInscriptions(inscriptionIds) {
      var arrayOfInscriptionPromises = $.map(inscriptionIds, function(inscription) {
        return getInscription(inscription.isicily_id)
      });
      return $q.all(arrayOfInscriptionPromises);
    }

    function updateDate(inscriptions, dateText, notBefore, notAfter) {
      return updateInscriptionElement(inscriptions, 'date', {dateText: dateText, notBefore: notBefore, notAFter: notAfter});
    }

    function updateCommentary(inscriptions, commentaryText) {
      return updateInscriptionElement(inscriptions, 'commentary', {commentary: commentaryText});
    }

    function updateType(inscriptions, types, typeName, updateName) {
        var commaSepTerms = $.map(types, function(type, index) {return type.term}).join(', ');
      // set the  type for the 'viewable' version of the inscriptions 
      $.each(inscriptions, function(index, inscription) {
        inscription[typeName] = commaSepTerms;
      });

      var dataForPost = {terms: types};
      return updateInscriptionElement(inscriptions, updateName, dataForPost);
    }

    function updateInscriptionElement(inscriptions, fieldName, newValues) {  
      var docIds = inscriptions.map(function(inscription){return inscription.file_name}).join(',');
      var dataForPost = {docIds: docIds, newValues: newValues};
      var uri = config.INSCRIPTIONS_UPDATE_URI + fieldName;
      return $http({
          url: uri,
          method: 'POST',           
          data: dataForPost
        }).then(
          function(response) {
            return response.data;
          }, function(error) {
            alert(error);
            console.log(error);
            return $q.reject(error);
        })
    };
    
    function updateObjectType(inscriptions, objectTypes) {
      return updateType(inscriptions, objectTypes, 'object_type', 'object-type');
    }

    function updateInscriptionType(inscriptions, inscriptionTypes) {
      return updateType(inscriptions, inscriptionTypes, 'inscription_type', 'inscription-type');
    }

    function updateMuseumList(inscription, museums) {
          // update the museum xml in the stored epidoc
       return updateInscriptionElement(inscription, 'museum', museums);
    }

    function updateMaterial(inscriptions, materials) {
      return updateType(inscriptions, materials, 'material', 'material');
    }

    function updateExecutionType(inscriptions, executionTypes) {
      return updateType(inscriptions, executionTypes, 'execution_type', 'execution-type');
    }

    function updateEdition(inscription, edition) {
      inscription.edition = edition;
      return updateInscriptionElement([inscription], 'edition', edition);
    }

    //1.  try the xml2js with the option set to maintain child order.
    //2.  add in XML parsing, so as to pull out the transcription, and translation.
    //3.  also instead try just using the js version, and pulling out the transcription and translation
    // as js, then converting them to xml using the xml builder.
    

    function getEditableInscription(id) {

      return DataManager.getEditableInscriptionData(id).then(
        function(xmlString){
          var newEditableInscription = new EditableInscription(id, xmlString);
          return newEditableInscription;
        }
      )
    }

    function addAllFindSpotsToMap(map,useShortContent) {
      loadInscriptions().then(
          function(inscriptions) {$.each(inscriptions, function(index, inscription) {inscription.addToMainMap(map)})},
          function(error) {console.log(error)}
      )
    }



  return {
        loadInscriptions: loadInscriptions,
        getInscription: getInscription,
        getInscriptions: getInscriptions,
        updateMuseumList: updateMuseumList,
        updateInscriptionType: updateInscriptionType,
        updateObjectType: updateObjectType,
        updateExecutionType: updateExecutionType,
        updateMaterial: updateMaterial,
        updateDate: updateDate,
        updateCommentary: updateCommentary,
        updateEdition: updateEdition,
        getEditableInscription: getEditableInscription,
        addAllFindSpotsToMap:addAllFindSpotsToMap,
        getInscriptionCount: getInscriptionCount
    }

}

export default {
  name: 'InscriptionsManager',
  fn: InscriptionsManager
};

