function CommentModalCtrl($mdDialog, $timeout, fieldName, inscriptionId, CommentsManager) {
'ngInject';
     
  // the view model for access in the view
  const commentModalVM = this;
  commentModalVM.cancelButtonText = "Cancel";
  commentModalVM.inscriptionId = inscriptionId;
  commentModalVM.fieldName = fieldName;

  commentModalVM.cancel = function() {
    $mdDialog.cancel();
  };
  commentModalVM.submit = function() {

   
    CommentsManager.addComment(fieldName, inscriptionId, commentModalVM.orcidId, commentModalVM.userName, commentModalVM.commentText, commentModalVM.email).then(
      function(success) {
          $mdDialog.show(
            $mdDialog.alert()
              .clickOutsideToClose(true)
              .title(`Thank you.  Your comment has been saved.`)
            //  .htmlContent(commentHTML)
              .ariaLabel('Comment')
              .ok('OK')
          );
          $mdDialog.hide;
          
      },
      function(failure) {
          $mdDialog.show(
            $mdDialog.alert()
              .clickOutsideToClose(true)
              .title(`Sorry, your comment couldn't been saved.  You may copy and save your comment below to try again later.`)
              .textContent(commentModalVM.commentText)
              .ariaLabel('Comment')
              .ok('OK')
          );
          $mdDialog.hide;
      }
    );
    
  };

}

export default {
  name: 'CommentModalCtrl',
  fn: CommentModalCtrl
}