function Museum($http, InscriptionsManager, $q, config, $mdDialog){
'ngInject';
    // constructor for Museum objects
    function Museum(museumData) {
      if (museumData) {
       // angular.extend(this, museumData);
       this.data = museumData;
      } else {
        this.data = {};
      }
    }

    Museum.prototype = {
      addToMap: addToMap,
      removeFromMap: removeFromMap,
      showInfoWindow: showInfoWindow,
      hideInfoWindow: hideInfoWindow,
      getContentForInfoWindow: getContentForInfoWindow,
      getShortContentForInfoWindow: getShortContentForInfoWindow,
      save: save
    }

    var museumMarkerIcon = {
      url: 'https://maps.gstatic.com/mapfiles/place_api/icons/museum-71.png',
      size: new google.maps.Size(17, 17),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(2, 5),
      scaledSize: new google.maps.Size(17, 17)
    }

    var siteMarkerIcon = {
      url: 'images/monument.png',
      size: new google.maps.Size(17, 17),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(2, 5),
      scaledSize: new google.maps.Size(17, 17)
    }

    var infoWindow = new google.maps.InfoWindow({
      content: '',
      centreBeforeInfoWindowClick:null,
      mapShown: null
    })

    infoWindow.addListener('closeclick', function() {
      this.mapShown.panTo(this.centreBeforeInfoWindowClick);
    })

    function removeFromMap(map) {
      if (this.mapMarker) {
        this.mapMarker.setMap(null);
      }
    }

    function addToMap(map, showShortContent, centerOnMuseum) {
      
      if (!this.data.latitude || !this.data.longitude) return;
      
      this.map = map;
      this.markerContent = showShortContent?this.getShortContentForInfoWindow():this.getContentForInfoWindow();
      if (this.mapMarker == null) { 
        this.latLng = new google.maps.LatLng(parseFloat(this.data.latitude), parseFloat(this.data.longitude));
        this.mapMarker = new google.maps.Marker(this.LatLng);  
        this.mapMarker.setPosition(this.latLng);
        this.data.site_type == 'MUSEUM' ? this.mapMarker.setIcon(museumMarkerIcon): this.mapMarker.setIcon(siteMarkerIcon);
        var thisMuseum = this;
        this.mapMarker.addListener('click', function() {
            thisMuseum.showInfoWindow();
        });
      }

      this.mapMarker.setMap(map);

      if (centerOnMuseum) {
        map.setCenter(this.latLng); 
        map.setZoom(10)
      };

      // return a handle to the marker to allow setting map bound around all visible markers.
      return this.mapMarker;
    }

    function showInfoWindow() {
      infoWindow.centreBeforeInfoWindowClick = this.map.getCenter();
      infoWindow.mapShown = this.map;
      infoWindow.setContent(this.markerContent);
      infoWindow.open(this.map, this.mapMarker);
    }

    function hideInfoWindow() {
      infoWindow.close();
    }
 

    function getContentForInfoWindow() {
      var contentString = getSharedContentForInfoWindow(this);
     // if (this.contact_details) contentString +=   '<div>'+ this.contact_details +'</div>';
      //if (this.collection_description) contentString += '<p>'+ this.collection_description +'</p>' ;
      //if (this.size) contentString += '<p>Size: '+ this.size +'</p>';
      //if (this.inscriptions_on_display) contentString +='<p>Inscriptions on Display: '+ this.inscriptions_on_display +'</p>';
      
      contentString +='<a href="/museum/' +this.data.id+ '">I.Sicily Page for Museum</a><div/>';
      if (this.data.google_place_url) {
        contentString += '<a href="' + this.data.google_place_url + '">Google Page for Museum</a><div/>'
      }
      
      if (this.data.inscriptions) {
        contentString += '<u>Inscriptions in Collection</u>: <br/>';
        contentString += '<div style="max-height:70px;overflow: scroll;"">' + this.data.inscriptions.map(function(insc){return '<a href="/inscription/'+insc.isicily_id+'"">'+insc.isicily_id+'</a>'}).join(', ');
        contentString += '</div>';
      };
      contentString += '</div>';
      return contentString;
    }

    function getShortContentForInfoWindow() {
      var contentString = getSharedContentForInfoWindow(this);
      contentString += '<div>' + this.data.latitude + ', ' + this.data.longitude + '</div>'
      if (this.data.google_place_url) contentString += '<div><a href="' + this.data.google_place_url +'">Google Page</a></div>'
      contentString += '</div>';
      return contentString;
    }

    function showPopup(title, message) {
      $mdDialog.show(
            $mdDialog.alert()
              .clickOutsideToClose(false)
              .title(title)
              .textContent(message)
              .ariaLabel(title)
              .ok('OK')
      );
    }


    function save() {
     
      var uri = `${config.MUSEUM_PUT_URI}/${this.data.id}`;
      return $http({
        method: 'PUT',
        url: uri,      
        data: this.data,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(
          function(response) { return response.data},
          function(errorResponse) { 
            showPopup('Error.', 'There was a problem saving.  Try again, or report the error: ' + errorResponse.data);
            return $q.reject(errorResponse)
          }
      );
    }

    function getSharedContentForInfoWindow(museum) {
      var contentString = '<div>' +
          '<div style="margin-bottom:.1em;font-weight:700">' + museum.data.name + '</div>';
      if (museum.data.location_description) contentString +='<div>'+ museum.data.location_description +'</div>';
      
      return contentString;
    }

    return Museum;

  }

export default {
  name: 'Museum',
  fn: Museum
};
