import $ from 'jquery';

function PublicationCtrl(PublicationsManager, ModalsManager, GridHelper, InscriptionsManager, publication, $state) {
'ngInject';   
  const vm = this;
  vm.publication = publication;

  
 
 vm.authors = [];
 vm.editors = [];
  vm.authors = $.map(publication.data.creators, 
    function(creator, index) {
      if (creator.creatorType == 'author') {
        return creator.lastName + ', ' + creator.firstName;
      }
    }
  ).join('; ');

function populateInscriptionGrid() {
    if (vm.publication.inscriptions && vm.publication.inscriptions.length > 0) {
      InscriptionsManager.getInscriptions(vm.publication.inscriptions).then(
      function(inscriptions) {
        vm.gridOptions.api.setRowData(inscriptions);
        //modelUpdated();
      });
        
    }
  }

 GridHelper.initializeStandardInscriptionGrid(vm, '#single-pub-grid', false)

 vm.exportCSV = (ev)=>ModalsManager.openExport(vm.gridOptions.api, ev)

 populateInscriptionGrid()

}

export default {
  name: 'PublicationCtrl',
  fn: PublicationCtrl
};