import $ from 'jquery';
function MuseumEditCtrl($scope, $compile, $state, $mdDialog, MuseumsManager, store, jwtHelper, museum) {
'ngInject';
  
  const vm = this;  

  var sicilyCentre = {lat:37.500, lng:14.000};
  var map = new google.maps.Map(document.getElementById('museum-edit-map'), {
        zoom: 8,
        center: sicilyCentre,
        scrollwheel: false
  });

  var museumMarker = new google.maps.Marker({map:map, visible:false});
  var museumInfoWindow = new google.maps.InfoWindow();
   
  vm.admin = store.get('jwt')?jwtHelper.decodeToken(store.get('jwt')).role == 'ADMIN':false;

  if (museum) {
    vm.museum = museum;
    showMuseumMarker();
    vm.isNewRecord = false;
  } else {
    vm.museum = {};
    vm.isNewRecord = true;
  }
    

  function showMuseumMarker() {

    google.maps.event.clearListeners(museumMarker, 'click');

    if (vm.museum.data.google_place_id) {
      (new google.maps.places.PlacesService(map)).getDetails({
        placeId: vm.museum.data.google_place_id
      }, function(place, status) {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          var content = '<div><strong>' + 
            place.name + 
            '</strong><br/>' + 
            place.adr_address + 
            '</br>' + 
            place.formatted_phone_number + 
            '</br><button class="btn btn-primary btn-xs" ng-show="museum.google_place_id == \'' + 
            place.place_id + 
            '\'" ng-click="removeGooglePlace()">Remove this Google place.</button>' +  
            '</div>';
          showGooglePlaceMarker(place, museumMarker, content, museumInfoWindow);
          google.maps.event.addListener(museumMarker, 'click', function() {
            //museumInfoWindow.setContent();
            museumInfoWindow.open(map, this);
          });
        }
      });
    } else if (vm.museum.data.latitude && vm.museum.data.longitude ) {
      museumMarker.setPosition(new google.maps.LatLng(parseFloat(vm.museum.data.latitude), parseFloat(vm.museum.data.longitude)));
      museumMarker.setMap(map);
      museumMarker.setVisible(true);
      map.panTo(museumMarker.getPosition());
      google.maps.event.addListener(museumMarker, 'click', function() {
            museumInfoWindow.setContent(vm.museum.data.latitude + ',' + vm.museum.data.longitude);
            museumInfoWindow.open(map, this);
          });
    } else {
      museumMarker.setVisible(false);
      museumInfoWindow.close();
    }
  }

  vm.submit = function () {
    scrollToTop();
    if ($scope.museumform.$valid) {
      if (vm.isNewRecord) {
        createNewRecord();
      } else {
        saveRecord();
      }
    } else {
      $mdDialog.show(
            $mdDialog.alert()
              .clickOutsideToClose(false)
              .title('Missing fields.')
              .textContent("You must provide values for the highlighted fields.")
              .ariaLabel('Missing fields.')
              .ok('OK')
      );
    }
  }

  function createNewRecord() {

    MuseumsManager.create(vm.museum).then(
          function(newRecord) {
             $mdDialog.show(
              $mdDialog.confirm()
                .clickOutsideToClose(false)
                .title('Saved.')
                .textContent("Your record was saved.")
                .ariaLabel('Saved')
                .ok('Ok')
                
            ).finally(
                  function(){console.log("what the");$state.go('museum', {id: newRecord.id})}
                );
           // $state.go('museum', {id: newRecord.id});
              
          },
          function(error) {
            console.log(error);
            showErrorDialog();
          }
        );    
  }

  function showErrorDialog() {
      $mdDialog.show(
        $mdDialog.alert()
              .clickOutsideToClose(false)
              .title('Hmmmm.')
              .textContent("Your record wasn't saved.  Please try again or contact the adminstrator.")
              .ariaLabel('Error')
              .ok('OK')
      );
  }

  function saveRecord(){

    MuseumsManager.save(vm.museum).then(
          function(newRecord) {
            console.log(newRecord);
            $mdDialog.show(
              $mdDialog.confirm()
                .clickOutsideToClose(false)
                .title('Saved.')
                .textContent("Your record was saved.  Click below to edit the next sequential record.")
                .ariaLabel('Saved')
                .ok('Edit Next Record')
                .cancel('Done')
              ).then(
                  function(){$state.go('museum-edit', {id: newRecord.id+1})},
                  function(){$state.go('museum', {id: newRecord.id})}
                );
              
          },
          function(error) {
            showErrorDialog();
          }

           
        );    
  }

  vm.clear = function () {
      vm.museum = {};
      scrollToTop();
  };

  function scrollToTop() {
    $('html, body').animate({scrollTop: $("#museum-edit-top").offset().top - 10}, 500);
  }
 
  map.addListener('click', function(e) {
    var isAdmin = store.get('jwt')?jwtHelper.decodeToken(store.get('jwt')).role == 'ADMIN':false;
    if (isAdmin) {
      $mdDialog.show(
        $mdDialog.confirm()
          .clickOutsideToClose(false)
          .title('Change museum latitude and longitude.')
          .textContent("You've clicked a position on the map, which can be used as the new latitude and longitude.  If you accept the change, you'll still have to Submit the main form.  Use these coordinates?")
          .ariaLabel('Museum coordinates')
          .ok('Yes, use new coordinates.')
          .cancel('No, I mistakenly clicked the map.')
          
      ).then(
            function(){
              vm.museum.data.latitude = e.latLng.lat();
              vm.museum.data.longitude = e.latLng.lng();
              vm.museum.latlng = e.LatLng;
              showMuseumMarker()
            }
          );  
    }
  });
    
  var input = (document.getElementById('pac-input'));

  map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

  var autocomplete = new google.maps.places.Autocomplete(input);
  autocomplete.bindTo('bounds', map);

  var infowindow = new google.maps.InfoWindow();
  var marker = new google.maps.Marker({
    map: map,
    anchorPoint: new google.maps.Point(0, -29)
  });

  autocomplete.addListener('place_changed', function() {
    infowindow.close();
    marker.setVisible(false);
    var place = autocomplete.getPlace();
    vm.possibleGooglePlace = place;
    if (!place.geometry) {
      window.alert("No latitude and longitude for the chosen place.  Make sure to actually choose a place from the results.");
      return;
    }
    var content = '<div><strong>' + 
      place.name + 
      '</strong><br/>' + 
      place.adr_address;
      if (place.formatted_phone_number) {
        content += '</br>' +  place.formatted_phone_number;
      }
      content += '</br><button class="btn btn-primary btn-xs" ng-hide="museum.google_place_id == \'' + 
      place.place_id + 
      '\'" ng-click="setGooglePlace()">Use this Google place.</button>' + 
      '</div>';
    showGooglePlaceMarker(place, marker, content, infowindow);
    
  });


  vm.setGooglePlace = function() {
    vm.museum.data.latitude = vm.possibleGooglePlace.geometry.location.lat();
    vm.museum.data.longitude = vm.possibleGooglePlace.geometry.location.lng();
    vm.museum.data.google_place_id = vm.possibleGooglePlace.place_id;
    vm.museum.data.google_place_url = vm.possibleGooglePlace.url;
    infowindow.close();
    showMuseumMarker();
  }
    
   vm.removeGooglePlace = function() {
    vm.museum.data.latitude = null;
    vm.museum.data.data.longitude = null;
    vm.museum.data.google_place_id = null;
    vm.museum.data.google_place_url = null;
    infowindow.close();
    showMuseumMarker();
  }
 
  function showGooglePlaceMarker(place, marker, content, infowindow) {
    // If the place has a geometry, then present it on a map.
    
    if (place.geometry.viewport) {
      map.fitBounds(place.geometry.viewport);
    } else {
      map.setCenter(place.geometry.location);
      map.setZoom(10);  
    }
    marker.setIcon(/** @type {google.maps.Icon} */({
      url: "https://maps.gstatic.com/mapfiles/place_api/icons/museum-71.png",
      size: new google.maps.Size(71, 71),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(17, 34),
      scaledSize: new google.maps.Size(35, 35)
    }));
    marker.setPosition(place.geometry.location);
    marker.setVisible(true);

    var htmlElement = $compile(content)($scope); 
    infowindow.setContent(htmlElement[0]);
    infowindow.open(map, marker);
    $scope.$apply();
  }
  
}

export default {
  name: 'MuseumEditCtrl',
  fn: MuseumEditCtrl
}


