import $ from 'jquery';
function TeiView($compile) {
  'ngInject';
      return function(scope, element, attrs) { 

        var refreshTeiView = function() {
          var teiDomFragment = scope.$eval(attrs.teihtml);
          if (angular.isElement(teiDomFragment)) {
              teiDomFragment.find('placeName').attr( 'ng-click', function() { return 'openPlaceWindow("' + $(this).attr('key') + '")';}).wrap( '<a></a>' );
              teiDomFragment.find('persName name').attr( 'ng-click', function() { return 'openPlaceWindow("' + $(this).attr('nymRef') + '")';}).wrap('<a></a>');
              teiDomFragment.find('bibl').attr( 'ng-click', function() { return 'openPlaceWindow("' + $(this).attr('n') + '")';}).wrap('<a></a>');    
            //  element.html(teiDomFragment.html().toString());
            if (teiDomFragment[0]) {
              element.html((new XMLSerializer()).serializeToString(teiDomFragment[0]));
            }
            $compile(element.contents())(scope);
          }
        }
        scope.$watch(attrs.teihtml, refreshTeiView);    
      }
  }
  
export default {
  name: 'teiView',
  fn: TeiView
}