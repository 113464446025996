import $ from 'jquery';
import xml2js from 'xml2js';
import _ from 'lodash';
function EditableInscription(Inscription){
'ngInject';
     // constructor for EditableInscription objects
     // Inherits prototype from Inscription
    function EditableInscription(id, epiDocForInscription) {
      // although typically the parent constructor is called when
      // inheriting from another function, we
      // don't in this case because we initialize the object differently,
      // i.e., from the XML TEI EpiDoc, and not from the cached json
      // so, no:  Inscription.call(this); 
      this.isicilyId = id;
      this.teiXMLString = epiDocForInscription;


      var transcriptionXPath = "TEI text body div[type='edition'] ab";
      var apparatusXPath = "TEI text body div[type='apparatus'] ab";
      var translationXPath = "TEI text body div[type='translation'] p";
      var commentaryXPath = "TEI text body div[type='commentary'] p";

      var thisEditableInscription = this;

      xml2js.parseString(this.teiXMLString, {}, function(err, parsedObject) {
        thisEditableInscription.inscriptionObject = parsedObject;
        thisEditableInscription.teiDOM = $( $.parseXML( thisEditableInscription.teiXMLString ) );
        thisEditableInscription.transcription = (new XMLSerializer()).serializeToString(thisEditableInscription.teiDOM.find( transcriptionXPath )[0]);
        thisEditableInscription.translation = (new XMLSerializer()).serializeToString(thisEditableInscription.teiDOM.find( translationXPath )[0]);
  
          console.log(parsedObject);
          console.log(`${JSON.stringify(parsedObject)}`);
      });

      // Set the original_lat_long here to allow the addOriginalLocationToMap function 
      // of the inherited Inscription prototype to work.
      //this.original_lat_long = '37.500,14.000';
     
    }

    // use the Inscription prototype, but override the getEdition property,
    // and add a save property
    EditableInscription.prototype = Object.create(
      Inscription.prototype, 
      {getEdition: () => null,
      save: save
      }
    );
    EditableInscription.prototype.constructor = EditableInscription;
 
    function save() {
        
         var transcriptionElement = this.teiDom.find(transcriptionXPath);
          transcriptionElement.replaceWith($(this.transcription));

          var translationElement = inscription.tei_dom.find(translationXPath);
          translationElement.replaceWith($(this.translation));
          //  loop through the list of paths to update, 
          //  get the corresponding object snippet from the js object, convert to 
          //  xml and put back in the DOM.
          var builder = new xml2js.Builder({headless: true});
          var titleElement = this.teiDom.find(titleXPath);
          titleElement.text(this.inscriptionObject);
      
          var updatePaths = [
            'TEI teiHeader fileDesc titleStmt title'
          ];
          // note that we set 'this' for the forEach callback function to 
          // the 'this' that's bound to the save method, which is the
          // editableInscriptoin object
          updatePaths.forEach(function(updatePath) {
             var elementToUpdate = this.teiDom.find(updatePath);
             var newObjectSnippet = _.get(this.inscriptionObject, updatePath.split(" "));
             var newXML = builder.buildObject(newObjectSnippet);
             elementToUpdate.replaceWith(newXML);
          }, this);

          var serializedDOM = (new XMLSerializer()).serializeToString(inscription.tei_dom[0]);
          return DataManager.saveInscriptionData(this.isicilyId, serializedDOM);
         //  var xmlStringAfter = builder.buildObject(jsonObject);
         // console.log(`The xml string after: ${xmlStringAfter}`);
    }

    return EditableInscription;

  }

export default {
  name: 'EditableInscription',
  fn: EditableInscription
};


