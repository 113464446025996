function LoginCtrl($rootScope, $http, config, store, $state, $stateParams) {
'ngInject';

  const vm = this;
  vm.user = {};

  vm.nextState = $stateParams.nextState || 'inscriptions';
  var newParams = angular.copy($stateParams);
  delete newParams.nextState;
  
  vm.login = function() {
    $http({
      url: config.LOGIN_URL,
      method: 'POST',
      data: vm.user
    }).then(function(response) {
      store.set('jwt', response.data.token);  
      $state.go(vm.nextState, newParams);
    }, function(error) {
      alert(error.data);
    });
  }

}

export default {
  name: 'LoginCtrl',
  fn: LoginCtrl
}


