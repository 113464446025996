function EditionEditModalCtrl($scope, $uibModalInstance,  $timeout, $log, ModalsManager, inscription) {
'ngInject';

      $scope.inscription = inscription;
      var editor ;
      var doc;
      $scope.textToEdit;

      $scope.editorOptions = {
        lineWrapping : true,
       // lineNumbers: true,
       // readOnly: 'nocursor',
        mode: 'xml',
        autofocus: true
       // value: $scope.sampleEpiDoc
    };

    $scope.editorLoaded = function(_editor){
      // grab hold of the code mirror editor
      editor = _editor;
      doc = editor.getDoc();
      
    };

      $timeout(function() {$scope.textToEdit = $scope.inscription.edition}, 300);

      $scope.done = function() {           
          $uibModalInstance.close($scope.textToEdit);
      }

      $scope.cancel = function () {
          $uibModalInstance.dismiss('cancel');
      };

      function isASingleRangeSelected() {
        if (doc.somethingSelected()) {
          if (doc.getSelections().length > 1) {
            SweetAlert.swal({
              title: 'Multiple selections.',
               text: 'You may only tag a single selection.',
               type: 'warning',
              // showCancelButton: true,
               confirmButtonColor: '#DD6B55',
               confirmButtonText: 'OK',
               closeOnConfirm: true});
            return false;
          } 
          return true;
        } else {
         SweetAlert.swal({
            title: 'Nothing selected.',
             text: 'You must first select text to tag.',
             type: 'warning',
            // showCancelButton: true,
             confirmButtonColor: '#DD6B55',
             confirmButtonText: 'OK',
             closeOnConfirm: true});
         return false;
        }
        
    }

    $scope.tagPlace = function() {
      
      if (isASingleRangeSelected(doc)) {  
        ModalsManager.openPlaceLookup(doc.getSelections()[0]).then(
          function(selectedEntity) {
              doc.replaceSelection('<placeName ref="' + selectedEntity.place_uri + '">' + doc.getSelections()[0] + '</placeName>');
          }, function () {
            $log.info('No entity selected.');
        });
      } 
    };

    $scope.tagPerson = function() {
      
      if (isASingleRangeSelected(doc)) {       
        ModalsManager.openPersonLookup(doc.getSelections()[0]).then(
          function(selectedEntity) {
              doc.replaceSelection('<persName ref="' + selectedEntity.person_uri + '">' + doc.getSelections()[0] + '</persName>');
          }, function () {
            $log.info('No entity selected.');
        });
      } 
    };

  }

  export default {
    name: 'EditionEditModalCtrl',
    fn: EditionEditModalCtrl
  }