import $ from 'jquery';
import _ from 'lodash';

function InscriptionPrintableCtrl(inscription, MuseumsManager, PublicationsManager, $window, TEIHelper, config, $state, $sce) {
'ngInject';
  
  const vm = this;


  vm.inscription = inscription;
  if (inscription.bibl) PublicationsManager.getChicago(inscription.bibl).then((chicago)=>vm.chicago = chicago);
    vm.date = new Date();

    vm.citableURL = $state.href($state.current.name, {}, {absolute:true});
    

    var sicilyCentre = {lat:37.500, lng:14.000};
    var museum_map = new google.maps.Map(document.getElementById('museum_map'), {
      zoom: 8,
      center: sicilyCentre,
      scrollwheel: false,
      scaleControl: true,
    });
    var findspot_map = new google.maps.Map(document.getElementById('findspot_map'), {
      zoom: 8,
      center: sicilyCentre,
      scrollwheel: false,
      scaleControl: true,
    });

    vm.inscription.addToFindspotMap(findspot_map);

    if (vm.inscription.images) {
      let printImage = [].concat(vm.inscription.images.graphic).filter(graphic=>graphic.n=='print')[0]
     // vm.imageFile = `${config.BASE_URI}/inscription_images/${vm.inscription.isicily_id}/public/${vm.inscription.printable_image}`;  
     vm.imageFile = `${config.BASE_URI}inscription_images/${vm.inscription.isicily_id}/${printImage.url}`;  
     
     vm.imageCredit = printImage.desc;

    }
/*
    if (vm.inscription.height) {
      vm.height = vm.inscription.height.hasOwnProperty('#text') ? vm.inscription.height['#text'] : vm.inscription.height;        
    }
    if (vm.inscription.width) {
      vm.width = vm.inscription.width.hasOwnProperty('#text') ? vm.inscription.width['#text'] : vm.inscription.width;
    }
    if (vm.inscription.depth) {
      vm.depth = vm.inscription.depth.hasOwnProperty('#text') ? vm.inscription.depth['#text'] : vm.inscription.depth;
    }
      
     // eXist returns single results as an object, multiple results in an array.  so convert object results to array.
   if (vm.inscription.letterHeights && vm.inscription.letterHeights.dimensions) vm.inscription.letterHeights.dimensions = [].concat(vm.inscription.letterHeights.dimensions);
   if (vm.inscription.letterHeightLoci && vm.inscription.letterHeightLoci.locus) vm.inscription.letterHeightLoci.locus = [].concat(vm.inscription.letterHeightLoci.locus);
   if (vm.inscription.interlinearHeights && vm.inscription.interlinearHeights.dimensions) vm.inscription.interlinearHeights.dimensions = [].concat(vm.inscription.interlinearHeights.dimensions);
   if (vm.inscription.interlinearLoci && vm.inscription.interlinearLoci.locus) vm.inscription.interlinearLoci.locus = [].concat(vm.inscription.interlinearLoci.locus);
   
  
    //console.log(`the provenance: ${vm.inscription.provenance}`);
    //console.log(`the ancient place name: ${vm.inscription.ancient_place_name_for_display}`);
    //console.log(`the modern place name: ${vm.inscription.modern_place_name_for_display}`);
    
    vm.provenance = TEIHelper.hyperlinkAll(vm.inscription.provenance || vm.inscription.ancient_place_name_for_display);
    vm.ancientPlaceForTitle = TEIHelper.hyperlinkAll(vm.inscription.ancient_place_name_for_display);
    vm.modernPlaceForTitle = TEIHelper.hyperlinkAll(vm.inscription.modern_place_name_for_display);
    */
   
   var heightWithUnits, widthWithUnits, depthWithUnits;
    
    if (vm.inscription.height) {
      var height = vm.inscription.height.hasOwnProperty('#text') ? vm.inscription.height['#text'] : vm.inscription.height;
      heightWithUnits = `H = ${height} ${vm.inscription.height_unit}; `;        
    }
    if (vm.inscription.width) {
      var width = vm.inscription.width.hasOwnProperty('#text') ? vm.inscription.width['#text'] : vm.inscription.width;
      widthWithUnits = `W = ${width} ${vm.inscription.width_unit}; `;
    }
    if (vm.inscription.depth) {
      var depth = vm.inscription.depth.hasOwnProperty('#text') ? vm.inscription.depth['#text'] : vm.inscription.depth;
      depthWithUnits = `D = ${depth} ${vm.inscription.depth_unit}; `;
    }
    vm.dimensions = heightWithUnits + widthWithUnits + depthWithUnits;
      
    // eXist returns single results as an object, multiple results in an array.  so convert object results to array.
  
   if (vm.inscription.letterHeightLoci 
        && vm.inscription.letterHeightLoci.locus 
        && vm.inscription.letterHeights 
        && vm.inscription.letterHeights.dimensions) {
          vm.letterHeightLoci = [].concat(vm.inscription.letterHeightLoci.locus);
          vm.letterHeights = [].concat(vm.inscription.letterHeights.dimensions);
    } 

   if (vm.inscription.interlinearLoci 
        && vm.inscription.interlinearLoci.locus 
        && vm.inscription.interlinearHeights 
        && vm.inscription.interlinearHeights.dimensions 
        && vm.inscription.interlinearHeights.dimensions.height 
        && vm.inscription.interlinearHeights.dimensions.height['#text']) {
          vm.interlinearLoci = [].concat(vm.inscription.interlinearLoci.locus);
          vm.interlinearHeights = [].concat(vm.inscription.interlinearHeights.dimensions);
   }  
  
   if (vm.inscription.material) {
      vm.materialHTML = [].concat(vm.inscription.material).map(
        material=>`<a href="${material.ref}" target="_blank">${material['#text']}</a>`
      ).join(', ');
    }
    if (vm.inscription.term) {
      vm.inscriptionTypeHTML = [].concat(vm.inscription.term).map(
          (type)=>`<a href="${type.ref}" target="_blank">${type['#text']}</a>`
      ).join('/');
    }
    if (vm.inscription.objectType) {
      vm.objectTypeHTML = [].concat(vm.inscription.objectType).map(
          (type)=>`<a href="${type.ref}" target="_blank">${type['#text']}</a>`
      ).join('/');
    }
    if (vm.inscription.rs) {
      vm.executionTypeHTML = [].concat(vm.inscription.rs).map(
        (type)=>`<a href="${type.ref}" target="_blank">${type['#text']}</a>`
      ).join(', ');
    }

    if (vm.inscription.app) {
      vm.apparati = [].concat(vm.inscription.app);
    }

    if (vm.inscription.phi_id) {
      vm.phiIds = vm.inscription.phi_id.split(' ');
    }

    let provenanceHyperlinked = TEIHelper.hyperlinkAll(vm.inscription.provenance);
     vm.ancientPlaceForTitle = TEIHelper.hyperlinkAll(vm.inscription.ancient_place_name_for_display);
     vm.modernPlaceForTitle = TEIHelper.hyperlinkAll(vm.inscription.modern_place_name_for_display);
     vm.provenanceForField = provenanceHyperlinked || vm.ancientPlaceForTitle || vm.modernPlaceForTitle;
    vm.layoutDescHTML = TEIHelper.hyperlinkAll(vm.inscription.layout_description);
    vm.supportDescHTML = TEIHelper.hyperlinkAll(vm.inscription.support_description);
    
    vm.inscription.bibl = [].concat(vm.inscription.bibl);

    vm.biblByCorpus = _.sortBy(_.filter(vm.inscription.bibl, 'type'), ['type']);
    vm.biblByAuthor = _.sortBy(_.filter(vm.inscription.bibl, b=>b&&!b.type), ['date', 'author']);

    //let interpretedLeiden = (new XMLSerializer()).serializeToString(TEIHelper.convertToLeiden(inscription.edition, false)[0])
    //let diplomaticLeiden = (new XMLSerializer()).serializeToString(TEIHelper.convertToLeiden(inscription.edition, true)[0])
    vm.joinedTranslations = $sce.trustAsHtml(TEIHelper.getJoinedTranslations(inscription.translation));
    //console.log(vm.joinedTranslations)
 

    vm.print = function() {
      $('#print-button').hide();
      $window.print();
      $window.close();
    }

    if (vm.inscription.museum_uri) {
      MuseumsManager.getMuseumByURI(vm.inscription.museum_uri).then(
        function(museum) {
          vm.museum = museum;
          if (museum) {
            var museumMarker = museum.addToMap(museum_map);
            if (museumMarker) {
              museum_map.setCenter(museumMarker.getPosition());
              museum_map.setZoom(14);
            } 
          }
        }
      )
    }
}  

export default {
  name: 'InscriptionPrintableCtrl',
  fn: InscriptionPrintableCtrl
}

