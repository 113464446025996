import $ from 'jquery';
function PublicationsManager(DataManager){
'ngInject';

  var publications = null;
  var publicationsByKey = Object.create(null);

  function loadPublications() {
      return DataManager.getPublicationData().then(
          function(publicationsData) { 
            if (! publications) {
              publications = publicationsData;
              $.each(publicationsData, function(index, publicationData){
                publicationsByKey[publicationData.key] = publicationData;
              })
            }
            return publications;
          },
          function(errorResponse) {  
            console.log('error in call to get publications list');
            console.log(errorResponse);  
          }
        )
  }

  function getPublication(zoteroKey) { 
      return  loadPublications().then(
        (response)=>publicationsByKey[zoteroKey],
        (errorResponse) =>{
          console.log('error in call to get publication doc');
          console.log(errorResponse); 
        }
      )  
  }

  function getChicago(biblList){
      const serializer = new XMLSerializer()
    // loadPublications to make sure the pubs have been loaded into their singleton
    return loadPublications().then(
      ()=>{
        return [].concat(biblList).reduce((finalString, bibl)=>{
          if (!bibl.ptr || !bibl.ptr.target) return finalString
          let zoteroURI = bibl.ptr.target
          let zoteroKey = bibl.ptr.target.substring(zoteroURI.lastIndexOf('/')  + 1)
          if (!zoteroKey) return finalString
          let thePub = publicationsByKey[zoteroKey]
          if (!thePub) return finalString
          let chicago = thePub.chicago
          if (!chicago) return finalString

          if (bibl.citedRange) {
              let parsedDom = $($.parseXML(chicago));
              parsedDom.find('.csl-entry').append(' At ' + bibl.citedRange);
              chicago = serializer.serializeToString(parsedDom[0]);
          }
            return finalString.concat(chicago)},'')
      }
    )
  }

  return {
        getPublication: getPublication,
        loadPublications: loadPublications,
        getChicago: getChicago
  }
}

export default {
  name: 'PublicationsManager',
  fn: PublicationsManager
};