function DateEditModalCtrl($scope, $uibModalInstance,  $timeout, $log, inscription) {
'ngInject';   
      $scope.inscription = inscription;
      
      $scope.date = {
        text: inscription.date
      };

      if (inscription.date_not_before.startsWith('-')) {
        $scope.date.notBeforeEra = 'BC';
        $scope.date.notBefore = inscription.date_not_before.slice(1);
      } else {
        $scope.date.notBeforeEra = 'AD';
        $scope.date.notBefore = inscription.date_not_before.slice(1);
      }

      if (inscription.date_not_after.startsWith('-')) {
        $scope.date.notAfterEra = 'BC';
        $scope.date.notAfter = inscription.date_not_after.slice(1);
      } else {
        $scope.date.notAfterEra = 'AD';
        $scope.date.notAfter = inscription.date_not_after.slice(1);
      }

   
      $scope.done = function() {           
           
          if ($scope.date.notBeforeEra == 'BC') {
            inscription.date_not_before = '-' + $scope.date.notBefore;
          } else {
            inscription.date_not_before = $scope.date.notBefore;
          }
          
          if ($scope.date.notAfterEra == 'BC') {
            inscription.date_not_after = '-' + $scope.date.notAfter;
          } else {
            inscription.date_not_after = $scope.date.notAfter;
          }

          inscription.date = $scope.date.text;

           InscriptionsManager.updateDate([inscription], $scope.date.text, $scope.date_not_before, $scope.date_not_after);
      }

      $scope.cancel = function () {
          $uibModalInstance.dismiss('cancel');
      };
  }

export default {
  name: 'DateEditModalCtrl',
  fn: DateEditModalCtrl
}