function Comment(ModalsManager) {
'ngInject';
	return {
	    template: `<a href="" ng-show="$root.role == 'ADMIN' || $root.role == 'BASIC'">
	    	<md-icon style="font-size: 1em; color: #55ACEE; padding-left:.3em" class="material-icons" >
	    		message
	    	</md-icon>
	    	<md-tooltip >
          		Click to add comment or correction
        	</md-tooltip>
        </a>`,
	    restrict: 'E',
	    scope: {
      		field: '@',
      		inscriptionId: '@'
    	},
	    link: (scope, element) => {
	        element.on('click', (ev) => {
		        ModalsManager.openComment(scope.field, scope.inscriptionId, ev);
		     });
	        
	    }
	}
}

export default {
	name: 'comment',
	fn: Comment
}